import { FiMenu, FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IconArrowDropdown, IconArrowDropUp } from "../../../../shared/Icons";
import "./TopBar.css";
import { Color } from "../../../../../utils/theme";
import { MenuContent } from "../SideBar/SidebarAdmin";
import { useState } from "react";
import { LogoApp } from "../../../user/Layouts/SideBar/SidebarUser";
import { useAppSelector } from "../../../../../redux/hooks";
import { getAvatar } from "../../../../../utils/utils";

const TopBarAdmin = () => {
	return (
		<div className="top-bar">
			<div className="d-flex justify-content-between align-items-center  justify-content-lg-end">
				<div className="d-lg-none">
					<button
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#FashionMenu"
						aria-controls="FashionMenu"
					>
						<FiMenu style={{ fontSize: 25, color: Color.dark }} />
					</button>
				</div>
				<ProfilDropdown />
			</div>
			<div
				className="offcanvas offcanvas-start"
				id="FashionMenu"
				aria-labelledby="FashionMenuLabel"
			>
				<div className="offcanvas-header">
					<div className="offcanvas-title" id="FashionMenuLabel">
						<LogoApp path="/admin" />
					</div>
					<button
						type="button"
						className="btn-close btn_close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>
				<div className="offcanvas-body">
					<div className="menu-mobile pb-3">
						<MenuContent />
					</div>
				</div>
			</div>
		</div>
	);
};

export function ProfilDropdown() {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleSetActive = () => {
		setIsActive((isActive) => !isActive);
	};
	const { user } = useAppSelector((s) => s.user);
	return (
		<div className="flex-m gap-2 gap-lg-4">
			{/* <div className="input-search-container">
				<div className="input-group">
					<input
						type="text"
						className="form-control form-search-input"
						placeholder="Recherche"
						aria-label="Recherche"
						aria-describedby="basic-addon2"
					/>
					<span
						className="input-group-text"
						id="basic-addon2"
						style={{ background: "#E97D24", color: "#fff" }}
					>
						<FiSearch />
					</span>
				</div>
			</div> */}
			<div className="dropdown top-bar-btn">
				<button
					className="dropdown-toggle"
					type="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					onClick={toggleSetActive}
				>
					<div className="flex-m">
						<div className="auth-admin-img-container">
							<img
								src={getAvatar(user?.avatar)}
								alt="admin-avatar"
								style={{ width: "46px", height: "46px", borderRadius: "9px" }}
							/>
						</div>
						{isActive ? <IconArrowDropUp /> : <IconArrowDropdown />}
					</div>
				</button>

				<ul className="dropdown-menu fashion__card pepite_action_container mt-2">
					<div className="d-flex flex-column align-items-end px-2 gap-2">
						<li>
							<Link
								to="/admin/profil"
								className="btn_action btn__edit"
								onClick={toggleSetActive}
							>
								Mon compte
							</Link>
						</li>
						<li>
							<button
								className="btn_action btn__delete"
								data-bs-target="#LogoutModal"
								data-bs-toggle="modal"
								onClick={toggleSetActive}
							>
								Me déconnecter
							</button>
						</li>
					</div>
				</ul>
			</div>
		</div>
	);
}

export default TopBarAdmin;
