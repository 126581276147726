import React from "react";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { GraphUserType } from "../../../../../utils/api/user/user.type";
interface Props {
	data?: GraphUserType[];
}
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);
const BarChart: React.FC<Props> = ({ data: graphData }) => {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
				display: false,
			},
			title: {
				display: false,
				text: "",
			},
			tooltip: {
				intersect: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
					borderWidth: 0,
				},
			},
			y: {
				grid: {
					display: false,
					borderWidth: 0,
				},
			},
		},
	};

	const labels = [
		"Jan.",
		"Fev.",
		"Mar.",
		"Avr.",
		"Mai.",
		"Jui.",
		"Jui.",
		"Aou.",
		"Sep.",
		"Oct.",
		"Nov.",
		"Dec.",
	];

	const data = {
		labels,
		datasets: [
			{
				label: "€",
				data: graphData?.map((item) => item?.sales_amount),
				backgroundColor: "#F2BA8C",
				barPercentage: 0.1,
				grid: false,
			},
		],
	};
	return (
		<div className="bar-chart-container">
			<Bar data={data} options={options} />
		</div>
	);
};

export default BarChart;
