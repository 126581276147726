import { BsSquareFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useLazyCheckMarketPlaceTokenValidityQuery } from "../../../../utils/api/marketplace/marketplace.api";
import { Color } from "../../../../utils/theme";
import { formatPlurial } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import { BtnPrimary } from "../../../common/Button";
import Pagination from "../../../common/Pagination";
import PanelFilter from "../../../common/PanelFilter";
import DeletePepiteModal from "../../modals/user/DeletePepiteModal";
import ModifierDiffusionModalPepite from "../../modals/user/ModifierDiffusionModalPepite";
import ModalChoiceCollection from "../collection/ModalChoiceCollection";
import DiffuserModalPepite from "../Diffuser/DiffuserModalPepite";
import ModaImportFilePepite from "./ModaImportFilePepite";
import ModalDiffusionMassProducts from "./ModalDiffusionMassProducts";
import ModalDowloadShopifyProducts from "./ModalDowloadShopifyProducts";
import "./MonStock.css";
import PepiteCard, { PepiteCardSkeleton } from "./PepiteCard";
import { useDiffuseMassProducts } from "./useDiffuseMassProducts";
import { useOpenDowloadShopifyProductModal, useStock } from "./useStock";

const MonStock = () => {
	const {
		data,
		isLoading,
		handleFilterPepiteByName,
		handleFilterPepiteByStatut,
		item,
		getCurrentPepite,
		handlePageClick,
		pageCount,
		isShowPagination,
	} = useStock();
	const [checkMarketPlaceTokenValidity, { data: tokenStatus }] =
		useLazyCheckMarketPlaceTokenValidityQuery();

	const { isShowModal, closeModal, openDowloadModal } = useOpenDowloadShopifyProductModal();

	const { onToggleCheckedItem, handleSelectAll, arrayOfProductsId, isShowBroadcastBtn, reset } =
		useDiffuseMassProducts(data?.results);

	return (
		<div className="stock__page">
			<div className="d-flex flex-column flex-md-row justify-content-md-between gap-3">
				<h1>Mon stock</h1>
				<div className="d-flex flex-column flex-md-row gap-3 align-items-center">
					<button
						className="fashion__btn"
						style={{ color: Color.primary }}
						onClick={openDowloadModal}
					>
						Télecharger
					</button>
					<button
						className="fashion__btn"
						style={{ color: Color.primary }}
						data-bs-toggle="modal"
						data-bs-target="#ModaImportFilePepite"
					>
						Importer
					</button>
					{isShowBroadcastBtn && (
						<>
							<BtnPrimary
								label="Diffuser"
								className="fashion__btn btn__add px-3 py-2"
								data-bs-toggle="modal"
								data-bs-target="#ModalDiffusionMassProducts"
								onClick={() => checkMarketPlaceTokenValidity()}
							/>
							<BtnPrimary
								label="Ajouter à  une collection"
								className="fashion__btn btn__add px-3 py-2"
								data-bs-toggle="modal"
								data-bs-target="#ModalChoiceCollection"
							/>
						</>
					)}
					<Link
						className="fashion__btn btn__add px-3 py-2"
						to="/user/mon-stock/ajouter-une-pepite"
					>
						<FiPlus />
						<span className="ms-2">Ajouter une pépite</span>
					</Link>
				</div>
			</div>
			<PanelFilter
				filterPepiteByName={handleFilterPepiteByName}
				filterPepiteByStatut={handleFilterPepiteByStatut}
				isShowFilterStatus
			/>
			<div className="pepites__container mt-4 px-md-3 px-lg-0">
				<div className="flex-sb mb-3">
					<div className="flex-m gap-2">
						<h3 className="title__level3">Mes pépites en stock</h3>
						<BsSquareFill
							style={{
								fontSize: 7,
								color: "#999591",
							}}
						/>
						<p className="total-pepite  m-0">
							{formatPlurial(data?.count, "Résultat")}
						</p>
					</div>
					{isShowPagination ? (
						<div className="view-md">
							<Pagination
								title="Page"
								handlePageClick={handlePageClick}
								pageCount={pageCount}
							/>
						</div>
					) : null}
				</div>
				<div className="pepites__cards__container">
					<div className="form-group mb-3">
						<label htmlFor="select-all">Tous sélectionner</label>
						<input
							type="checkbox"
							className="form-check-input fas__check ms-2"
							style={{ cursor: "pointer" }}
							onChange={handleSelectAll}
							id="select-all"
						/>
					</div>
					{!isLoading ? (
						data?.results && data?.results?.length > 0 ? (
							data?.results?.map((item) => (
								<PepiteCard
									key={item?.slug}
									path="/user/mon-stock/pepites/"
									pepite={item}
									getCurrentPepite={getCurrentPepite}
									sales={item?.sales}
									checkTokenStatus={checkMarketPlaceTokenValidity}
									onToggleCheckedItem={onToggleCheckedItem}
									arrayOfProductsId={arrayOfProductsId}
								/>
							))
						) : (
							<AlertInfo message="Aucune pépite trouvée" />
						)
					) : (
						<>
							{[...Array(5)]?.map((item, i) => (
								<PepiteCardSkeleton key={i} />
							))}
						</>
					)}
				</div>
				{isShowPagination ? (
					<div className="flex-c">
						<Pagination
							title="Page"
							handlePageClick={handlePageClick}
							pageCount={pageCount}
						/>
					</div>
				) : null}
			</div>
			<DiffuserModalPepite item={item} tokenStatus={tokenStatus} />
			<ModifierDiffusionModalPepite />
			<ModaImportFilePepite />
			<ModalDowloadShopifyProducts isOpen={isShowModal} closeModal={closeModal} />
			<ModalDiffusionMassProducts
				tokenStatus={tokenStatus}
				reset={reset}
				arrayOfProductsId={arrayOfProductsId}
			/>
			<DeletePepiteModal
				message="En supprimant cette pépite, elle sera automatiquement retirée de notre plateforme et des marketplaces."
				item={item}
			/>
			<ModalChoiceCollection produitsId={arrayOfProductsId} />
		</div>
	);
};
export default MonStock;
