import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { RegisterFormData } from "../../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../../utils/api/auth/auth.api";
import { cleannerError, onHide, onOpen } from "../../../../utils/utils";
import { UserType } from "../../../../utils/api/user/user.type";
import { IOffer, OfferOptionType } from "../../../../utils/api/offer/offer.type";
import { useNavigate } from "react-router-dom";
import { PaymentMode } from "../../../../utils/api/paymentMode/paymentMode.type";
import { telRegex } from "../../../../utils/constants";

const initialData = {
	nom: "",
	prenom: "",
	date_naissance: "",
	user_type: UserType.vendeur,
	civilite: undefined,
	telephone: "",
	email: "",
	password: "",
	confirmPassword: "",
	nom_boutique: "",
	numero_siret: undefined,
	adresse: "",
	ville: "",
	code_postal: "",
};

function useSignupForm(navigation: any) {
	const validationSchema = yup.object().shape({
		step: yup.number().default(1),
		email: yup.string().when("step", {
			is: 1,
			then: yup.string().email().required().label("Email"),
		}),
		password: yup.string().when("step", {
			is: 1,
			then: validatePassword(yup),
		}),
		confirmPassword: yup.string().when("step", {
			is: 1,
			then: yup
				.string()
				.oneOf([yup.ref("password"), null], "Les mots de passe ne correspond pas"),
		}),
		nom: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Nom"),
		}),
		prenom: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Prénoms"),
		}),
		telephone: yup.string().when("step", {
			is: 2,
			then: yup
				.string()
				.required()
				.matches(telRegex, "Numéro de téléphone invalide")
				.label("Téléphone"),
		}),
		nom_boutique: yup.string().when("step", {
			is: 3,
			then: yup.string().required().label("Nom de la boutique"),
		}),
		numero_siret: yup.number().when("step", {
			is: 3,
			then: yup
				.number()
				.required()
				.label("Numéro de SIREN")
				.typeError("Numéro de SIREN est un champ obligatoire"),
		}),
		adresse: yup.string().when("step", {
			is: 4,
			then: yup.string().required().label("Adresse"),
		}),
		ville: yup.string().when("step", {
			is: 4,
			then: yup.string().required().label("Ville"),
		}),
		code_postal: yup.string().when("step", {
			is: 4,
			then: yup.string().required().label("Code Postale"),
		}),
		// offre: yup.string().when("step", {
		// 	is: 5,
		// 	then: yup.string().required("Choisissez un abonnement"),
		// }),
	});
	const [data, setData] = useState<RegisterFormData>();
	const [checkedId, setCheckedId] = useState<number>();
	const [offer, setOffer] = useState<IOffer>();
	const [checkedModePayementId, setCheckedModePayementId] = useState<number>();
	const [isShowOffer, setIsShowOffer] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const env = window.location.origin;
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<RegisterFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialData,
	});

	//  Calling the Register Mutation
	const [registerUser, { isLoading, isSuccess, error, isError, data: result }] =
		useRegisterUserMutation();

	useEffect(() => {
		if (isSuccess && result) {
			console.log({ result });
			window.location.href = result?.url;
		}
		const err = error as any;
		if (isError) {
			console.log(err?.data?.email);
			if (err?.data?.email?.includes("user with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				}).then(() => navigate("/inscription"));
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenu lors de l'inscription",
					showConfirmButton: true,
					confirmButtonColor: Color.primary,
					// timer: 5000,
				});
			}
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors, 4000);
	}, [errors]);

	const toggle = () => setIsOpen((isOpen) => !isOpen);

	const handleChoiceOffer = (offer: IOffer) => {
		console.log({ offer });
		setCheckedId(offer?.id);
		setOffer(offer);
		if (offer?.id) {
			// console.log("offer", offer?.id);
			setValue("offre", offer?.id);
		}
		if (offer?.option === OfferOptionType.FREEMIUM) {
			navigation.next();
		} else {
			toggle();
		}
	};

	const handleChoiceModePaiement = (item: PaymentMode) => {
		setCheckedModePayementId(item?.id);
		if (item?.id) {
			console.log("moyen_paiement", item?.id);
			setValue("moyen_paiement", item?.id);
		}
	};

	const onSubmit = async (data: RegisterFormData) => {
		switch (data?.step) {
			case 1:
				console.log(data);
				setData({ ...data });
				navigation.next();
				break;
			case 2:
				navigation.next();
				console.log(data);
				break;
			case 3:
				if (String(data?.numero_siret)?.length < 9) {
					return setError("numero_siret", {
						message: "Le numero est inférieur à 9 chiffres",
					});
				} else if (String(data?.numero_siret)?.length > 14) {
					return setError("numero_siret", {
						message: "Le numero doit être inférieur à 14 chiffres",
					});
				} else {
					navigation.next();
				}
				console.log(data);
				break;
			case 4:
				navigation.next();
				console.log(data);
				break;
			case 5:
				if (data?.moyen_paiement && data?.moyen_paiement) {
					// onHide("ChooseAbonnementModal");
					toggle();
					navigation.next();
				} else {
					if (!data?.offre) {
						return setError("offre", {
							message: "Vous devez choisir un abonnement",
						});
					} else {
						setIsShowOffer(false);
					}
					if (!data?.moyen_paiement && !isShowOffer) {
						return setError("moyen_paiement", {
							message: "Vous devez choisir un mode de paiement",
						});
					}
				}

				break;
			default:
				const sendData = {
					...data,
					env: env,
					page: `se-connecter`,
				};
				registerUser(sendData);
				break;
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		data,
		checkedId,
		handleChoiceOffer,
		isShowOffer,
		checkedModePayementId,
		handleChoiceModePaiement,
		isOpen,
		toggle,
		offer,
	};
}
export function validatePassword(Yup: any) {
	return Yup.string()
		.required()
		.matches(/[a-z]+/, "Au moins une minuscule.")
		.matches(/[A-Z]+/, "Au moins une majuscule.")
		.matches(/[!@#$%^&*(),;-_+*/.?":{}|<>]+/, "Au moins un caractère spécial")
		.matches(/\d+/, "Au moins un chiffre.")
		.min(8)
		.label("Mot de passe");
}
export default useSignupForm;
