import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IAdmin, IAdminFormData, IDashboardAdmin, IUserByDay } from "./admin.type";
import { prepareHeaders } from "../user/user.api";
import { IUser, PaginationResults, TypeQuery, UserType } from "../user/user.type";
import { QueryUrl } from "../../utils";

export const AdminApi = createApi({
	reducerPath: "adminApi",
	tagTypes: ["admin"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		meAdmin: builder.query<IAdmin, void>({
			query: () => "auth/me-admin/",
		}),
		getAdminsList: builder.query<PaginationResults<IAdmin>, TypeQuery>({
			query: (query) => QueryUrl("user/admins/", query),
			providesTags: ["admin"],
		}),
		dashboardAdmin: builder.query<IDashboardAdmin, void>({
			query: () => "dashboard/",
		}),
		getGraphUserByWeek: builder.query<IUserByDay[], { date: Date }>({
			query: (date) => {
				return {
					url: "graphuserbyweek/",
					params: date,
				};
			},
		}),
		addOrUpdateAdmin: builder.mutation<
			IAdmin,
			{
				slug?: string;
				user_type?: UserType.admin | UserType.superAdmin;
				data: IAdminFormData | FormData;
			}
		>({
			invalidatesTags: ["admin"],
			query: ({ slug, user_type, data }) => {
				if (slug) {
					if (user_type && user_type === "admin") {
						return {
							url: `user/admins/${slug}/`,
							method: "PUT",
							body: data,
						};
					} else {
						return {
							url: `user/${slug}/`,
							method: "PUT",
							body: data,
						};
					}
				}
				return {
					url: `user/admins/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editAdmin: builder.mutation<
			IAdmin,
			{
				slug?: string;
				user_type?: "admin" | "superadmin";
				data: IAdminFormData | FormData | any;
			}
		>({
			invalidatesTags: ["admin"],
			query: ({ slug, user_type, data }) => {
				if (user_type === "admin") {
					return {
						url: `user/admins/${slug}/`,
						method: "PUT",
						body: data,
					};
				} else {
					return {
						url: `user/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
			},
		}),
		deleteAdmin: builder.mutation<IAdmin, string>({
			query: (slug) => ({
				url: `user/admins/${slug}`,
				method: "Delete",
			}),
			invalidatesTags: ["admin"],
		}),
	}),
});
export const {
	useMeAdminQuery,
	useAddOrUpdateAdminMutation,
	useEditAdminMutation,
	useLazyMeAdminQuery,
	useGetAdminsListQuery,
	useDeleteAdminMutation,
	useDashboardAdminQuery,
	useGetGraphUserByWeekQuery,
} = AdminApi;
