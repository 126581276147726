import React from "react";
import { BsSquare } from "react-icons/bs";
import { Pagination } from "reactstrap";
import { AlertInfo } from "../../../common/Alert";
import DeletePepiteModal from "../../modals/user/DeletePepiteModal";
import RemettrePepiteModalPepite from "../../modals/user/RemettrePepiteModalPepite";
import { PepiteCardSkeleton } from "../Stock/PepiteCard";
import { PepiteItem } from "./MesVentes";
import { useVentes } from "./useVentes";

const ListVentes = () => {
	const {
		data,
		isShowPagination,
		handlePageClick,
		pageCount,
		isLoading,
		getPepite,
		pepite,
		orderSlug,
		getOrderSlug,
	} = useVentes();
	console.log({ data });
	return (
		<>
			<div className="flex-sb mb-3">
				<div className="flex-m gap-2">
					<h3 className="title__level3">Mes pépites vendues</h3>
					<BsSquare
						style={{
							fontSize: 7,
							color: "#999591",
						}}
					/>
					<p className="total-pepite  m-0">0 Résultat</p>
				</div>
				<div className="view-md">
					{isShowPagination ? (
						<div className="flex-c">
							<Pagination
								title="Page"
								handlePageClick={handlePageClick}
								pageCount={pageCount}
							/>
						</div>
					) : null}
				</div>
			</div>
			<div className="pepites__cards__container">
				{!isLoading ? (
					data?.results && data?.results?.length > 0 ? (
						data?.results?.map((item) => (
							<React.Fragment key={item?.slug}>
								<PepiteItem
									pepite={item.produit}
									marketplace={item?.marketplace}
									marketplace_detail={item?.marketplace_detail}
									getPepite={getPepite}
									getOrderSlug={getOrderSlug}
									orderSlug={item?.slug}
								/>
							</React.Fragment>
						))
					) : (
						<AlertInfo message="Aucune vente effectuée" />
					)
				) : (
					<>
						{[...Array(5)]?.map((item, i) => (
							<PepiteCardSkeleton key={i} />
						))}
					</>
				)}
			</div>
			{isShowPagination ? (
				<div className="flex-c">
					<Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
				</div>
			) : null}
			<RemettrePepiteModalPepite pepite={pepite} orderSlug={orderSlug} />
			<DeletePepiteModal
				message="En supprimant cette pépite, toutes ses informations seront supprimées."
				item={pepite}
			/>
		</>
	);
};

export default ListVentes;
