import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect } from "react";
import Swal from "sweetalert2";
import {
  useAddOrUpdateSubCategoryMutation,
} from "../../../../../utils/api/category/category.api";
import {
  ICategory,
  Subcategory,
  SubCategoryFormData,
} from "../../../../../utils/api/category/category.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";

function useSubCategoryForm(closeModal: () => void, category: ICategory, item?: Subcategory) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required("Le nom est requis"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<SubCategoryFormData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			categorie: category.id,
		},
	});

	//  Calling the Register Mutation
	const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateSubCategoryMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item
					? "Sous-catégorie modifiée avec succès!"
					: "Sous-catégorie ajoutée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				closeModal();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item) {
			setValue("nom", item?.nom);
		} else {
			setValue("nom", "");
		}
	}, [item]);

	const onSubmit = (data: SubCategoryFormData) => {
		addOrUpdate({ slug: item?.slug, data: data });
	};
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useSubCategoryForm;
