type messageType = {
	message?: string;
};
function ErrorMessage({ message }: messageType) {
	return (
		<>
			{message && (
				<div className="alert alert-danger mt-1 py-2" role="alert">
					{message}
				</div>
			)}
		</>
	);
}

export default ErrorMessage;
