import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ReactPaginate from "react-paginate";

import "./style/Pagination.css";
type TEventPaginate = {
	selected: number;
};
interface IPagination {
	handlePageClick: (event: TEventPaginate) => void;
	pageCount: number;
	title?: string;
}
function Pagination({ handlePageClick, pageCount, title }: IPagination) {
	return (
		<div className="paginations-container flex-m gap-4">
			{title && <h3 className="title__level3 mb-3">{title}</h3>}
			<ReactPaginate
				onPageChange={handlePageClick}
				pageRangeDisplayed={1}
				marginPagesDisplayed={3}
				pageCount={pageCount}
				previousLabel={<FiChevronLeft />}
				nextLabel={<FiChevronRight />}
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakLabel="..."
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName="pagination"
				activeClassName="active"
			/>
		</div>
	);
}

export default Pagination;
