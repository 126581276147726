import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { VendeurFormData, IVendeur } from "./vendeur.type";

export const VendeurApi = createApi({
	reducerPath: "vendeurAPi",
	tagTypes: ["vendeur", "vendeur_by_slug", "users_deleted"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),

	endpoints: (builder) => ({
		getVendeur: builder.query<PaginationResults<IVendeur>, TypeQuery>({
			query: (query) => QueryUrl(`user/vendeurs/`, query),
			providesTags: ["vendeur"],
		}),
		getTrashSeller: builder.query<PaginationResults<IVendeur>, TypeQuery>({
			query: (query) => QueryUrl(`users_deleted/`, query),
			providesTags: ["users_deleted"],
		}),
		getVendeurBySlug: builder.query<IVendeur, any>({
			query: (slug) => `user/vendeurs/${slug}/`,
			providesTags: ["vendeur_by_slug"],
		}),
		addOrUpdateVendeur: builder.mutation<
			IVendeur,
			{ slug?: string; data: VendeurFormData | FormData }
		>({
			invalidatesTags: ["vendeur"],

			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `/users/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `users/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editVendeur: builder.mutation<
			IVendeur,
			{ slug?: string; data: VendeurFormData | FormData | any }
		>({
			invalidatesTags: ["vendeur", "vendeur_by_slug"],
			query: ({ slug, data }) => {
				return {
					url: `users/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteVendeur: builder.mutation<
			IVendeur,
			{ slug: string; data: VendeurFormData | FormData | any }
		>({
			query: ({ slug, data }) => ({
				url: `/user/${slug}/suppression/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["vendeur", "users_deleted"],
		}),
		restaurVendeur: builder.mutation<IVendeur, { deletion_id: string; password: string }>({
			query: ({ deletion_id, password }) => ({
				url: `/reactive_user/${deletion_id}/`,
				method: "POST",
				body: { password },
			}),
			invalidatesTags: ["vendeur", "users_deleted"],
		}),
	}),
});

export const {
	useGetVendeurQuery,
	useGetVendeurBySlugQuery,
	useEditVendeurMutation,
	useLazyGetVendeurQuery,
	useAddOrUpdateVendeurMutation,
	useDeleteVendeurMutation,
	useGetTrashSellerQuery,
	useRestaurVendeurMutation,
} = VendeurApi;
