import React from "react";
import { BtnBack } from "../../../common/Button";
import { Link } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { MdDelete } from "react-icons/md";
import {
	createMarkup,
	formatMontant,
	getImage,
	isShowComma,
	useLocationState,
} from "../../../../utils/utils";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import DeletePepiteModal from "../../modals/user/DeletePepiteModal";

const DetailPepite = () => {
	const item = useLocationState<IPepite>(undefined);
	console.log({ item });
	return (
		<div className="detail__pepite">
			<div className="row">
				<div className="col-md-6 col-lg-4">
					{item?.images?.map((img) => (
						<div className="image-container mb-3" key={img?.slug}>
							<img src={getImage(img?.picture)} alt="" className="w-100" />
						</div>
					))}
				</div>
				<div className="col-md-6 col-lg-8">
					<div className="mb-4 flex-sb">
						<BtnBack label="Revenir sur mon stock" />
						{/* <button data-bs-toggle="modal" data-bs-target="#DeleteModalPepite">
							<MdDelete style={{ fontSize: 18, color: Color.red }} />
						</button> */}
					</div>
					<div className="d-flex align-items-center gap-4">
						<h4 className="pepite__title fs-31">{item?.nom}</h4>
						<div className="position-relative ps-3">
							{item?.en_ligne ? (
								<h6 className="group_info_statut online">En ligne</h6>
							) : (
								<h6 className="group_info_statut">Ébauche</h6>
							)}
						</div>
					</div>
					<div className="description__container mb-4">
						<h5 className="m-0">description de la pépite</h5>
						<div
							className="description__text"
							dangerouslySetInnerHTML={createMarkup(item?.description)}
						/>
					</div>
					<div className="fashion__card px-3 py-3 mb-5">
						<div className="flex-sb ">
							<div className="group-info">
								<h5 className="group_info_title">genre</h5>
								<p className="group_info_value">{item?.genre}</p>
							</div>
							<div className="group-info">
								<h5 className="group_info_title">Catégorie</h5>
								<p className="group_info_value">{item?.categorie?.nom}</p>
							</div>
							<div className="group-info">
								<h5 className="group_info_title">sous-Catégorie</h5>
								<p className="group_info_value">{item?.sous_categorie?.nom}</p>
							</div>
						</div>
						<div className="flex-sb ">
							<div className="group-info">
								<h5 className="group_info_title">Taille</h5>
								<p className="group_info_value">
									{item?.taille?.map((elem, index) => (
										<span key={item?.slug + index}>
											{elem?.nom}
											{isShowComma(item?.couleur, index)}
										</span>
									))}
								</p>
							</div>
							<div className="group-info">
								<h5 className="group_info_title">Couleur</h5>
								<p className="group_info_value">
									{item?.couleur?.map((elem, index) => (
										<span key={item?.slug + index}>
											{elem?.nom}
											{isShowComma(item?.couleur, index)}
										</span>
									))}
								</p>
							</div>
							<div className="group-info">
								<h5 className="group_info_title">marque</h5>
								<p className="group_info_value">{item?.marque}</p>
							</div>
						</div>
						<div className="flex-sb ">
							<div className="group-info">
								<h5 className="group_info_title">état du vêtement</h5>
								<p className="group_info_value">{item?.etat?.nom}</p>
							</div>
							<div className="group-info">
								<h5 className="group_info_title">Prix</h5>
								<p className="group_info_value">{formatMontant(item?.prix)}</p>
							</div>
						</div>
					</div>
					{item?.marketplace?.length > 0 && (
						<div className="group-info">
							<h5 className="group_info_title">Diffusé sur :</h5>
							<div className="flex-m gap-2">
								{item?.marketplace?.map((item) => (
									<div className="img__container" key={item?.slug}>
										<img
											src={getImage(item?.logo)}
											alt="MarketPlace"
											className="logo-brodcast"
										/>
									</div>
								))}
							</div>
						</div>
					)}
					<div className="flex-c mt-5">
						<Link
							to={`/user/mon-stock/pepites/${item?.slug}/modifier`}
							className="btn__primary fashion__btn d-block text-center text-decoration-none"
							style={{ backgroundColor: Color.plight, color: Color.dark }}
							state={item}
						>
							modifier les informations
						</Link>
					</div>
				</div>
			</div>
			<DeletePepiteModal
				message="En supprimant cette pépite, son annonce sera supprimée sur toutes
						les marketplaces où elle est diffusée ainsi que toutes ses
						informations."
				item={item}
			/>
		</div>
	);
};

export default DetailPepite;
