import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";

interface TableProps {
	headers: string[];
}

const TableSkeleton: React.FC<TableProps> = ({ headers }) => {
	return (
		<div className="col-md-12">
			<table className="table table striped">
				<thead>
					<tr>
						{headers?.map((col, i) => (
							<th
								scope="col"
								style={{
									fontFamily: "Urbanist",
									fontStyle: "normal",
									fontWeight: "500",
									fontSize: "16px",
									lineHeight: "19px",
									color: "#333333",
								}}
								key={col + i}
							>
								{col}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{[...Array(4)]?.map((item, i) => (
						<tr key={i}>
							{headers?.map((col, i) => (
								<td key={col + i}>
									<VolkenoSkeleton
										variant="rect"
										width="100%"
										height={50}
										style={{ backgroundColor: "#faf9f7" }}
									/>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TableSkeleton;
