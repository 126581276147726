import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Logo.png";
import { Color } from "../../../utils/theme";
import { BtnBack } from "../../common/Button";
import Gallery from "../../common/Gallery";
import "./Auth.css";

interface Props {
	children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
	return (
		<div className="auth-page">
			<div className="row auth-row">
				<div className="col-lg-7 auth-start-container">
					<div className="d-lg-flex justify-content-lg-around">
						<nav className="no-view-mobile">
							<Link className="logo-container pt-3" to="/">
								<img src={Logo} alt="fashion-logo" className="logo-app" />
							</Link>
						</nav>
						<div className="auth-form-container w-lg-70">
							{/* Children */}
							{children}
						</div>
					</div>
				</div>
				<div className="col-lg-5 view-lg">
					<Gallery />
				</div>
			</div>
		</div>
	);
};

export function AuthMobileBanner({ title }: { title: string }) {
	return (
		<div className="auth-title-container d-sm-none flex-m-sb px-2 py-2">
			<BtnBack label="" color={Color.dark} fontSize={16} />
			<h1 className="auth-title w-100">{title}</h1>
		</div>
	);
}

export default AuthLayout;
