import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import { useLazyCheckMarketPlaceTokenValidityQuery } from "../../../../utils/api/marketplace/marketplace.api";
import {
	useGetPepitesByUserQuery,
	useGetShopifyProductsMutation,
} from "../../../../utils/api/pepite/pepite.api";
import { IPepite, IShopifyProduct } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { calculatePageCount, useModal } from "../../../../utils/utils";
import { useFilterPepite } from "../../admin/Pepites/Pepites";
import { checkDiffusionStatus, checkifCodeExitsAndSendIt } from "./hooks/stockUtils";

export function useGetPepite() {
	const [item, setItem] = useState<IPepite>();
	const getCurrentPepite = (item: IPepite) => {
		setItem(item);
	};
	return {
		item,
		getCurrentPepite,
	};
}

export function useCheckTokenStatus() {
	const [checkMarketPlaceTokenValidity, { data: tokenStatus }] =
		useLazyCheckMarketPlaceTokenValidityQuery();

	const onCheckTokenStatus = () => {
		console.log("check");
		checkMarketPlaceTokenValidity();
	};

	return {
		tokenStatus,
		onCheckTokenStatus,
	};
}

export const useStock = () => {
	const [page, setPage] = useState(1);
	const { user } = useAppSelector((s) => s.user);
	const { pepiteName, status, handleFilterPepiteByName, handleFilterPepiteByStatut } =
		useFilterPepite();
	const { item, getCurrentPepite } = useGetPepite();
	const perPage = 10;
	const { data, isLoading } = useGetPepitesByUserQuery({
		page,
		limit: perPage,
		nom: pepiteName,
		en_ligne: status,
		slug: user?.slug,
	});

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	checkDiffusionStatus();
	checkifCodeExitsAndSendIt();

	return {
		data,
		handleFilterPepiteByName,
		handleFilterPepiteByStatut,
		isLoading,
		setPage,
		item,
		getCurrentPepite,
		handlePageClick,
		perPage,
		pageCount,
		isShowPagination: data?.count && data?.count > perPage,
	};
};

export const useShopifyProducts = (isOpen: boolean) => {
	const itemsPerPage = 10;
	const [itemOffset, setItemOffset] = useState(0);
	const { user } = useAppSelector((s) => s.user);
	const endOffset = itemOffset + itemsPerPage;

	const [getShopifyProducts, { isLoading, data }] = useGetShopifyProductsMutation();
	const [products, setProducts] = useState<IShopifyProduct[]>();

	useEffect(() => {
		if (isOpen) {
			getShopifyProducts({
				user: user?.id,
				boutique: user?.boutique[0]?.id,
			});
		}
	}, [isOpen]);
	useEffect(() => {
		if (data) {
			setProducts(data?.slice(itemOffset, endOffset));
		}
	}, [data, itemOffset, itemsPerPage]);

	const onFilterProductsByTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.value) {
			const searchValue = e.currentTarget.value.toLowerCase();
			const items = products?.filter((product) =>
				product?.title?.toLowerCase()?.includes(searchValue)
			);
			setProducts(items);
		} else {
			setProducts(data?.slice(itemOffset, endOffset));
		}
	};

	const pageCount = calculatePageCount(data?.length, itemsPerPage);

	const handlePageClick = (event: { selected: number }) => {
		console.log(event?.selected);
		if (data?.length) {
			const newOffset = (event.selected * itemsPerPage) % data?.length;
			console.log(
				`User requested page number ${event.selected}, which is offset ${newOffset}`
			);
			setItemOffset(newOffset);
		}
	};

	return {
		handlePageClick,
		perPage: itemsPerPage,
		pageCount,
		isShowPagination: data?.length && data?.length > itemsPerPage,
		onFilterProductsByTitle,
		products,
		isLoading,
	};
};

export const useOpenDowloadShopifyProductModal = () => {
	const { toggle, isShowModal, closeModal } = useModal<IPepite>();

	const { user } = useAppSelector((s) => s?.user);

	const navigate = useNavigate();
	const openDowloadModal = () => {
		if (user?.shopify_token && user?.shopify_store) {
			toggle();
		} else {
			Swal.fire({
				icon: "info",
				title: `Informations`,
				text: "Vos devez renseignez vos identifiants de Shopify pour pouvoir importer vos produits, pour cela cliquer sur le bouton ci-dessous et suivez la procédure",
				showConfirmButton: true,
				confirmButtonText: "Renseignez",
				confirmButtonColor: Color.primary,
			}).then(() => {
				navigate("/user/modifier-profil");
			});
		}
	};

	return {
		openDowloadModal,
		isShowModal,
		closeModal,
	};
};

export const useGetProducts = () => {
	const [page, setPage] = useState(1);
	const { user } = useAppSelector((s) => s.user);
	const [search, setSearch] = useState<string>("");

	const [item, setItem] = useState<IPepite>();
	const getCurrentPepite = (item: IPepite) => {
		setItem(item);
	};

	const handleFilterPepiteByName = (e: React.FormEvent<HTMLInputElement>): void => {
		setSearch(e.currentTarget.value);
	};
	const perPage = 10;
	const { data, isLoading } = useGetPepitesByUserQuery({
		page,
		limit: perPage,
		nom: search,
		slug: user?.slug,
		en_ligne: "off",
	});

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	return {
		data: data?.results,
		handleFilterPepiteByName,
		isLoading,
		setPage,
		item,
		getCurrentPepite,
		handlePageClick,
		perPage,
		pageCount,
		isShowPagination: data?.count && data?.count > perPage,
		productsIsMoreThanOne: data && data?.results?.length > 1,
	};
};
