import React from "react";
import Couleur from "./Carateristiques/Couleurs";
import Etat from "./Carateristiques/Etat";
import Taille from "./Carateristiques/Taille";

const Caracteristiques = () => {
	return (
		<div>
			<h3 className="params-title">Caractéristiques</h3>
			<Taille />
			<Etat />
			<Couleur />
		</div>
	);
};

export default Caracteristiques;
export const CaracteristiqueCard = ({ children }: { children: React.ReactNode }) => {
	return (
		<div
			style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)" }}
			className="bg-white p-3 mb-4"
		>
			{children}
		</div>
	);
};
