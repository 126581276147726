import React from "react";
import CategorieTable from "../../tables/admin/CategorieTable/CategoryTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";

const Categories = () => {
	return (
		<div className="bloc-entete-admin-container">
			<AdminCard>
				<CategorieTable />
			</AdminCard>
		</div>
	);
};

export default Categories;
