import React from "react";
import { Color } from "../../../../utils/theme";
import { InputIcon } from "../../../common/Input";
import { IconDissatisfied } from "../../../shared/Icons";
import { useDeleteUserAccount } from "../../user/Profil/useProfilForm/useDeleteUserAccount";

const DeleteAccountModal = () => {
	const { register, onSubmit, errors } = useDeleteUserAccount();
	return (
		<div
			className="modal fade"
			id="DeleteAccountModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="DeleteAccountModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<form onSubmit={onSubmit}>
							<div className="modal__header">
								<div className="flex-c mb-3">
									<IconDissatisfied />
								</div>
								<h2 className="modal__title">Supprimer votre compte</h2>
							</div>
							<div className="modal__body mt-3">
								<h3 className="modal-ask-logout mb-4">
									Vous êtes sur le point de supprimer votre compte. Afin de
									confirmer votre demande, merci de saisir votre mot de passe.
								</h3>
							</div>
							<div className="input-container">
								<InputIcon
									label="Mot de passe*"
									id="password"
									{...register("password")}
									error={errors?.password}
								/>
							</div>
							<div className="d-flex flex-column flex-sm-row justify-content-sm-end  gap-3 gap-sm-5 mt-5">
								<button
									type="button"
									className="fashion__btn btn__primary"
									data-bs-dismiss="modal"
									style={{ color: Color.dark, backgroundColor: Color.plight }}
								>
									Annuler
								</button>
								<button
									type="submit"
									className="fashion__btn btn_delete text-center  w-50"
								>
									Supprimer mon compte
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeleteAccountModal;
