import React from "react";
import { ModalBody } from "reactstrap";
import { Modal } from "reactstrap";
import { IMarketPlace, TypeMarketplace } from "../../../../utils/api/marketplace/marketplace.type";
import { FormModalProps } from "../../../../utils/type";
import { getImage } from "../../../../utils/utils";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { FormError, InputAdmin } from "../../../common/Input";
import useCrudMarketPlaceForm from "../../admin/Marketplace/useForm/useCrudMarketPlaceForm";

const AddMarketPlaceModal = ({
	item,
	setItem,
	isOpen,
	closeModal,
}: FormModalProps<IMarketPlace>) => {
	const { register, onSubmit, errors, onChangeLogoMarket, logoMarket, isLoading } =
		useCrudMarketPlaceForm(closeModal, item, setItem);
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-content fas__modal  px-3 py-4">
				<div className="container-fluid mx-auto">
					<div className="flex-sb">
						<h4 className="fas-modal-title mb-4">
							{item ? "Modifier la" : "Ajouter une "} marketplace
						</h4>
						<CloseModalButton onClick={closeModal} />
					</div>
					<ModalBody>
						<form onSubmit={onSubmit}>
							<div className="row">
								{/* <div className="col-12">
									<InputAdmin
										label="Nom de la marketplace*"
										id="nom"
										type="text"
										placeholder="Nom de la marketplace"
										{...register("nom")}
										error={errors?.nom}
									/>
								</div> */}
								<div className="col-12 mb-3">
									<label htmlFor="option-offre">Nom de la marketplace*</label>
									<select
										id="option-offre"
										className="form-select form-control-lg form-control-admin"
										{...register("nom")}
									>
										<option value={TypeMarketplace.etsy}>Etsy</option>
										<option value={TypeMarketplace.ebay}>Ebay</option>
									</select>
									<FormError error={errors?.nom} />
								</div>
								<div className="col-12">
									<InputAdmin
										label="Logo"
										id="logo"
										type="file"
										placeholder="Logo"
										onChange={onChangeLogoMarket}
										error={errors?.logo}
										multiple={false}
									/>
								</div>
								{(logoMarket || item?.logo) && (
									<div className="col-12 mb-3">
										<img
											src={logoMarket ? logoMarket : getImage(item?.logo)}
											alt="logo-marketplace"
											className="img-thumbnail"
										/>
									</div>
								)}
								<div className="col-12">
									<InputAdmin
										label="Lien"
										id="lien"
										type="url"
										placeholder="Lien"
										{...register("lien")}
										error={errors?.lien}
									/>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<CloseModalButton text="Fermer" onClick={closeModal} />
								<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
							</div>
						</form>
					</ModalBody>
				</div>
			</div>
		</Modal>
	);
};

export default AddMarketPlaceModal;
