import { Navigate } from "react-router-dom";
import ForgotPassword from "../components/modules/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/modules/auth/ForgotPassword/ResetPassword";
import SignIn from "../components/modules/auth/Signin/Signin";
import SignUp from "../components/modules/auth/Signup/Signup";
import Dashboard from "../components/modules/user/Dashboard/Dashboard";
import Diffuser from "../components/modules/user/Diffuser/Diffuser";
import UserLayout from "../components/modules/user/Layouts/UserLayout";
import AddPepite from "../components/modules/user/AddPepite/AddPepite";
import MonStock from "../components/modules/user/Stock/MonStock";
import MesVentes from "../components/modules/user/Ventes/MesVentes";
import DetailPepite from "../components/modules/user/Stock/DetailPepite";
import Profil from "../components/modules/user/Profil/Profil";
import EditProfil from "../components/modules/user/Profil/EditProfil";
import EditPepite from "../components/modules/user/EditPepite/EditPepite";
import Faq from "../components/modules/user/Faq/Faq";
import AdminSignIn from "../components/modules/admin/AdminSignIn/AdminSignIn";
import AdminDashboard from "../components/modules/admin/Dashboard/AdminDashboard";
import AdminLayout from "../components/modules/admin/AdminLayouts/AdminLayout";
import Sellers from "../components/modules/admin/Sellers/Sellers";
import Pepites from "../components/modules/admin/Pepites/Pepites";
import Marketplace from "../components/modules/admin/Marketplace/Marketplace";
import Abonnement from "../components/modules/admin/Abonnement/Abonnement";
import Messages from "../components/modules/admin/Messages/Messages";
import GestionFaq from "../components/modules/admin/Faq/GestionFaq";
import Parametres from "../components/modules/admin/Parametres/Parametres";
import AddSeller from "../components/modules/admin/Sellers/AddSeller";
import DetailSeller from "../components/modules/admin/Sellers/DetailSeller";
import AdminAddPepites from "../components/modules/admin/Pepites/AdminAddPepites";
import AddAbonnement from "../components/modules/admin/Abonnement/AddAbonnement";
import DetailAbonnement from "../components/modules/admin/Abonnement/DetailAbonnement";
import ToutesLesOffres from "../components/modules/user/MonAbonnement/ToutesLesOffres";
import ProfilAdmin from "../components/modules/admin/Profil/ProfilAdmin";
import DetailProfilAdmin from "../components/modules/admin/Profil/DetailProfilAdmin";
import { ProtectedRoutes, RedirectAuthRoute } from "./routerHelper";
import { UserType } from "../utils/api/user/user.type";
import Categories from "../components/modules/admin/Categories/Categories";
import DetailAdminPepite from "../components/modules/admin/Pepites/DetailPepite";
import AdminEditPepite from "../components/modules/admin/Pepites/AdminEditPepite";
import MesCollections from "../components/modules/user/collection/MesCollections";
import DetailCollection from "../components/modules/user/collection/DetailCollection";
import MessagesContact from "../components/modules/user/Messages/MessagesContact";
import EditAdminProfil from "../components/modules/admin/Profil/EditAdminProfil";
import SellersTrash from "../components/modules/admin/Sellers/SellersTrash";

const userRoutes = [
	{ element: <Navigate to="/user/dashboard" replace />, index: true },
	{
		path: "dashboard",
		element: <Dashboard />,
	},
	{
		path: "mon-stock",
		element: <MonStock />,
	},
	{
		path: "mon-stock/pepites/:slug",
		element: <DetailPepite />,
	},
	{
		path: "diffuser",
		element: <Diffuser />,
	},
	{
		path: "mes-ventes",
		element: <MesVentes />,
	},
	{
		path: "mon-stock/ajouter-une-pepite",
		element: <AddPepite />,
	},
	{
		path: "mon-stock/pepites/:slug/modifier",
		element: <EditPepite />,
	},
	{
		path: "profil",
		element: <Profil />,
	},
	{
		path: "modifier-profil",
		element: <EditProfil />,
	},
	{
		path: "mes-collections",
		element: <MesCollections />,
	},
	{
		path: "mes-collections/:slug",
		element: <DetailCollection />,
	},
	{
		path: "mes-messages",
		element: <MessagesContact />,
	},
];

const adminRoutes = [
	{ element: <Navigate to="/admin/dashboard" replace />, index: true },
	{
		path: "dashboard",
		element: <AdminDashboard />,
	},
	{
		path: "vendeurs",
		element: <Sellers />,
	},
	{
		path: "vendeurs/ajouter-un-vendeur",
		element: <AddSeller />,
	},
	{
		path: "vendeurs/:slug",
		element: <DetailSeller />,
	},
	{
		path: "corbeille",
		element: <SellersTrash />,
	},
	{
		path: "vendeurs/:slug/modifier",
		element: <AddSeller />,
	},
	{
		path: "pepites",
		element: <Pepites />,
	},
	{
		path: "pepites/:slug",
		element: <DetailAdminPepite />,
	},
	{
		path: "pepites/:slug/modifier",
		element: <AdminEditPepite />,
	},
	{
		path: "pepites/ajouter",
		element: <AdminAddPepites />,
	},
	{
		path: "marketplace",
		element: <Marketplace />,
	},
	{
		path: "abonnements",
		element: <Abonnement />,
	},
	{
		path: "abonnements/:slug",
		element: <DetailAbonnement />,
	},
	{
		path: "abonnements/ajouter-un-abonnement",
		element: <AddAbonnement />,
	},
	{
		path: "abonnements/:slug/modifier",
		element: <AddAbonnement />,
	},
	{
		path: "messages",
		element: <Messages />,
	},
	{
		path: "faq",
		element: <GestionFaq />,
	},
	{
		path: "parametres",
		element: <Parametres />,
	},
	{
		path: ":slug/profil",
		element: <DetailProfilAdmin />,
	},
	{
		path: "profil",
		element: <ProfilAdmin />,
	},
	{
		path: ":slug/modifier",
		element: <EditAdminProfil />,
	},
	{
		path: "categories",
		element: <Categories />,
	},
];

export const AppRoutes = [
	/******** Auth user Routes ********/
	{ element: <Navigate to="/se-connecter" replace />, index: true },
	{
		path: "/se-connecter",
		element: (
			<RedirectAuthRoute>
				<SignIn />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/inscription",
		element: (
			<RedirectAuthRoute>
				<SignUp />
			</RedirectAuthRoute>
		),
	},
	{
		path: "/mot-de-passe-oublie",
		element: <ForgotPassword />,
	},
	{
		path: "/renitialiser-le-mot-de-passe",
		element: <ResetPassword />,
	},
	{
		path: "faq",
		element: <Faq />,
	},
	{
		path: "toutes-les-offres",
		element: <ToutesLesOffres />,
	},
	/******** User Routes ********/
	{
		path: "user",
		element: (
			<ProtectedRoutes userType={[UserType.vendeur]}>
				<UserLayout />
			</ProtectedRoutes>
		),
		children: userRoutes,
	},
	/******** Admin Routes ********/
	{
		path: "admin/connexion",
		element: (
			<RedirectAuthRoute>
				<AdminSignIn />
			</RedirectAuthRoute>
		),
	},
	{
		path: "admin",
		element: (
			<ProtectedRoutes userType={[UserType.admin, UserType.superAdmin]}>
				<AdminLayout />
			</ProtectedRoutes>
		),
		children: adminRoutes,
	},
];

export default AppRoutes;
