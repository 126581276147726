import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import InformationsPersonnelles from "./Steps/InformationsPersonnelles";
import "./Onboarding.css";
import InfosBoutiques from "./Steps/InfosBoutiques";
import AdresseFacturation from "./Steps/AdresseFacturation";
import Abonnement from "./Steps/Abonnement";
import Finalisation from "./Steps/Finalisation";
import { useStepType } from "../../../../utils/type";
import { FirstSignupFormWrapper, OnBoardingWrapper } from "./OnBoarding";
import AuthInfos from "./Steps/AuthInfos";
import useSignupForm from "../useAuthForm/useSignupForm";
import { MyStepperContext } from "../../../../utils/StepperContext";

const steps = [
	{
		id: "auth-info",
		Component: AuthInfos,
	},
	{
		id: "personal-info",
		Component: InformationsPersonnelles,
	},
	{
		id: "shop-info",
		Component: InfosBoutiques,
	},
	{
		id: "adresse-facturation",
		Component: AdresseFacturation,
	},
	{
		id: "abonnement",
		Component: Abonnement,
	},
	{
		id: "finalisation",
		Component: Finalisation,
	},
];

const SignUp: React.FC = () => {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		setValue,
		data,
		checkedId,
		handleChoiceOffer,
		isShowOffer,
		checkedModePayementId,
		handleChoiceModePaiement,
		isOpen,
		toggle,
		offer,
	} = useSignupForm(navigation);
	const { Component } = step;
	const props = {
		navigation,
		index,
		register,
		onSubmit,
		errors,
		isLoading,
		data,
		checkedId,
		handleChoiceOffer,
		setValue,
		isShowOffer,
		checkedModePayementId,
		handleChoiceModePaiement,
		isOpen,
		toggle,
		offer,
	};
	// console.log({ index });
	useEffect(() => {
		window.scrollTo(10, 10);
		setValue("step", index + 1);
	}, [index]);

	const onClickStep = (key: number) => {
		// console.log(key);
		if (index > key) {
			navigation.go(key + 1);
			setValue("step", key + 1);
		}
	};
	const onRestartStep = () => {
		navigation.go(0);
		setValue("step", 0);
	};
	return (
		<>
			{index === 0 ? (
				<FirstSignupFormWrapper>
					<Component {...props} />
				</FirstSignupFormWrapper>
			) : (
				<MyStepperContext.Provider value={{ onClickStep }}>
					<OnBoardingWrapper index={index} onRestart={onRestartStep}>
						<Component {...props} />
					</OnBoardingWrapper>
				</MyStepperContext.Provider>
			)}
		</>
	);
};

export default SignUp;
