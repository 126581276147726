import { ItokenStatus } from "../../../../utils/api/marketplace/marketplace.type";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { BtnPrimary } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import { IconShare } from "../../../shared/Icons";
import { RadioDiffusionPlatforme } from "../AddPepite/Steps/Diffusion";
import { SmallPepiteCard } from "../Stock/PepiteCard";
import useDiffusion from "./useForm/useDiffusion";

const DiffuserModalPepite = (props: { item?: IPepite; tokenStatus?: ItokenStatus }) => {
	const { item, tokenStatus } = props;
	const { onSubmit, isLoading, checked, handleChecked, errors, cleannerChecked } =
		useDiffusion(item);
	// console.log({ tokenStatus });
	return (
		<div
			className="modal fade"
			id="DiffuserModalPepite"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="DiffuserModalPepiteLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<form action="" onSubmit={onSubmit}>
							<div className="modal__header">
								<div className="flex-c mb-3 icon__share">
									<IconShare />
								</div>
								<h2 className="modal__title">Diffuser cette pépite</h2>
							</div>
							<div className="modal__body mt-3">
								<SmallPepiteCard pepite={item} />
							</div>
							<div>
								{/* <DiffusionPlatforme
									checked={checked}
									handleChecked={handleChecked}
									title="Sélectionnez vos marketplaces"
									imgStyle={true}
								/> */}
								<RadioDiffusionPlatforme
									checked={checked}
									handleChecked={handleChecked}
									title="Sélectionnez vos marketplaces"
									imgStyle={true}
									id="diffusion_simple"
									tokenStatus={tokenStatus}
								/>
								<FormError error={errors?.marketplace} />
							</div>
							<div className="modal__footer flex-end gap-5 mt-5">
								<BtnPrimary
									label="diffuser"
									isLoading={isLoading}
									mode="Secondary"
								/>
								<button
									type="button"
									className="fashion__btn"
									data-bs-dismiss="modal"
									style={{ color: Color.red }}
									onClick={cleannerChecked}
								>
									Annuler
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DiffuserModalPepite;
