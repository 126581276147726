import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { BoutiqueFormData, IBoutique } from "./boutique.type";

export const BoutiqueApi = createApi({
	reducerPath: "boutiqueAPi",
	tagTypes: ["boutique"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getBoutique: builder.query<PaginationResults<IBoutique>, { nom_boutique?: string }>({
			query: (query) => QueryUrl(`boutiques/`, query),
			providesTags: ["boutique"],
		}),
		addOrUpdateBoutique: builder.mutation<
			IBoutique,
			{ slug?: string; data: BoutiqueFormData | FormData }
		>({
			invalidatesTags: ["boutique"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `boutiques/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `boutiques/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteBoutique: builder.mutation<IBoutique, string>({
			query: (slug) => ({
				url: `boutiques/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["boutique"],
		}),
	}),
});

export const {
	useGetBoutiqueQuery,
	useLazyGetBoutiqueQuery,
	useAddOrUpdateBoutiqueMutation,
	useDeleteBoutiqueMutation,
} = BoutiqueApi;
