import React from "react";
import "./style/Gallery.css";
import Image1 from "../../assets/images/Backup/image1.png";
import Image2 from "../../assets/images/Backup/image2.png";
import Image3 from "../../assets/images/Backup/image3.png";
import Image4 from "../../assets/images/Backup/image4.png";
import Image5 from "../../assets/images/Backup/image5.png";
import Image6 from "../../assets/images/Backup/image6.png";
import Image7 from "../../assets/images/Backup/image7.png";
import Image8 from "../../assets/images/Backup/image8.png";
import Image9 from "../../assets/images/Backup/image9.png";
import Image10 from "../../assets/images/Backup/image10.png";
import Image11 from "../../assets/images/Backup/image11.png";
import Image12 from "../../assets/images/Backup/image12.png";
import Image13 from "../../assets/images/Backup/image13.png";
import Image14 from "../../assets/images/Backup/image14.png";
import Image15 from "../../assets/images/Backup/image15.png";
import Image16 from "../../assets/images/Backup/image16.png";
import Image17 from "../../assets/images/Backup/image17.png";

const Gallery: React.FC = () => {
	return (
		<div className="gallery">
			<div className="gallery--item gallery-col-1 gallerry-item--1">
				<img className="gallery--img" src={Image1} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-2 gallerry-item--2">
				<img className="gallery--img" src={Image2} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-3 gallerry-item--3">
				<img className="gallery--img" src={Image3} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-4 gallerry-item--4">
				<img className="gallery--img" src={Image4} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-1 gallerry-item--5">
				<img className="gallery--img" src={Image5} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-2 gallerry-item--6">
				<img className="gallery--img" src={Image6} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-3 gallerry-item--7">
				<img className="gallery--img" src={Image7} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-4 gallerry-item--8">
				<img className="gallery--img" src={Image8} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-1 gallerry-item--9">
				<img className="gallery--img" src={Image9} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-2 gallerry-item--10">
				<img className="gallery--img" src={Image10} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-3 gallerry-item--11">
				<img className="gallery--img" src={Image11} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-4 gallerry-item--12">
				<img className="gallery--img" src={Image12} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-1 gallerry-item--13">
				<img className="gallery--img" src={Image13} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-2 gallerry-item--14">
				<img className="gallery--img" src={Image15} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-3 gallerry-item--15">
				<img className="gallery--img" src={Image16} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-4 gallerry-item--16">
				<img className="gallery--img" src={Image17} alt="gallery-img" />
			</div>
			<div className="gallery--item gallery-col-1 gallerry-item--17">
				<img className="gallery--img" src={Image14} alt="gallery-img" />
			</div>
		</div>
	);
};

export default Gallery;
