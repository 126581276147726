import React from "react";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import UseFormChangePassword from "../../admin/Parametres/useParametreForm/UseFormChangePassword";

const ChangePasswordModal = () => {
	const { register, onSubmit, errors, isLoading } = UseFormChangePassword();
	return (
		<div
			className="modal fade"
			id="ChangePasswordModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="ChangePasswordModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<div className="flex-m-sb mb-5">
							<h4 className="fas-modal-title">Modifier mot de passe</h4>
							<CloseModalButton />
						</div>

						<form onSubmit={onSubmit}>
							<InputAdmin
								label="Ancien mot de passe"
								id="password"
								type="password"
								{...register("old_password")}
								error={errors?.old_password}
							/>
							<InputAdmin
								label="Nouveau mot de passe"
								id="new_password"
								type="password"
								{...register("new_password")}
								error={errors?.new_password}
							/>
							<InputAdmin
								label="Confirmer  mot de passe"
								id="confirm_password"
								type="password"
								{...register("confirm_password")}
								error={errors?.confirm_password}
							/>

							<div className="flex-end mt-5 gap-4">
								<CloseModalButton text="Fermer" />
								<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePasswordModal;
