import React from "react";
import { IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { Spinner } from "../../../../common/Button";

const Valider: React.FC<IStepsCrudPepiteProps> = ({ onSubmit, isLoading, formValues }) => {
	console.log(formValues?.online);
	return (
		<>
			<h2>Valider votre pépite</h2>
			<h4 className="success__message mt-3 mb-1">
				{formValues?.online
					? "Vous avez terminé de remplir tous les champs pour la diffusion de votre pépite."
					: "Vous avez terminé de remplir tous les champs pour ajouter une pépite."}
			</h4>
			<div>
				<div>
					<p>
						{formValues?.online
							? "En validant votre pépite, elle sera diffusée sur les marketplaces de votre choix. Vous pourrez à tout moment modifier ses informations dans l’onglet Mon stock."
							: "Vous pourrez à tout moment diffuser cette pépite sur les marketplaces en vous rendant dans l’onglet mon stock."}
					</p>
				</div>
				<form className="flex-c pt-5" onSubmit={onSubmit}>
					<button
						className="btn__add fashion__btn py-3"
						type="submit"
						disabled={isLoading}
					>
						{isLoading ? <Spinner /> : "ajouter"}
					</button>
				</form>
			</div>
		</>
	);
};

export default Valider;
