import React from "react";
import AuthLayout, { AuthMobileBanner } from "../AuthLayout";
import { BtnBack, BtnPrimary } from "../../../common/Button";
import { Input, InputIcon } from "../../../common/Input";
import RequiredMessage from "../../../common/RequiredMessage";
import { UseResetPasswordForm } from "../useAuthForm/UseResetPasswordForm";

const ResetPassword = () => {
	const { register, onSubmit, errors, isLoading } = UseResetPasswordForm();
	return (
		<AuthLayout>
			<AuthMobileBanner title="Rénitialisez votre mot de passe" />
			<div className="px-2 px-md-4 px-lg-1">
				<div className="mb-4 view-lg">
					<BtnBack />
				</div>
				<div className="d-lg-none mt-3">
					<h2 className="auth-title-mobile text-sm-center">
						Rénitialisez votre mot de passe
					</h2>
				</div>
				<div className="mb-4">
					<p className="auth-accroche-text">
						Veuillez renseigner votre nouveau mot de passe dans les champs ci-dessous :
					</p>
				</div>
				<form onSubmit={onSubmit}>
					<RequiredMessage />
					<Input
						label="Code*"
						type="text"
						id="email"
						{...register("code")}
						error={errors?.code}
					/>
					<InputIcon
						label="Mot de passe*"
						id="password"
						{...register("new_password")}
						error={errors?.new_password}
					/>
					<InputIcon
						label="Confirmation du mot de passe*"
						id="confirm-password"
						{...register("new_password_confirm")}
						error={errors?.new_password_confirm}
					/>
					<div className="btn-container mt-5">
						<BtnPrimary label="Envoyer ma demande" width="w-100" isLoading={isLoading}/>
					</div>
				</form>
			</div>
		</AuthLayout>
	);
};

export default ResetPassword;
