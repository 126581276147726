import { BsCardText, BsGrid, BsPerson, BsXDiamond } from "react-icons/bs";
import { AiOutlineShop } from "react-icons/ai";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { MdGridView, MdOutlineMessage } from "react-icons/md";
import { FiSettings } from "react-icons/fi";

export const Routes = [
	{
		label: "Dashboard",
		pathname: "/admin/dashboard",
		icon: <BsGrid />,
	},
	{
		label: "Vendeurs",
		pathname: "/admin/vendeurs",
		icon: <BsPerson />,
	},
	{
		label: "Pépites",
		pathname: "/admin/pepites",
		icon: <BsXDiamond />,
	},
	{
		label: "Marketplaces",
		pathname: "/admin/marketplace",
		icon: <AiOutlineShop />,
	},
	{
		label: "Abonnements",
		pathname: "/admin/abonnements",
		icon: <HiOutlineChartSquareBar />,
	},
	{
		label: "Catégories",
		pathname: "/admin/categories",
		icon: <MdGridView />,
	},
	{
		label: "FAQ",
		pathname: "/admin/faq",
		icon: <BsCardText />,
	},
	{
		label: "Messages",
		pathname: "/admin/messages",
		icon: <MdOutlineMessage />,
	},
	{
		label: "Paramètres",
		pathname: "/admin/parametres",
		icon: <FiSettings />,
	},
];
