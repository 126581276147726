import React, { useEffect, useState } from "react";
import { useGetCategoryQuery } from "../../../../../utils/api/category/category.api";
import { Subcategory } from "../../../../../utils/api/category/category.type";
import { useGetCouleurQuery } from "../../../../../utils/api/couleur/couleur.api";
import { useGetEtatQuery } from "../../../../../utils/api/etat/etat.api";
import { IPepite, IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { useGetTailleQuery } from "../../../../../utils/api/taille/taille.api";
import { Genres } from "../../../../../utils/constants";
import { BtnPrimary } from "../../../../common/Button";
import { CustomInput, FormError } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";
import { Select as CustomSelect } from "../../../../common/Select";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MoreCaracteristiques from "./MoreCaracteristiques";
import DimensionsPepites from "./DimensionsPepites";

const Caracteristiques: React.FC<IStepsCrudPepiteProps> = ({
	register,
	onSubmit,
	errors,
	Controller,
	control,
	onChangeColors,
	onChangeSize,
	item,
	setValue,
}) => {
	const { categories, sousCategories, handleChangeCategory, tailles, couleurs, etats } =
		useCaracteristiques(item);
	const animatedComponents = makeAnimated();
	return (
		<>
			<h2>Dites-en plus! 😀</h2>
			<form className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="row">
					<div className="col-md-6 mb-3">
						<CustomSelect
							label="Genre*"
							id="genre"
							options={Genres}
							{...register("genre")}
							error={errors?.genre}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 mb-3">
						<CustomSelect
							label="Catégorie*"
							id="category"
							options={categories?.map((item) => {
								return {
									label: item?.nom,
									value: item?.id,
								};
							})}
							{...register("categorie")}
							error={errors?.categorie}
							onChange={handleChangeCategory}
							defaultValue={item?.categorie?.id}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<CustomSelect
							label="Sous-catégorie"
							type="text"
							id="sous-category"
							options={sousCategories?.map((item) => {
								return {
									label: item?.nom,
									value: item?.id,
								};
							})}
							{...register("sous_categorie")}
							error={errors?.sous_categorie}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="taille" className="custom-form-label">
							Taille*
						</label>
						<Controller
							name="taille"
							control={control}
							render={() => {
								return (
									<Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										isMulti
										options={tailles?.map((item) => {
											return {
												label: item?.nom,
												value: item?.id,
											};
										})}
										classNamePrefix="react-select-custom"
										id="taille"
										placeholder="Taille"
										onChange={onChangeSize}
										defaultValue={item?.taille?.map((elem) => {
											return {
												label: elem?.nom,
												value: elem?.id,
											};
										})}
									/>
								);
							}}
						/>
						<FormError error={errors?.taille?.message} />
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="couleur" className="custom-form-label">
							Couleur*
						</label>
						<Controller
							name="couleur"
							control={control}
							render={() => {
								return (
									<Select
										closeMenuOnSelect={false}
										components={animatedComponents}
										isMulti
										options={couleurs?.map((item) => {
											return {
												label: item?.nom,
												value: item?.id,
											};
										})}
										classNamePrefix="react-select-custom"
										id="couleur"
										placeholder="Couleur"
										onChange={onChangeColors}
										defaultValue={item?.couleur?.map((elem) => {
											return {
												label: elem?.nom,
												value: elem?.id,
											};
										})}
									/>
								);
							}}
						/>
						<FormError error={errors?.couleur?.message} />
					</div>
					<div className="col-md-6 mb-3">
						<CustomInput
							firstLabel="Marque"
							label="Marque"
							type="text"
							id="marque"
							{...register("marque")}
							error={errors?.marque}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<CustomSelect
							label="État du produit*"
							id="etat"
							options={etats?.map((item) => {
								return {
									label: item?.nom,
									value: item?.id,
								};
							})}
							{...register("etat")}
							error={errors?.etat}
							defaultValue={item?.etat?.id}
						/>
					</div>
				</div>
				<MoreCaracteristiques
					register={register}
					errors={errors}
					setValue={setValue}
					pepite={item}
				/>
				<DimensionsPepites register={register} errors={errors} setValue={setValue} />
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</>
	);
};

const useCaracteristiques = (item: IPepite) => {
	// const cat = AppStorage.getItem<ICategory[]>("categories");
	const { data: categories } = useGetCategoryQuery({});
	const { data: tailles } = useGetTailleQuery();
	const { data: couleurs } = useGetCouleurQuery();
	const { data: etats } = useGetEtatQuery();

	const [sousCategories, setSousCategories] = useState<Subcategory[]>();

	const handleChangeCategory = (e: React.FormEvent<HTMLSelectElement>) => {
		if (categories && e.currentTarget.value) {
			const selectCategory = categories?.results?.find(
				(item) => item?.id === parseInt(e.currentTarget.value)
			);
			setSousCategories(selectCategory?.souscategories);
		}
	};
	useEffect(() => {
		if (item?.categorie?.id) {
			const selectCategory = categories?.results?.find(
				(category) => category?.id === item?.categorie?.id
			);
			setSousCategories(selectCategory?.souscategories);
		}
	});
	return {
		categories: categories?.results,
		sousCategories,
		handleChangeCategory,
		tailles: tailles?.results,
		couleurs: couleurs?.results,
		etats: etats?.results,
	};
};

export default Caracteristiques;
