import React from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { BtnPrimary } from "../../../common/Button";
import { IconShare } from "../../../shared/Icons";
import { DiffusionPlatforme } from "../../user/AddPepite/Steps/Diffusion";
import { SmallPepiteCard } from "../../user/Stock/PepiteCard";

const ModifierDiffusionModalPepite = () => {
	const [checked, setChecked] = React.useState<number>();

	const handleChecked = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value) {
			setChecked(parseInt(e.currentTarget.value));
		}
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		Swal.fire({
			icon: "success",
			title: "Diffusion mis à jour !",
			text: "La diffusion de votre pépite vient d’être mis à jour sur les marketplaces.",
			iconColor: Color.success,
			showConfirmButton: true,
			confirmButtonText: "Fermer",
			customClass: {
				confirmButton: "btn-swal-close mx-4",
				container: "validation__step",
				title: "swal__success__title",
			},
		});
	};
	return (
		<div
			className="modal fade"
			id="ModifierDiffusionModalPepite"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="ModifierDiffusionModalPepiteLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<form action="" onSubmit={onSubmit}>
							<div className="modal__header">
								<div className="flex-c mb-3 icon__share">
									<IconShare />
								</div>
								<h2 className="modal__title">Modifier la diffusion</h2>
							</div>
							<div className="modal__body mt-3">
								<SmallPepiteCard />
							</div>
							<div>
								{/* <DiffusionPlatforme
									checked={checked}
									handleChecked={handleChecked}
									title="Sélectionnez vos marketplaces"
									imgStyle={true}
								/> */}
							</div>
							<div className="modal__footer flex-end gap-5 mt-5">
								<button
									type="button"
									className="fashion__btn"
									data-bs-dismiss="modal"
									style={{ color: Color.red }}
								>
									Annuler
								</button>
								<BtnPrimary label="mettre à jour" />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModifierDiffusionModalPepite;
