import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useAddOrUpdateFaqMutation } from "../../../../../utils/api/faq/faq.api";
import { FaqFormData, IFaq} from "../../../../../utils/api/faq/faq.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";

function useFaqForm(item?: IFaq ) {
	const validationSchema = yup.object().shape({
		titre: yup.string().required().label("titre"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<FaqFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [addOrUpdate, { isLoading, isSuccess, error, isError }] = useAddOrUpdateFaqMutation();

	const onChangeDescription = (description: any) => {
		setValue("description", description);
	};

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item ? "Question modifiée avec succès!" : "Question ajoutée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				if (!item) {
					reset();
				}

				onHide("AddEditFaqModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${item ? "la modification" : "l'ajout"}`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	useEffect(() => {
		if (item?.titre) {
			setValue("titre", item?.titre);
		} else {
			setValue("titre", "");
		}
	}, [item]);

	const onSubmit = (data: FaqFormData) => {
		addOrUpdate({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		onChangeDescription,
		reset
	};
}

export default useFaqForm;

