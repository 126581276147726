import { ItokenStatus } from "../../../../utils/api/marketplace/marketplace.type";
import { Color } from "../../../../utils/theme";
import { BtnPrimary } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import { IconShare } from "../../../shared/Icons";
import { RadioDiffusionPlatforme } from "../AddPepite/Steps/Diffusion";
import { useDiffusionMultiple } from "./useDiffuseMassProducts";
type Props = {
	produitsIds?: number[];
	tokenStatus?: ItokenStatus;
	reset: () => void;
};
const ConfirmDiffusionMassModal = ({ produitsIds, tokenStatus, reset }: Props) => {
	const { onSubmit, isLoading, checked, handleChecked, errors, cleannerChecked } =
		useDiffusionMultiple(reset, produitsIds);
	return (
		<form action="" onSubmit={onSubmit}>
			<div className="modal__header">
				<div className="flex-c mb-3 icon__share">
					<IconShare />
				</div>
				<h2 className="modal__title">Diffuser les pépites</h2>
			</div>
			<div>
				<RadioDiffusionPlatforme
					checked={checked}
					handleChecked={handleChecked}
					title="Sélectionnez vos marketplaces"
					imgStyle={true}
					id="diffusion_multiple"
					tokenStatus={tokenStatus}
				/>
				<FormError error={errors?.marketplace} />
			</div>
			<div className="modal__footer flex-end gap-5 mt-5">
				<BtnPrimary label="diffuser" isLoading={isLoading} mode="Secondary" />
				<button
					type="button"
					className="fashion__btn"
					data-bs-dismiss="modal"
					style={{ color: Color.red }}
					onClick={cleannerChecked}
				>
					Annuler
				</button>
			</div>
		</form>
	);
};

export default ConfirmDiffusionMassModal;
