import React from "react";
import ReactQuill from "react-quill";
import { FrequenceType, IOffer, OfferOptionType } from "../../../../utils/api/offer/offer.type";
import { useLocationState } from "../../../../utils/utils";
import Breadcrumb from "../../../common/Breadcrumb";
import { ButtonSubmit } from "../../../common/Button";
import { FormError, InputAdmin } from "../../../common/Input";
import { IconEuroOrange } from "../../../shared/Icons";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import UseCrudAbonnement from "./useForm/UseCrudAbonnement";

const BreadRoutes = [
	{ name: "Abonnement", path: "/admin/abonnements" },
	{ name: "Ajouter un abonnement", path: "/admin/abonnements/ajouter-un-abonnement" },
];
const BreadCrumbRoutes = [
	{ name: "Abonnement", path: "/admin/abonnements" },
	{ name: "Modifier un abonnement", path: "/admin/abonnements/:slug/modifer" },
];
const AddAbonnement = () => {
	const item = useLocationState<IOffer>(undefined);
	const { register, onSubmit, errors, response, handleChange, isLoading } =
		UseCrudAbonnement(item);
	return (
		<div className="abonnement__page">
			<h1>Abonnement</h1>
			{item?.slug ? (
				<Breadcrumb routes={BreadCrumbRoutes} />
			) : (
				<Breadcrumb routes={BreadRoutes} />
			)}
			<AdminCard marginTop={false}>
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-6 mb-2">
							<InputAdmin
								label="Nom  de l’abonnement"
								id="nom_abonnement"
								type="text"
								{...register("title")}
								error={errors?.title}
							/>
						</div>
						<div className="col-md-6 mb-2">
							<div>
								<div className="flex-sb">
									<label htmlFor="prix" className="form-admin-label">
										Prix
									</label>
									<div className="flex-sb gap-4">
										{errors?.frequence && (
											<small className="text-danger">
												{errors?.frequence?.message}
											</small>
										)}
										<div className="form-group d-flex gap-1">
											<input
												type="radio"
												id="annuel"
												className="form-check-input fas__check"
												style={{ width: 16, height: 16 }}
												{...register("frequence")}
												value={FrequenceType.YEAR}
											/>
											<label
												htmlFor="annuel"
												className="frequence-label"
												style={{ color: "#EB5757" }}
											>
												Annuel
											</label>
										</div>
										<div className="form-group d-flex gap-1">
											<input
												type="radio"
												id="mensuel"
												value={FrequenceType.MONTH}
												className="form-check-input fas__check"
												style={{ width: 16, height: 16 }}
												{...register("frequence")}
											/>
											<label
												htmlFor="mensuel"
												className="frequence-label"
												style={{ color: "#EB5757" }}
											>
												Mensuel
											</label>
										</div>
									</div>
								</div>
								<div className="input-group">
									<input
										className="form-control form-control-lg form-control-admin"
										type="text"
										id="prix"
										{...register("price")}
									/>
									<span className="input-group-text" id="basic-addon3">
										<IconEuroOrange />
									</span>
								</div>
							</div>
							<FormError error={errors?.price} />
						</div>
						<div className="col-md-6">
							<InputAdmin
								label="Détails supplémentaires"
								{...register("details")}
								type="text"
							/>
						</div>
						<div className="col-md-6">
							<label htmlFor="option-offre">Type d'abonnement</label>
							<select
								id="option-offre"
								className="form-select form-control-lg form-control-admin"
								{...register("option")}
							>
								<option value={OfferOptionType.BASIC}>Basic</option>
								<option value={OfferOptionType.FREEMIUM}>Freemium</option>
								<option value={OfferOptionType.ADVANCED}>Avancée</option>
							</select>
							<FormError error={errors?.option} />
						</div>
						<div className="col-md-6">
							<InputAdmin label="Position" {...register("position")} type="number" />
						</div>
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="reponse">Description des avantages</label>
								<ReactQuill
									className="editor-cgu"
									value={response}
									onChange={handleChange}
								/>
							</div>
							<FormError error={errors?.description} />
						</div>
						<div className="flex-end mt-5 gap-4">
							<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
						</div>
					</div>
				</form>
			</AdminCard>
		</div>
	);
};

export default AddAbonnement;
