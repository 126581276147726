import React from "react";
import { useStepperContext } from "../../utils/StepperContext";
import "./style/Stepper.css";

interface StepperPropsType {
	index: number;
	steps: string[];
	name?: string;
}

const StepperVertical: React.FC<StepperPropsType> = ({ index, steps, name: stepperName }) => {
	const { onClickStep } = useStepperContext();
	return (
		<div className="d-flex justify-content-end mb-4">
			<div className="stepper-vertical-wrapper d-flex flex-column gap-4">
				{steps.map((name, key) => (
					<div className="position-relative" key={key} onClick={() => onClickStep(key)}>
						<div className="flex-m-sb gap-2" style={{ cursor: "pointer" }}>
							<h6
								className="step-name flex-fill"
								style={{
									color: stepperName
										? index - 1 === key
											? "#c3a083"
											: index > key
											? "#BCB7B3"
											: "#ECEAE9"
										: index === key
										? "#c3a083"
										: index > key
										? "#BCB7B3"
										: "#ECEAE9",
								}}
							>
								{name}
							</h6>
							<div
								className="stepper-item"
								style={{
									backgroundColor: stepperName
										? index - 1 === key
											? "#c3a083"
											: index > key
											? "#BCB7B3"
											: "#ECEAE9"
										: index === key
										? "#c3a083"
										: index > key
										? "#BCB7B3"
										: "#ECEAE9",
								}}
							></div>
						</div>
						{key < steps.length - 1 && <div className="stepper__bar"></div>}
					</div>
				))}
			</div>
		</div>
	);
};

export default StepperVertical;
