const ListeAbonnesData = [
	{
		id: 1,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 2,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 3,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 4,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 5,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 6,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 7,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 8,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 9,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
	{
		id: 10,
		name: "Chareyre  Karlito",
		date_debut: "01/08/2022",
		date_fin: "24/08/2022",
		statut: "En cours",
	},
];

export default ListeAbonnesData;
