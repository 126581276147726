import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { ITaille } from "../../../../utils/api/taille/taille.type";
import { FormModalProps } from "../../../../utils/type";
import { ButtonSubmit } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import useCrudTaille from "../../admin/Parametres/useParametreForm/useCrudTaille";

const AddTailleModal = ({ item, isOpen, closeModal }: FormModalProps<ITaille>) => {
	const { register, onSubmit, errors, isLoading } = useCrudTaille(closeModal, item);
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-header">
				<h1 className="modal-title fs-5 fas-admin-modal-title" id="AddTailleModalLabel">
					{item ? "Modifier la" : "Ajouter une"} taille
				</h1>
				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={closeModal}
				></button>
			</div>
			<ModalBody>
				<form onSubmit={onSubmit}>
					<InputAdmin
						label=""
						placeholder="Taille"
						type="text"
						{...register("nom")}
						error={errors.nom}
						defaultValue={item?.nom}
					/>
					<div className="flex-c py-2">
						<ButtonSubmit label="ENREGISTRER" isLoading={isLoading} />
					</div>
				</form>
			</ModalBody>
		</Modal>
	);
};

export default AddTailleModal;
