import { useState } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetCollectionBySellerQuery } from "../../../../../utils/api/collection/collection.api";
import { calculatePageCount } from "../../../../../utils/utils";

function useCollection() {
	const userSlug = useAppSelector((s) => s?.user?.user?.slug);
	const [page, setPage] = useState<number>(1);
	const [search, setSearch] = useState<string>();
	const [produitsId, setProduitsId] = useState<number[]>([]);

	const perPage = 10;
	const { data, isLoading } = useGetCollectionBySellerQuery({
		slug: userSlug,
		limit: perPage,
		page,
		nom: search,
	});

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	const getProduitsId = (id: number) => {
		let collection = data?.results?.find((item) => item?.id === id);
		if (collection) {
			let produitsId = collection?.produits?.map((item) => item?.id);
			setProduitsId([...produitsId]);
		}
	};

	const handleFilterCollection = (e: React.FormEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
	};

	return {
		data,
		isLoading,
		collections: data?.results,
		setPage,
		handlePageClick,
		perPage,
		pageCount,
		isShowPagination: data?.count && data?.count > perPage,
		getProduitsId,
		produitsId,
		setSearch,
		handleFilterCollection,
		search,
	};
}

export default useCollection;
