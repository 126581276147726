import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";
import ListeAbonnesTable from "../../tables/admin/ListeAbonnes/ListeAbonnesTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import { AbonnementCard } from "./Abonnement";

const BreadRoutes = [
	{ name: "Abonnement", path: "/admin/abonnements" },
	{ name: "Abonnement 1", path: "/admin/abonnements/slug" },
];
export const item = {
	id: 1,
	title: "ESSENTIEL",
	preffered: false,
	description: "Idéal pour tout les e-shop qui enregistre régulièrement des ventes",
	price: "25",
	premium: false,
	offers: [
		"Offre gestionnaire de stock incluse",
		"Nombre de pièces illimitées",
		"Analyses des données",
		"Synchronisation sur 2 marketplaces",
		"1 utilisateur",
		"Assistance par e-mail",
	],
};

const DetailAbonnement = () => {
	return (
		<div className="abonnement__page">
			<h1>Abonnement</h1>
			<Breadcrumb routes={BreadRoutes} />
			<AdminCard marginTop={false}>
				{/* <AbonnementCard
					id={1}
					description={item.description}
					offers={item.offers}
					preffered={item.preffered}
					premium={item.premium}
					price={item.price}
					title={item.title}
				/> */}
				<div className="fashion__card px-xl-3 px-1 py-4 my-5">
					<h3 style={{ fontSize: "17px" }}>Liste des abonnés</h3>
					<ListeAbonnesTable />
				</div>
			</AdminCard>
		</div>
	);
};

export default DetailAbonnement;
