import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategory, Subcategory } from "../../utils/api/category/category.type";
import { IEtat } from "../../utils/api/etat/etat.type";
import { AppStorage } from "../../utils/storage";

export const CaracteristiquePepiteSlice = createSlice({
	name: "sectorSlice",
	initialState: {},
	reducers: {
		onSetCategory: (state, action: PayloadAction<ICategory[]>) => {
			state = action.payload;
			AppStorage.setItem("categories", state);
			return state;
		},
		onSetSubCategory: (state, action: PayloadAction<Subcategory[]>) => {
			state = action.payload;
			AppStorage.setItem("sousCategories", state);
			return state;
		},
		onSetEtatPepite: (state, action: PayloadAction<IEtat[]>) => {
			state = action.payload;
			AppStorage.setItem("etat", state);
			return state;
		},
	},
});

export const { onSetCategory, onSetSubCategory, onSetEtatPepite } =
	CaracteristiquePepiteSlice.actions;
