import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults,TypeQuery } from "../user/user.type";
import { FaqFormData, IFaq  } from "./faq.type";


export const FaqApi = createApi({
	reducerPath: "faqAPi",
	tagTypes: ["faq","faq_by_slug"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	
	endpoints: (builder) => ({
		getFaq: builder.query<PaginationResults<IFaq>, void>({
			query: () => `/faqs/`,
			providesTags: ["faq"],
		}),
		getFaqBySlug: builder.query<IFaq , any>({
			query: (slug) => `faqs/${slug}/`,
			providesTags: ["faq_by_slug"],
		}),
		addOrUpdateFaq: builder.mutation<IFaq,{slug?: string; data: FaqFormData | FormData }>({
			invalidatesTags: ["faq"],
			
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `faqs/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `faqs/`,
					method: "POST",
					body: data,
				};
			},
		}),
		editFaq: builder.mutation<IFaq,{slug?: string;data: FaqFormData | FormData | any;}>({
			invalidatesTags: ["faq"],
			query: ({ slug, data }) => {
					return {
						url: `faqs/${slug}/`,
						method: "PUT",
						body: data,
					};
			},
		}),
		deleteFaq: builder.mutation<IFaq, string>({
			query: (slug) => ({
				url: `faqs/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["faq"],
		}),
	}),
});

export const {
	useGetFaqQuery,
	useGetFaqBySlugQuery,
	useEditFaqMutation,
	useLazyGetFaqQuery,
	useAddOrUpdateFaqMutation,
	useDeleteFaqMutation,
} = FaqApi;
