import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { PaymentMode, PaymentModeFormData } from "./paymentMode.type";
import { prepareHeaders } from "../user/user.api";

const tags = {
	paymentModeByVisitor: "paymentModeByVisitor",
	paymentMode: "paymentMode",
};
export const PaymentModeApi = createApi({
	reducerPath: "paymentModeAPi",
	tagTypes: Object.values(tags),
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getPaymentModeForVisitor: builder.query<PaymentMode[], void>({
			query: () => `modepaiement/visitors/`,
			transformResponse: ({ results }) => results,
			providesTags: [tags.paymentModeByVisitor],
		}),
		getPaymentMode: builder.query<PaginationResults<PaymentMode>, TypeQuery>({
			query: (query) => QueryUrl("modepaiements/", query),
			providesTags: [tags.paymentMode],
		}),
		addOrUpdatePaymentMode: builder.mutation<PaymentMode, PaymentModeFormData>({
			invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
			query: ({ slug, ...data }) => {
				if (slug) {
					return {
						url: `modepaiements/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `modepaiements/`,
					method: "POST",
					body: data,
				};
			},
		}),
		addPaymentMode: builder.mutation<PaymentMode, PaymentModeFormData | any>({
			query: (data) => ({
				url: "modepaiements/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
		}),
		editPaymentMode: builder.mutation<PaymentMode, PaymentModeFormData | any>({
			query: ({ slug, ...data }) => ({
				url: `modepaiements/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
		}),
		deletePaymentMode: builder.mutation<PaymentMode, string>({
			query: (slug) => ({
				url: `modepaiements/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
		}),
	}),
});

export const {
	useGetPaymentModeForVisitorQuery,
	useGetPaymentModeQuery,
	useAddPaymentModeMutation,
	useEditPaymentModeMutation,
	useDeletePaymentModeMutation,
	useAddOrUpdatePaymentModeMutation,
} = PaymentModeApi;
