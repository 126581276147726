import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../utils/api/user/user.type";
import { AppStorage } from "../../utils/storage";

const initialState = AppStorage.getItem<AuthState>("user", {
	user: undefined,
	token: undefined,
	message: null,
});
export const UserSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		onSetUser: (state, action: PayloadAction<AuthState["user"]>) => {
			state.user = action.payload;
			AppStorage.setItem("user", state);
		},
		onSetToken: (state, action: PayloadAction<AuthState["token"]>) => {
			state.token = action.payload;
			AppStorage.setItem("token", state.token);
		},
		onSetMessage: (state, action: PayloadAction<AuthState["message"]>) => {
			state.message = action.payload;
			AppStorage.setItem("status_abonnement", state.message);
		},
		onSetUserToken: (state, action: PayloadAction<AuthState>) => {
			state.user = action.payload.user;
			state.token = action.payload.token;
			AppStorage.setItem("user", state);
		},
		onlogout: (state) => {
			state.user = null;
			state.token = null;
			AppStorage.setItem("user", state);
			AppStorage.clear();
			window.location.pathname = "/se-connecter";
		},
	},
});

export const { onSetUser, onSetUserToken, onSetToken, onlogout, onSetMessage } = UserSlice.actions;
export default UserSlice.reducer;
