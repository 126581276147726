import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	PaymentMode,
	PaymentModeFormData,
} from "../../../../../utils/api/paymentMode/paymentMode.type";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Color } from "../../../../../utils/theme";
import { useAddOrUpdatePaymentModeMutation } from "../../../../../utils/api/paymentMode/paymentMode.api";
import Swal from "sweetalert2";
import { cleannerError, onHide } from "../../../../../utils/utils";

function UseCrudModePayment(closeModal: () => void, item?: PaymentMode) {
	const validationSchema = yup.object().shape({
		titre: yup.string().required().label("Le nom"),
		nom: yup.string().required().label("Le type"),
		description: yup.string().nullable(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<PaymentModeFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [description, setDescription] = useState("");

	const [addOrUpdateItem, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdatePaymentModeMutation();

	const handleChangeDescription = useCallback((value: string) => {
		setDescription(value);
		setValue("description", value);
	}, []);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item
					? "Mode de paiement modifié avec succès !"
					: "Mode de paiement ajouté avec succès",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				setDescription("");
				closeModal();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur de statut ${err?.status} est survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: PaymentModeFormData) => {
		if (item?.slug) {
			data["slug"] = item?.slug;
		}
		console.log("data", data);
		await addOrUpdateItem(data);
	};

	useEffect(() => {
		const fields: (keyof PaymentModeFormData)[] = ["nom", "description", "titre"];
		if (item?.id) {
			for (let field of fields) {
				setValue(field, item[field]);
				if (field === "description") {
					setDescription(item[field] || "");
				}
			}
		} else {
			for (let field of fields) {
				setValue(field, "");
			}
		}
	}, [item]);

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		description,
		handleChangeDescription,
	};
}

export default UseCrudModePayment;
