import React, { useEffect, useState } from "react";
// import { Pagination } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from "../../../../common/AdminPagination";
import StockPepitesData from "./StockPepitesData";
import StockPepitesTableSkeleton from "./StockPepitesTableSkeleton";
import { logoPlatformes } from "../../../user/AddPepite/Steps/Diffusion";

function StockPepitesTable() {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(5);
	const [isLoading, setIsLoading] = useState(true);

	const plateformFormatter: any = (cell: any, row: any) => {
		return (
			<div className="flex-m gap-2">
				{logoPlatformes?.map((item, index) => (
					<div className="img__container">
						<img src={item?.logo} alt={item?.name} className="logo-brodcast" />
						{index < logoPlatformes?.length - 1 && <span className="ms-2">_</span>}
					</div>
				))}
			</div>
		);
	};
	const nameFormatter: any = (cell: any, row: any) => {
		return (
			<div className="flex-m gap-2">
				<div className="img-container">
					<img
						src="https://picsum.photos/200/300"
						alt={cell}
						style={{ height: 26, width: 26 }}
					/>
				</div>
				<span>{cell}</span>
			</div>
		);
	};

	const columns = [
		{
			dataField: "name",
			text: "Nom",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
		},
		{
			dataField: "id",
			text: "Diffusé",
			formatter: (cell: any, row: any) => plateformFormatter(cell, row),
		},
		{
			dataField: "statut",
			text: "Statut",
		},
	];

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 0);
	}, []);

	return (
		<>
			{isLoading && <StockPepitesTableSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={StockPepitesData}
						columns={columns}
						bordered={false}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table"
					/>
					<CustomPagination
						nbPages={StockPepitesData.length}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
		</>
	);
}

export default StockPepitesTable;

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-end">
			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
