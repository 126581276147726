import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { IBoutique } from "../../../../utils/api/boutique/boutique.type";
import { IUser } from "../../../../utils/api/user/user.type";
import { BtnPrimary } from "../../../common/Button";
import { Input, InputIcon } from "../../../common/Input";
import Popup from "../../../common/Popup";
import DeleteAccountModal from "../../modals/user/DeleteAccountModal";
import LogoutModal from "../../modals/user/LogoutModal";
import { AssideButtons, GestionAbonnement } from "./Profil";
import "./Profil.css";
import UseEditInfoBoutique from "./useProfilForm/UseEditInfoBoutique";
import useEditInfoPersonelles from "./useProfilForm/UseEditInfoPersonnelles";

const EditProfil = () => {
	return (
		<div className="profil__page">
			<h1>Mon profil</h1>
			<div className="row">
				<div className="col-md-3 view-md d-lg-none">
					<Popup />
				</div>
				<div className="col-md-9 col-lg-12">
					<div className="mt-5 row  d-md-flex flex-row-reverse">
						<div className="col-md-3 col-xl-2 ">
							<AssideButtons path="/user/dashboard" label="Revenir au dashboard" />
						</div>
						<div className="px-xl-3 py-3 col-xl-10 col-md-9">
							<EditProfilForm />
							<GestionAbonnement />
							<div>
								<h3>Supprimer mon compte ?</h3>
								<p className="common_style color-red my-3">
									Voulez-vous vraiment supprimer votre compte ?
								</p>
								<p className="common_style color-dark ">
									En supprimant votre compte, toutes vos pépites en cours de
									diffusion seront automatiquement enlevées des marketplaces ainsi
									que toutes celles se trouvant sur votre compte TFT Solution.
								</p>
								<div className="flex-c mt-5">
									<button
										type="button"
										className="fashion__btn btn__danger w-50"
										style={{ borderRadius: "4px" }}
										data-bs-toggle="modal"
										data-bs-target="#DeleteAccountModal"
									>
										Supprimer
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DeleteAccountModal />
			<LogoutModal />
		</div>
	);
};

function EditProfilForm() {
	const { user } = useAppSelector((s) => s.user);
	return (
		<div className="fashion__card py-4 px-2 px-xl-3">
			<div className="flex-m-sb mb-3">
				<h3>Mes informations</h3>
				<Link to="/user/profil" className="common_style color-red" type="button">
					Annuler
				</Link>
			</div>
			<InfoPersonnellesForm user={user} />
			<InfoBoutiquesForm boutique={user?.boutique[0]} />
		</div>
	);
}

function InfoPersonnellesForm({ user }: { user: IUser }) {
	const { register, onSubmit, errors, isLoading } = useEditInfoPersonelles(user);
	return (
		<form onSubmit={onSubmit}>
			<div className="info_user_block  border-bottom  mb-4">
				<h4>Informations personnelles</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Nom"
							defaultValue={user?.nom}
							type="text"
							id="nom"
							{...register("nom")}
							error={errors?.nom}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Prénom"
							defaultValue={user?.prenom}
							type="text"
							id="prenom"
							{...register("prenom")}
							error={errors?.prenom}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Téléphone"
							defaultValue={user?.telephone}
							type="tel"
							id="tel"
							{...register("telephone")}
							error={errors?.telephone}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Adresse mail"
							value={user?.email}
							type="email"
							id="email"
							disabled
						/>
					</div>
				</div>
				<div className="info_user_block">
					<h4>Identifiants de ma boutique Shopify</h4>
					<div className="row">
						<div className="group-info col-md-6 mb-3">
							<Input
								label="Nom de boutique Shopify"
								defaultValue={user?.shopify_store}
								type="text"
								id="shopify_store"
								{...register("shopify_store")}
								error={errors?.shopify_store}
								autoComplete="off"
							/>
						</div>
						<div className="group-info col-md-6 mb-3">
							<InputIcon
								label="Clé Shopify"
								defaultValue={user?.shopify_token}
								id="shopify_token"
								{...register("shopify_token")}
								error={errors?.shopify_token}
								autoComplete="off"
							/>
						</div>
					</div>
				</div>
				<div className="flex-c py-3">
					<BtnPrimary
						label="enregistrer les informations"
						width="w-50"
						isLoading={isLoading}
						mode="Secondary"
					/>
				</div>
			</div>
		</form>
	);
}

function InfoBoutiquesForm({ boutique }: { boutique?: IBoutique }) {
	const { register, onSubmit, errors, isLoading } = UseEditInfoBoutique(boutique);
	return (
		<form onSubmit={onSubmit}>
			<div className="info_user_block  border-bottom mb-4">
				<h4>Informations de ma boutique</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Nom"
							defaultValue={boutique?.nom_boutique}
							type="text"
							id="nom_boutique"
							{...register("nom_boutique")}
							error={errors?.nom_boutique}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Numéro de SIREN"
							defaultValue={boutique?.numero_siret}
							type="text"
							id="siret"
							{...register("numero_siret")}
							error={errors?.numero_siret}
						/>
					</div>
				</div>
			</div>
			<div className="info_user_block">
				<h4>Adresse de facturation</h4>
				<div className="row">
					<div className="group-info col-12 mb-3">
						<Input
							label="Adresse"
							defaultValue={boutique?.adresse}
							type="text"
							id="adresse"
							{...register("adresse")}
							error={errors?.adresse}
						/>
					</div>
				</div>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Ville"
							defaultValue={boutique?.ville}
							type="text"
							id="ville"
							{...register("ville")}
							error={errors?.ville}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Code postal"
							defaultValue={boutique?.code_postal}
							type="text"
							id="code_postal"
							{...register("code_postal")}
							error={errors?.code_postal}
						/>
					</div>
				</div>
				<div className="flex-c py-3">
					<BtnPrimary
						label="enregistrer les informations"
						width="w-50"
						isLoading={isLoading}
						mode="Secondary"
					/>
				</div>
			</div>
		</form>
	);
}

function ShopifyStoreInfo({ user }: { user: IUser }) {
	const { register, onSubmit, errors, isLoading } = useEditInfoPersonelles(user);
	return (
		<form onSubmit={onSubmit}>
			<div className="info_user_block  border-bottom mb-4 mt-5">
				<h4>Identifiants de ma boutique Shopify</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Nom de boutique Shopify"
							defaultValue={user?.shopify_store}
							type="text"
							id="shopify_store"
							{...register("shopify_store")}
							error={errors?.shopify_store}
						/>
					</div>
					<div className="group-info col-md-6 mb-3">
						<Input
							label="Clé Shopify"
							defaultValue={user?.shopify_token}
							type="text"
							id="shopify_token"
							{...register("shopify_token")}
							error={errors?.shopify_token}
						/>
					</div>
				</div>
			</div>
		</form>
	);
}
export default EditProfil;
