import React from "react";
import { IStepsSignupProps } from "../../../../../utils/api/auth/auth.type";
import { BtnPrimary } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";

const AdresseFacturation: React.FC<IStepsSignupProps> = ({ register, onSubmit, errors }) => {
	return (
		<React.Fragment>
			<h2>Adresse de facturation</h2>
			<form action="" className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="row">
					<div className="col-12">
						<Input
							label="Adresse*"
							type="text"
							id="adresse"
							{...register("adresse")}
							error={errors?.adresse}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Code postal*"
							type="text"
							id="code_postal"
							{...register("code_postal")}
							error={errors?.code_postal}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Ville*"
							type="text"
							id="ville"
							{...register("ville")}
							error={errors?.ville}
						/>
					</div>
				</div>
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</React.Fragment>
	);
};

export default AdresseFacturation;
