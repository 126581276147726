import React, {useState, useEffect} from "react";
import ReactQuill from "react-quill";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import useFaqForm from "../../admin/Faq/useForm/useFaqForm";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import { FormModalProps } from "../../../../utils/type";

type AddFaqModalProps = {
	item?: IFaq;
  };


const AddEditFaqModal = ({
	item,
}: AddFaqModalProps) => {
	const { register, onSubmit, errors,onChangeDescription, isLoading, reset  } = useFaqForm(item);

	const [response, setResponse] = useState<any>();
	const handleChange = (value: any) => {
		setResponse(value);
		onChangeDescription(value)
	};

	useEffect(() =>{
		setResponse(item?.description?.replace(/(<([^>]+)>)/ig, ''));
	},[item])


	
	return (
		<div
			className="modal fade"
			id="AddEditFaqModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="AddEditFaqModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<div className="flex-sb">
							<h4 className="fas-modal-title mb-4">Questions/Reponses</h4>
							<CloseModalButton />
						</div>

						<form onSubmit={onSubmit} >
							<InputAdmin
								label="Questions"
								id="titre"
								type="text"
								placeholder="Ajouter une question"
								{...register("titre")}
								error={errors?.titre}
							/>
							<div className="form-group">
								<label htmlFor="reponse">Reponse</label>
								<ReactQuill
									className="editor-cgu"
									value={response}
									onChange={handleChange}
								/>
							</div>
							<div className="flex-end mt-5 gap-4">
								<CloseModalButton text="Fermer" />
								<ButtonSubmit label="Enregistrer" isLoading={isLoading}/>

							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddEditFaqModal;
