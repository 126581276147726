import React from "react";
import { IAdmin } from "../../../../utils/api/admin/admin.type";
import { Authorisations } from "../../../../utils/constants";
import { Color } from "../../../../utils/theme";
import { getAvatar, getName, useLocationState } from "../../../../utils/utils";

const DetailProfilAdmin = () => {
	const admin = useLocationState<IAdmin>(undefined);
	return (
		<div className="detail-profil-admin-page">
			<h1>Profil</h1>
			<div className="fashion__card px-xl-3 px-1 py-3 mb-4 mt-4">
				<div className="profil_content">
					<div className="img-container flex-c">
						<img
							src={getAvatar(admin?.avatar)}
							alt="admin-avatar"
							style={{ width: "150px", height: "150px", borderRadius: "30px" }}
						/>
					</div>
					<h3 className="admin-user-name mt-3">{getName(admin)}</h3>
				</div>
			</div>
			<div className="fashion__card px-xl-3 px-1 py-3 mb-4 mt-4">
				<h3 className="personal-info-title mb-4">Informations personnelles</h3>
				<div className="row border-b mb-3">
					<div className="col-md-6">
						<h6 className="admin-info-label fw-400">Nom</h6>
					</div>
					<div className="col-md-6">
						<h6 className="admin-info-value fw-700">{getName(admin)}</h6>
					</div>
				</div>
				<div className="row border-b mb-3">
					<div className="col-md-6">
						<h6 className="admin-info-label fw-400">Mail</h6>
					</div>
					<div className="col-md-6">
						<h6 className="admin-info-value fw-700">{admin?.email}</h6>
					</div>
				</div>
				<div className="row border-b mb-3">
					<div className="col-md-6">
						<h6 className="admin-info-label fw-400">Téléphone</h6>
					</div>
					<div className="col-md-6">
						<h6 className="admin-info-value fw-700">{admin?.telephone}</h6>
					</div>
				</div>
				<div className="row border-b mb-3">
					<div className="col-md-6">
						<h6 className="admin-info-label fw-400">Adresse</h6>
					</div>
					<div className="col-md-6">
						<h6 className="admin-info-value fw-700">{admin?.adresse}</h6>
					</div>
				</div>
			</div>
			<div className="fashion__card px-xl-3 px-1 py-4">
				<h3 className="personal-info-title mb-4">Accès</h3>
				<div className="mt-2 d-flex gap-3 flex-wrap">
					{Authorisations?.map((item, i) => (
						<div key={i}>
							<div
								className="container-access"
								style={{
									borderColor: admin[item?.field] === true ? Color.primary : "",
								}}
							>
								<input
									type="checkbox"
									className="form-check-input fas__check"
									id={`checkbox${i}`}
									defaultChecked={admin[item?.field]}
								/>
								<label
									className="custom-check-form-label mb-0"
									htmlFor={`checkbox${i}`}
								>
									{item?.label}
								</label>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default DetailProfilAdmin;
