import React, { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { IAbonnement } from "../../../../utils/api/abonnement/abonnement.type";
import { useCancelSubscriptionMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";

const DisabledAccount = ({ item }: { item: IAbonnement }) => {
	const [cancelSubscription, { isSuccess, isError, error }] = useCancelSubscriptionMutation();

	const onCancelSubscription = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir arrêter votre abonnement`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return cancelSubscription(item?.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Abonnement arrêté avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);
	return (
		<button
			type="button"
			className="fashion__btn btn__primary btn-disable-account"
			style={{
				borderRadius: "4px",
			}}
			onClick={onCancelSubscription}
		>
			Arrêter mon abonnement
		</button>
	);
};

export default DisabledAccount;
