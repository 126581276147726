import React from "react";
import { IStepsSignupProps } from "../../../../../utils/api/auth/auth.type";
import { OfferOptionType } from "../../../../../utils/api/offer/offer.type";
import { Color } from "../../../../../utils/theme";
import { Spinner } from "../../../../common/Button";

const Finalisation: React.FC<IStepsSignupProps> = ({ onSubmit, isLoading, offer }) => {
	return (
		<React.Fragment>
			<h2>Finaliser mon inscription</h2>
			<h4 className="success__message mt-3 mb-1">
				{offer?.option === OfferOptionType.FREEMIUM
					? "Vous avez terminé de remplir tous les champs, pour valider votre inscription cliquez sur le bouton finaliser mon inscription."
					: "Vous avez terminé de remplir tous les champs. Afin de finaliser votre inscription,vous allez être redirigé vers la page de paiement."}
				{/* Vous avez terminé de remplir tous les champs. Afin de finaliser votre inscription,
				vous allez être redirigé vers la page de paiement. */}
			</h4>
			<form className="pt-4" onSubmit={onSubmit}>
				<div className="btn-container flex-c mt-5">
					<button
						type="submit"
						className="fashion__btn btn__primary"
						style={{ color: Color.dark, backgroundColor: Color.plight }}
						disabled={isLoading}
					>
						{isLoading ? <Spinner mode={Color.dark} /> : "FINALISER MON INSCRIPTION"}
					</button>
				</div>
			</form>
		</React.Fragment>
	);
};

export default Finalisation;
