import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { ButtonAdd } from "../../../common/Button";
import { InputFilter } from "../../../common/Input";
import SellersTable from "../../tables/admin/SellersTable/SellersTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";

const Sellers = () => {
	const [search, setSearch] = useState<string>("");
	return (
		<div className="sellers__page">
			<h1>Vendeurs</h1>
			<AdminCard>
				<div className="d-flex justify-content-between my-4">
					<div className="filter-container">
						<label htmlFor="" className="me-2">
							Filtrer
						</label>
						<InputFilter
							label="Nom du vendeur"
							onChange={(e) => setSearch(e.currentTarget.value)}
						/>
					</div>
					<div className="d-flex gap-3">
						{/* <Link
							to="/admin/corbeille"
							className="admin-btn-add"
							style={{ background: Color.secondary, color: Color.dark }}
						>
							<FaTrash />
							<span className="ms-2">Corbeille</span>
						</Link> */}
						<ButtonAdd
							path="/admin/vendeurs/ajouter-un-vendeur"
							label="Ajouter un vendeur"
						/>
					</div>
				</div>
				<SellersTable word={search} />
			</AdminCard>
		</div>
	);
};

export default Sellers;
