import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { AppStorage } from "../../utils/storage";
import { Color } from "../../utils/theme";

const Style = {
	backgroundColor: Color.red,
	padding: "10px 3rem",
	position: "fixed",
	top: "0",
	left: "0",
	width: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	zIndex: 1000,
	margin: "auto",
} as React.CSSProperties;

type Props = {};

const NotifyBanner: React.FC<Props> = () => {
	const [statusAbonnement, setStatusAbonnement] = useState<string>();
	const message = AppStorage.getItem("status_abonnement") as string;
	useEffect(() => {
		if (message) {
			setStatusAbonnement(message);
		}
	}, [message]);

	const hideBanner = () => {
		AppStorage.removeItem("status_abonnement");
		setStatusAbonnement("");
	};

	if (statusAbonnement) {
		return (
			<div style={Style}>
				<div className="d-flex align-items-center justify-content-center flex-fill gap-3">
					<h1
						className="m-0"
						style={{ fontSize: "clamp(20px, 3vw, 28px)", color: Color.white }}
					>
						{statusAbonnement}
					</h1>
					<Link
						to="/toutes-les-offres"
						style={{
							color: Color.white,
							textDecoration: "underline",
							fontWeight: "600",
						}}
					>
						Je renouvelle mon abonnement
					</Link>
				</div>
				<button onClick={hideBanner}>
					<MdClose style={{ fontSize: "20px", color: Color.white }} />
				</button>
			</div>
		);
	}
	return null;
};

export default NotifyBanner;
