import { IAdmin } from "../admin/admin.type";
import { IBoutique } from "../boutique/boutique.type";
import { IOffer } from "../offer/offer.type";

export enum UserType {
	admin = "admin",
	superAdmin = "superadmin",
	vendeur = "vendeur",
}

export type CiviliteType = "homme" | "femme";
export interface IUser {
	id: number;
	slug: string | any;
	email: string;
	nom: string;
	prenom: string;
	password: string;
	date_naissance: string;
	user_type: UserType;
	civilite: CiviliteType;
	telephone: string;
	avatar: string;
	adresse: string;
	latitude: string;
	longitude: string;
	voyageur_proprietaire: boolean;
	is_voyageur_proprio: boolean;
	statut_proprietaire: "en_cours" | "valider";
	password_reset_count: number;
	first_connexion: boolean;
	created_at: string;
	last_login: string;
	is_active: boolean;
	pourcentage: number;
	is_archive: boolean;
	boutique: IBoutique[];
	abonnement: IOffer;
	shopify_store: string;
	shopify_token: string;
	offre: IOffer;
	deletion_id: string;
}
export interface AuthState {
	user: IUser | IAdmin | any;
	token?: string | null;
	message?: string | null;
}

export type UserFormData = {
	slug: string | any;
	nom: string;
	prenom: string;
	telephone: string;
	shopify_store: string | null;
	shopify_token: string | null;
};
export type DeleteUserAccountData = {
	password: string;
};
export type PaginationResults<T> = {
	count?: number;
	next?: string | null;
	previous?: string | null;
	results: T[];
};

export type GraphUserType = {
	month: string;
	sales_amount: number;
	sales_count: number;
};

export type UserDashboardDataType = {
	pepites_online_count: number;
	pepites_stock_count: number;
	revenu_total: number;
	sales_by_year_count: number;
	sales_count: number;
	graph: GraphUserType[];
};

export type TypeQuery = Partial<{
	page?: number;
	limit?: number;
	word?: string;
	slug?: string;
}>;

export type TypeDashboardUserQuery = Partial<{
	slug?: string;
	marketplace: string | null;
	year: string | number;
}>;
