import React from "react";
import { IStepsSignupProps } from "../../../../../utils/api/auth/auth.type";
import { BtnPrimary } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";

const InfosBoutiques: React.FC<IStepsSignupProps> = ({ register, onSubmit, errors }) => {
	return (
		<React.Fragment>
			<h2>Informations de ma boutique</h2>
			<form action="" className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="row">
					<div className="col-md-6">
						<Input
							label="Nom*"
							type="text"
							id="nom_boutique"
							{...register("nom_boutique")}
							error={errors?.nom_boutique}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Numéro de SIREN (9 chiffres) *"
							type="number"
							id="numero_siret"
							{...register("numero_siret")}
							error={errors?.numero_siret}
						/>
					</div>
				</div>
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</React.Fragment>
	);
};

export default InfosBoutiques;
