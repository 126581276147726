import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";
import StockPepitesTable from "../../tables/admin/StockPepites/StockPepitesTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import { Link } from "react-router-dom";
import { IVendeur } from "../../../../utils/api/vendeur/vendeur.type";
import { useParams } from "react-router-dom";
import {
	useGetVendeurBySlugQuery,
} from "../../../../utils/api/vendeur/vendeur.api";
import { GestionAbonnement } from "../../user/Profil/Profil";





const DetailSeller = () => {
	let { slug } = useParams();

	const { data } = useGetVendeurBySlugQuery(slug);
	console.log({ data });

	let nom = data?.prenom + " " + data?.nom;

	const BreadRoutes = [
		{ name: "Vendeurs", path: "/admin/vendeurs" },
		{ name: nom, path: `/admin/vendeurs/${slug}` },
	];

	return (
		<div>
			<h1>Vendeurs </h1>
			<Breadcrumb routes={BreadRoutes} />
			<AdminCard marginTop={false}>
				<div className="profil__page">
					<div className="fashion__card py-4 px-2 px-xl-3">
						<InfosPersonnelles vendeur={data} />
					</div>
					<GestionAbonnement admin />
				</div>
				<div className="fashion__card py-4 px-2 px-xl-3">
					<h4 className="total-stock">
						<span className="fw-500">Stocks</span>
						<span style={{ fontWeight: 200, fontSize: 14 }}>(200 produits)</span>
					</h4>
					<StockPepitesTable />
				</div>
			</AdminCard>
		</div>
	);
};

export function InfosPersonnelles({ vendeur }: { vendeur?: IVendeur | any }) {
	return (
		<div className="infos-personnelles">
			<div className="info_user_block  border-bottom  mb-4">
				<h4>Informations personnelles</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Nom</h5>
						<p className="user_info_value">{vendeur?.nom}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Prénom</h5>
						<p className="user_info_value">{vendeur?.prenom}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Téléphone</h5>
						<p className="user_info_value">{vendeur?.telephone}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Adresse mail</h5>
						<p className="user_info_value">{vendeur?.email}</p>
					</div>
				</div>
			</div>
			<div className="info_user_block  border-bottom mb-4">
				<h4>Informations de la boutique</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Nom</h5>
						<p className="user_info_value">{vendeur?.boutique[0]?.nom_boutique}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Numéro de siren</h5>
						<p className="user_info_value">{vendeur?.boutique[0]?.numero_siret}</p>
					</div>
				</div>
			</div>
			<div className="info_user_block">
				<h4>Adresse de facturation</h4>
				<div className="row">
					<div className="group-info col-12 mb-3">
						<h5 className="user_info_label">Adresse</h5>
						<p className="user_info_value">{vendeur?.boutique[0]?.adresse}</p>
					</div>
				</div>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Code postal</h5>
						<p className="user_info_value">{vendeur?.boutique[0]?.code_postal}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Ville</h5>
						<p className="user_info_value">{vendeur?.boutique[0]?.ville}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailSeller;
