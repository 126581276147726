import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";
import { AbonnementFormData } from "./abonnement.type";

export const AbonnementApi = createApi({
	tagTypes: ["abonnement"],
	reducerPath: "abonnementApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		renewAbonnement: builder.mutation<{ url: string }, AbonnementFormData>({
			invalidatesTags: ["abonnement"],
			query: (data) => {
				return {
					url: `abonnements/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const { useRenewAbonnementMutation } = AbonnementApi;
