import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { FieldError, UseFormSetValue } from "react-hook-form";
import { PepiteFormData } from "../../utils/api/pepite/pepite.type";
import { FormError } from "./Input";

type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	error?: string | FieldError;
	setValue: UseFormSetValue<PepiteFormData>;
	fieldName: string;
	values?: string[];
};
export const DynamicInput = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { label, id, required, error, setValue, fieldName, values, ...rest } = props;
	const [items, setItems] = useState<string[]>([]);
	const currentRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (values) {
			setItems(values);
		}
	}, [values]);

	const handleAddItem = () => {
		if (currentRef.current?.value) {
			const newArrayOfItems = [...items, currentRef.current?.value];
			setItems(newArrayOfItems);
			currentRef.current.value = "";
			setValue(fieldName, newArrayOfItems);
		}
	};
	const handleRemoveItem = (item: string) => {
		const newArrayOfItems = items?.filter((elem) => elem !== item);
		setItems([...newArrayOfItems]);
		setValue(fieldName, newArrayOfItems);
	};
	return (
		<div className="mb-3">
			<div>
				<label htmlFor={id} className="form-dynamic-label">
					{label}
				</label>
				<div className="input-group">
					<input
						className="form-control form-control-lg form-custom-control form-dynamic-input"
						placeholder={label}
						id={id}
						{...rest}
						ref={currentRef}
					/>
					<button className="input-group-text" type="button" onClick={handleAddItem}>
						Ajouter
					</button>
				</div>
			</div>
			{!!items.length && (
				<div className="d-flex gap-2 flex-wrap mt-2">
					{items?.map((item, index) => (
						<div key={index} style={{ border: "1px solid #cccac8" }}>
							<span
								style={{
									backgroundColor: "#cccac8",
									height: "100%",
									display: "inline-block",
								}}
								className="px-2"
								role="button"
								onClick={() => handleRemoveItem(item)}
							>
								X
							</span>
							<span className="px-2">{item}</span>
						</div>
					))}
				</div>
			)}
			{<FormError error={error} />}
		</div>
	);
});
