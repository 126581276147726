import React from "react";
import { IconSearch } from "../shared/Icons";
type PanelFilterProps = {
	filterPepiteByName: (e: React.FormEvent<HTMLInputElement>) => void;
	filterPepiteByStatut: (e: React.FormEvent<HTMLInputElement>) => void;
	isShowFilterStatus?: boolean;
};
const PanelFilter = ({
	filterPepiteByName,
	filterPepiteByStatut,
	isShowFilterStatus,
}: Partial<PanelFilterProps>) => {
	return (
		<div className="panel-filter py-4 row mt-4 bg__slate">
			<div className="col-md-6 mb-3 mb-md-0">
				<label htmlFor="search_pepite" className="fas__label__imp">
					Rechercher une pépite
				</label>
				<div className="search-input-container position-relative mt-2">
					<span style={{ position: "absolute", top: "8px", left: "4px" }}>
						<IconSearch />
					</span>
					<input
						type="search"
						id="search_pepite"
						className="form-control fas__control"
						placeholder="Écrivez le nom de la pépite"
						onChange={filterPepiteByName}
					/>
				</div>
			</div>
			{isShowFilterStatus && (
				<div className="col-md-6 d-flex flex-column justify-content-between">
					<label htmlFor="filter" className="fas__label__imp">
						Filtrer mes pépites :
					</label>
					<div className="flex-m mt-2 gap-4">
						<div className="flex-m gap-2">
							<input
								type="radio"
								name="statut"
								className="form-check-input fas__check"
								id="online"
								onChange={filterPepiteByStatut}
								value="on"
							/>
							<label htmlFor="online" className="fas__label">
								En ligne
							</label>
						</div>
						<div className="flex-m gap-2">
							<input
								type="radio"
								name="statut"
								className="form-check-input fas__check"
								id="ebauche"
								onChange={filterPepiteByStatut}
								value="off"
							/>
							<label htmlFor="ebauche" className="fas__label">
								Ébauche
							</label>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PanelFilter;
