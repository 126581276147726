import useCategoryForm from "../../admin/Categories/useForm/useCategoryForm";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import { ICategory } from "../../../../utils/api/category/category.type";
import { Modal } from "reactstrap";
import { FormModalProps } from "../../../../utils/type";

const AddCategoryModal = ({ item, isOpen, closeModal }: FormModalProps<ICategory>) => {
	const { register, onSubmit, errors, isLoading } = useCategoryForm(closeModal, item);
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-header">
				<h5 className="modal-title modal-title-custom">
					{item ? "Modifier" : "Ajouter"} une catégorie
				</h5>
				<CloseModalButton onClick={closeModal} />
			</div>
			<div className="modal-body">
				<form onSubmit={onSubmit}>
					<div className="col-12">
						<InputAdmin
							label="nom"
							id="nom"
							type="text"
							placeholder="Nom catégorie"
							{...register("nom")}
							error={errors?.nom}
						/>
					</div>
					<div className="flex-end">
						<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default AddCategoryModal;
