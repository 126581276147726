import { useEffect } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import { OfferOptionType } from "../../../../utils/api/offer/offer.type";
import { IUser } from "../../../../utils/api/user/user.type";
import { Color } from "../../../../utils/theme";
import { AlertInfo } from "../../../common/Alert";
import Popup from "../../../common/Popup";
import { AbonnementCard } from "../../admin/Abonnement/Abonnement";
import DeleteAccountModal from "../../modals/user/DeleteAccountModal";
import LogoutModal from "../../modals/user/LogoutModal";
import DisabledAccount from "./DisabledAccount";
import "./Profil.css";

const Profil = () => {
	const { user } = useAppSelector((s) => s.user);
	const navigate = useNavigate();
	const { search } = useLocation();
	useEffect(() => {
		if (search?.includes("status=success&slug") || search?.includes("success_freemium")) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				showConfirmButton: false,
				title: "Votre Abonnement a été renouvelé avec succès!",
				timer: 2000,
			}).then(() => {
				navigate("/user/profil");
			});
		}
	}, [search]);
	return (
		<div className="profil__page">
			<h1>Mon profil</h1>
			<div className="row">
				<div className="col-md-3 view-md d-lg-none">
					<Popup />
				</div>
				<div className="col-md-9 col-lg-12">
					<div className="mt-5 row  d-md-flex flex-row-reverse">
						<div className="col-md-3 col-xl-2 ">
							<AssideButtons path="/user/dashboard" label="Revenir au dashboard" />
						</div>
						<div className="px-xl-3 py-3 col-xl-10 col-md-9">
							<div className="fashion__card py-4 px-2 px-xl-3">
								<div className="flex-m-sb mb-3">
									<h3>Mes informations</h3>
									<Link to={"/user/modifier-profil"} className="btn__show">
										Modifier mes informations
									</Link>
								</div>
								<InfosPersonnelles user={user} />
							</div>
							<GestionAbonnement user={user} />
							<div className="">
								<p className="common_style color-red my-3">
									Supprimer mon compte ?
								</p>
								<p className="common_style color-dark ">
									En supprimant votre compte, toutes vos pépites en cours de
									diffusion seront automatiquement enlevées des marketplaces ainsi
									que toutes celles se trouvant sur votre compte TFT Solution.
								</p>
								<div className="flex-c mt-5">
									<button
										type="button"
										className="fashion__btn  btn__danger w-50"
										style={{ borderRadius: "4px" }}
										data-bs-toggle="modal"
										data-bs-target="#DeleteAccountModal"
									>
										Supprimer mon compte
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DeleteAccountModal />
			<LogoutModal />
		</div>
	);
};

export function InfosPersonnelles({ user }: { user?: IUser | any }) {
	return (
		<div className="infos-personnelles">
			<div className="info_user_block  border-bottom  mb-4">
				<h4>Informations personnelles</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Nom</h5>
						<p className="user_info_value">{user?.nom}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Prénom</h5>
						<p className="user_info_value">{user?.prenom}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Téléphone</h5>
						<p className="user_info_value">{user?.telephone}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Adresse mail</h5>
						<p className="user_info_value">{user?.email}</p>
					</div>
				</div>
			</div>
			<div className="info_user_block  border-bottom mb-4">
				<h4>Informations de ma boutique</h4>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Nom</h5>
						<p className="user_info_value">{user?.boutique[0]?.nom_boutique}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Numéro de siren</h5>
						<p className="user_info_value">{user?.boutique[0]?.numero_siret}</p>
					</div>
				</div>
			</div>
			<div className="info_user_block">
				<h4>Adresse de facturation</h4>
				<div className="row">
					<div className="group-info col-12 mb-3">
						<h5 className="user_info_label">Adresse</h5>
						<p className="user_info_value">{user?.boutique[0]?.adresse}</p>
					</div>
				</div>
				<div className="row">
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Code postal</h5>
						<p className="user_info_value">{user?.boutique[0]?.code_postal}</p>
					</div>
					<div className="group-info col-md-6 mb-3">
						<h5 className="user_info_label">Ville</h5>
						<p className="user_info_value">{user?.boutique[0]?.ville}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export function GestionAbonnement({ user, admin }: { user?: IUser | any; admin?: boolean }) {
	const isShowBtn =
		user?.abonnement?.cancel || user?.abonnement?.offre?.option === OfferOptionType.FREEMIUM;
	return (
		<div className="fashion__card my-5  px-2 py-3">
			<div className="d-flex flex-column flex-xl-row align-items-lg-center justify-content-lg-between gap-3 mb-4">
				<h3 className="text-center space-nowrap">{!admin ? "Mon a" : "A"}bonnement</h3>
				{!admin && (
					<div className="d-flex flex-column gap-3 flex-lg-row">
						{!isShowBtn && <DisabledAccount item={user?.abonnement} />}
						<Link
							to="/toutes-les-offres"
							className="btn__add fashion__btn d-block text-center"
						>
							Modifier mon abonnement
						</Link>
					</div>
				)}
			</div>
			{user?.abonnement ? (
				<AbonnementCard item={user?.abonnement?.offre} />
			) : (
				<AlertInfo message="Aucun abonnement trouvé" />
			)}
		</div>
	);
}

export function AssideButtons({ path, label }: { path: string; label: string }) {
	return (
		<div className="d-flex flex-md-column justify-content-md-start gap-md-5 align-items-center justify-content-between">
			<Link
				to={path}
				className="common_style"
				style={{ color: " #8C847D", whiteSpace: "nowrap" }}
			>
				<FiArrowLeft />
				<span className="ms-2">{label}</span>
			</Link>
			<button
				className="logout__btn common_style px-2 py-1"
				data-bs-target="#LogoutModal"
				data-bs-toggle="modal"
			>
				Me déconnecter
			</button>
		</div>
	);
}

export default Profil;
