import { CloseModalButton } from "../../../common/Button";
import ContactForm from "../../user/contactForm/ContactForm";

const ContactModal = () => {
	return (
		<div
			className="modal fade"
			id="ContactModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="ContactModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content  px-3 py-3">
					{/* <div className="container-fluid mx-auto"> */}
					<ContactForm isShowContact={false} />
					{/* </div> */}
				</div>
			</div>
		</div>
	);
};

export default ContactModal;
