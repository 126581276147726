import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";
import { IMarketPlace, ItokenStatus, MarketPlaceFormData } from "./marketplace.type";

export const MarketPlaceApi = createApi({
	tagTypes: ["marketPlace"],
	reducerPath: "marketPlaceApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getMarketPlace: builder.query<IMarketPlace[], void>({
			query: () => "marketplaces/",
			providesTags: ["marketPlace"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdateMarketPlace: builder.mutation<IMarketPlace, { slug?: string; data: FormData }>({
			invalidatesTags: ["marketPlace"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `marketplaces/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `marketplaces/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteMarketPlace: builder.mutation<IMarketPlace, string>({
			query: (slug) => ({
				url: `marketplaces/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["marketPlace"],
		}),
		checkMarketPlaceTokenValidity: builder.query<ItokenStatus, void>({
			query: () => ({
				url: `check_market_token/`,
				method: "GET",
			}),
			providesTags: ["marketPlace"],
		}),
	}),
});

export const {
	useGetMarketPlaceQuery,
	useLazyGetMarketPlaceQuery,
	useAddOrUpdateMarketPlaceMutation,
	useDeleteMarketPlaceMutation,
	useCheckMarketPlaceTokenValidityQuery,
	useLazyCheckMarketPlaceTokenValidityQuery,
} = MarketPlaceApi;
