type ItemType = {
	label: string;
	value: string;
};
export const Genres: ItemType[] = [
	{ label: "Femme", value: "femme" },
	{ label: "Homme", value: "homme" },
	{ label: "Enfant", value: "enfant" },
];

export const Tailles: ItemType[] = [
	{ label: "Taille unique", value: "Taille unique" },
	{ label: "32 (taille XXS)", value: "32 (taille XXS)" },
	{ label: "34 (taille XS)", value: "34 (taille XS)" },
	{ label: "36 (taille S)", value: "36 (taille S)" },
	{ label: "38 (taille S)", value: "38 (taille S)" },
	{ label: "40 (taille S)", value: "40 (taille S)" },
	{ label: "42 (taille XL)", value: "42 (taille XL)" },
	{ label: "44 (taille XL)", value: "44 (taille XL)" },
	{ label: "46 (taille XL)", value: "46 (taille XL)" },
	{ label: "48 (taille XL)", value: "48 (taille XL)" },
	{ label: "50 (taille XXXL)", value: "50 (taille XXXL)" },
	{ label: "52 (taille XXXL)", value: "52 (taille XXXL)" },
];

export type FieldType =
	| "dashboard"
	| "vendeurs"
	| "pepites"
	| "marketplaces"
	| "abonnemements"
	| "messages"
	| "faqs"
	| "parametres";
type Authorization = {
	field: FieldType;
	label: string;
	path: string;
};
export const Authorisations: Authorization[] = [
	{
		field: "dashboard",
		label: "Dashboard",
		path: "/admin/dashboard",
	},
	{
		field: "vendeurs",
		label: "Vendeurs",
		path: "/admin/vendeurs",
	},
	{
		field: "pepites",
		label: "Pépites",
		path: "/admin/pepites",
	},
	{
		field: "marketplaces",
		label: "Marketplace",
		path: "/admin/marketplace",
	},
	{
		field: "abonnemements",
		label: "Abonnement",
		path: "/admin/abonnements",
	},
	{
		field: "messages",
		label: "Messages",
		path: "/admin/messages",
	},
	{
		field: "faqs",
		label: "Faq",
		path: "/admin/faq",
	},
	{
		field: "parametres",
		label: "paramètres",
		path: "/admin/parametres",
	},
];

export const WhoMadeValues = [
	{
		label: "Moi-même",
		value: "i_did",
	},
	{
		label: "Une autre personne ou un entreprise",
		value: "someone_else",
	},
	{
		label: "Un membre de ma boutique",
		value: "collective",
	},
];

export const WhenMadeValues = [
	{
		groupName: "Pas encore réalisé",
		options: [
			{
				label: "Réalisé sur commandes",
				value: "made_to_order",
			},
		],
	},
	{
		groupName: "Récement",
		options: [
			{
				label: "2010-2019",
				value: "2020_2022",
			},
			{
				label: "2010-2019",
				value: "2010_2019",
			},
			{
				label: "2003-2009",
				value: "2003_2009",
			},
		],
	},
	{
		groupName: "Vintage",
		options: [
			{
				label: "Avant 2003",
				value: "before_2003",
			},
			{
				label: "2000-2002",
				value: "2000_2002",
			},
			{
				label: "Années 1990",
				value: "1990s",
			},
			{
				label: "Années 1980",
				value: "1980s",
			},
			{
				label: "Années 1970",
				value: "1970s",
			},
			{
				label: "Années 1960",
				value: "1960s",
			},
			{
				label: "Années 1950",
				value: "1950s",
			},
			{
				label: "Années 1940",
				value: "1940s",
			},
			{
				label: "Années 1930",
				value: "1930s",
			},
			{
				label: "Années 1920",
				value: "1920s",
			},
			{
				label: "Années 1910",
				value: "1910s",
			},
			{
				label: "1900-1909",
				value: "1900s",
			},
			{
				label: "XIXe siècle",
				value: "1800s",
			},
			{
				label: "XVIII siècle",
				value: "1700s",
			},
			{
				label: "Avant 1700",
				value: "before_1700",
			},
		],
	},
];

export const Type = [
	{
		label: "Physique",
		value: "physical",
	},
	{
		label: "Numérique",
		value: "download",
	},
	{
		label: "Les deux",
		value: "both",
	},
];
export const WeightUnit = [
	{
		label: "Kilogramme",
		value: "kg",
	},
	{
		label: "Gramme",
		value: "g",
	},
	{
		label: "Ounce",
		value: "oz",
	},
	{
		label: "Pounds",
		value: "lb",
	},
];
export const DimensionsUnit = [
	{
		label: "Mètre",
		value: "m",
	},
	{
		label: "Centimètre",
		value: "cm",
	},
	{
		label: "Feet",
		value: "feet",
	},
	{
		label: "Inch",
		value: "inch",
	},
];

export const ORIGIN = window.location.origin;

export const telRegex = /^(?:\+\d{1,2}|\d{1,2})[\d\s.-]{8,}$/;