import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetSellPepitesBySellerQuery } from "../../../../utils/api/pepite/pepite.api";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { EBAY_REDIRECT_URL, ETSY_REDIRECT_URL } from "../../../../utils/http";
import { calculatePageCount } from "../../../../utils/utils";

export const useVentes = () => {
	const [page, setPage] = useState(1);
	const { user } = useAppSelector((s) => s.user);
	const [searchParams, setSearchParams] = useSearchParams();
	const [item, setItem] = useState<IPepite>();
	const [orderSlug, setOrderSlug] = useState<string>();

	const marketplace = searchParams.get("marketplace");

	const params = Object.fromEntries(searchParams);

	const handleFilterPepiteByMarketplace = (e: React.FormEvent<HTMLInputElement>): void => {
		setSearchParams({ ...params, marketplace: e.currentTarget.value });
	};

	const perPage = 10;
	const { data, isError, error, isLoading } = useGetSellPepitesBySellerQuery({
		page: page,
		limit: perPage,
		slug: user?.slug,
		marketplace,
	});

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	const getPepite = (item?: IPepite) => {
		setItem(item);
	};
	const getOrderSlug = (slug?: string) => {
		setOrderSlug(slug);
	};

	useEffect(() => {
		if (isError) {
			const err = error as any;
			if (err.status === 401 && marketplace === "etsy") {
				window.open(ETSY_REDIRECT_URL, "_self");
			} else {
				window.open(EBAY_REDIRECT_URL, "_self");
			}
		}
	}, [isError]);

	return {
		data,
		isLoading,
		setPage,
		handlePageClick,
		perPage,
		pageCount,
		isShowPagination: data?.count && data?.count > perPage,
		handleFilterPepiteByMarketplace,
		pepite: item,
		getPepite,
		orderSlug,
		getOrderSlug,
	};
};
