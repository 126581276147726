import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  CategoryFormData,
  ICategory,
  Subcategory,
  SubCategoryFormData,
} from "./category.type";


export const CategoryApi = createApi({
  reducerPath: "categoryAPi",
  tagTypes: ["category", ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCategory: builder.query<PaginationResults<ICategory>, TypeQuery>({
      query: () => `categories/`,
      providesTags: ["category"],
    }),
    addOrUpdateCategory: builder.mutation<
    ICategory,
      { slug?: string; data: CategoryFormData | FormData }
    >({
      invalidatesTags: ["category"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `categories/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `categories/`,
          method: "POST",
          body: data,
        };
      },
    }),
    addOrUpdateSubCategory: builder.mutation<
      Subcategory,
      { slug?: string; data: SubCategoryFormData | FormData }
    >({
      invalidatesTags: ["category"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `souscategories/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `souscategories/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteCategory: builder.mutation<ICategory, string>({
      query: (slug) => ({
        url: `categories/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["category"],
    }),
    deleteSubCategory: builder.mutation<Subcategory, string>({
      query: (slug) => ({
        url: `souscategories/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["category"],
    }),
  }),
});

export const {
  useGetCategoryQuery,
  useLazyGetCategoryQuery,
  useAddOrUpdateCategoryMutation,
  useAddOrUpdateSubCategoryMutation,
  useDeleteCategoryMutation,
  useDeleteSubCategoryMutation,
} = CategoryApi;
