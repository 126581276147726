import ReactQuill from "react-quill";
import { Modal } from "reactstrap";
import { PaymentMode } from "../../../../utils/api/paymentMode/paymentMode.type";
import { FormModalProps } from "../../../../utils/type";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { FormError, InputAdmin } from "../../../common/Input";
import { Select } from "../../../common/Select";
import UseCrudModePayment from "../../admin/Parametres/useParametreForm/UseCrudModePayment";
const options = [
	{
		value: "stripe",
		label: "Stripe",
	},
	{
		value: "paydunya",
		label: "Paydunya",
	},
	{
		value: "paytech",
		label: "Paytech",
	},
];
const AddPaiementModeModal = ({ item, isOpen, closeModal }: FormModalProps<PaymentMode>) => {
	const { register, errors, onSubmit, isLoading, handleChangeDescription, description } =
		UseCrudModePayment(closeModal, item);
	return (
		<Modal isOpen={isOpen} centered size="lg">
			<div className="modal-header">
				<h5 className="admin-modal-title" id="ajoutermodePaiementModalLabel">
					{item?.id ? "Modifier" : "Ajouter"} un mode de paiement
				</h5>
			</div>
			<form onSubmit={onSubmit}>
				<div className="modal-body text-start">
					<div className="row">
						<div className="col-12">
							<InputAdmin
								label="Mode de paiement"
								id="payMode"
								required
								placeholder="Mode de paiement"
								type="text"
								{...register("titre")}
								error={errors?.titre}
							/>
						</div>
						<div className="col-12 mb-3">
							<label htmlFor="mode_paiement" className="form-first-label">
								Choisissez un type
							</label>
							<select
								id="mode_paiement"
								{...register("nom")}
								className="form-select form-custom-control fas-form-select py-3"
							>
								{options?.map((option) => (
									<option value={option?.value} key={option?.value}>
										{option?.label}
									</option>
								))}
							</select>

							<FormError error={errors?.nom} />
						</div>

						<div className="col-12">
							<label htmlFor="description" className="custom-form-label">
								Description
							</label>
							<ReactQuill
								className="editor-cgu"
								defaultValue={description || item?.description}
								onChange={(value) => handleChangeDescription(value)}
							/>
							<FormError error={errors?.description} />
						</div>
					</div>
				</div>
				<div className="flex-end py-2 px-3 gap-3">
					<CloseModalButton text="Fermer" onClick={closeModal} />
					<ButtonSubmit label="ENREGISTRER" isLoading={isLoading} />
				</div>
			</form>
		</Modal>
	);
};

export default AddPaiementModeModal;
