import React from "react";
import { Link, useLocation } from "react-router-dom";

interface IBreadcrumbProps {
	routes: {
		name: string;
		path: string;
	}[];
}

export const BreadcrumbStyle = {
	fontFamily: "DM Sans",
	// color: pathname === route.path ? "#DA7943" : "#000000",
	fontWeight: 500,
	fontSize: 15,
	lineHeight: "20px",
} as React.CSSProperties;

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ routes }) => {
	const { pathname } = useLocation();
	return (
		<ul className="p-0 d-flex mb-1 mt-3">
			{routes?.map((route, index) => (
				<li key={route.name + index}>
					<Link
						to={route.path}
						style={{
							fontFamily: "DM Sans",
							color: pathname === route.path ? "#DA7943" : "#000000",
							fontWeight: 500,
							fontSize: 15,
							lineHeight: "20px",
						}}
					>
						<span>{route.name}</span>
						<span className={`mx-2 ${index === routes.length - 1 && "d-none"}`}>
							&gt;
						</span>
					</Link>
				</li>
			))}
		</ul>
	);
};

export default Breadcrumb;
