import ConfirmDiffusionMassModal from "./ConfirmDiffusionMassModal";
import { ItokenStatus } from "../../../../utils/api/marketplace/marketplace.type";
type Props = { tokenStatus?: ItokenStatus; arrayOfProductsId?: number[]; reset: () => void };

const ModalDiffusionMassProducts: React.FC<Props> = ({ tokenStatus, arrayOfProductsId, reset }) => {
	return (
		<>
			<div
				className="modal fade"
				id="ModalDiffusionMassProducts"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				aria-labelledby="ModalDiffusionMassProductsLabel"
				aria-hidden="true"
			>
				<div className={`modal-dialog modal-dialog-centered `}>
					<div className="modal-content fas__modal  px-3 py-4">
						<div className="container-fluid mx-auto">
							<ConfirmDiffusionMassModal
								produitsIds={arrayOfProductsId}
								tokenStatus={tokenStatus}
								reset={reset}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalDiffusionMassProducts;
