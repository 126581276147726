import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { IStepsSignupProps } from "../../../../../utils/api/auth/auth.type";
import { useGetOfferForVisitorsQuery } from "../../../../../utils/api/offer/offer.api";
import { OfferOptionType } from "../../../../../utils/api/offer/offer.type";
import { createMarkup } from "../../../../../utils/utils";
import { BtnPrimary } from "../../../../common/Button";
import ChooseAbonnementModal from "../../../modals/user/ChooseAbonnementModal";

const Abonnement: React.FC<IStepsSignupProps> = ({
	onSubmit,
	errors,
	handleChoiceOffer,
	checkedId,
	isShowOffer,
	checkedModePayementId,
	handleChoiceModePaiement,
	isOpen,
	toggle,
}) => {
	const { data, isLoading } = useGetOfferForVisitorsQuery();
	return (
		<React.Fragment>
			<h2>Mon abonnement</h2>
			<div className="offres-container my-5">
				<div className="row">
					{!isLoading ? (
						data?.map((item) => (
							<div className="col-12 col-lg-6 col-xl-4 mb-3" key={item?.slug}>
								<div className="offer-item">
									<div className="recommande-container mb-2">
										{item?.option === OfferOptionType.BASIC && (
											<div className="badge-recommande">Recommandé</div>
										)}
									</div>
									<div className="px-2">
										<h3 className="fas-offer-title mb-3">{item?.title} </h3>

										{item?.details && (
											<div className="fas-offer-description">
												<p>{item?.details}</p>
											</div>
										)}
										<div className="offer-advantage-container">
											<ul
												className="mt-3 px-0 avantages-offres"
												dangerouslySetInnerHTML={createMarkup(
													item?.description
												)}
											></ul>
										</div>

										<div className="abonnement-price-container flex-c gap-2 mt-3 mb-3">
											<span style={{ fontWeight: 800, fontSize: 20 }}>€</span>
											<h5 className="fas-ab-price">
												{item?.option !== OfferOptionType.FREEMIUM
													? item?.price
													: "0.00"}
											</h5>
											<h6
												className="m-0"
												style={{
													fontWeight: 600,
													fontSize: 20,
													lineHeight: "50px",
												}}
											>
												/ mois
											</h6>
										</div>
										<div className="flex-c mb-4">
											<BtnPrimary
												label="SOUSCRIRE"
												mode="Secondary"
												// data-bs-toggle="modal"
												// data-bs-target="#ChooseAbonnementModal"
												className="btn__add fashion__btn subscribe_btn"
												onClick={() => handleChoiceOffer(item)}
											/>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<>
							{[...Array(3)]?.map((item, i) => (
								<div className="col-12 col-lg-6 col-xl-4 p-0" key={i}>
									<div className="offer-item">
										<div className="px-2">
											<div className="mb-3">
												<VolkenoSkeleton
													variant="rect"
													width="30%"
													height={10}
													className="mb-3"
												/>
											</div>
											<div className="mb-3">
												<VolkenoSkeleton
													variant="rect"
													width="100%"
													height={30}
													className="mb-3"
												/>
											</div>
											{[...Array(4)]?.map((item, i) => (
												<div className="mb-1" key={i}>
													<VolkenoSkeleton
														variant="rect"
														width="100%"
														height={10}
														className="mb-3"
													/>
												</div>
											))}
											<div className="mb-3">
												<VolkenoSkeleton
													variant="rect"
													width="100%"
													height={20}
													className="mb-3"
												/>
											</div>
											<div className="mb-3 px-5">
												<VolkenoSkeleton
													variant="rect"
													width="100%"
													height={40}
													className="mb-3"
													style={{ borderRadius: 0 }}
												/>
											</div>
										</div>
									</div>
								</div>
							))}
						</>
					)}
				</div>
			</div>

			{/* <div className="flex-end">
				<BtnPrimary
					label="Suivant"
					type="button"
					data-bs-toggle="modal"
					data-bs-target="#ChooseAbonnementModal"
				/>
			</div> */}
			<ChooseAbonnementModal
				errors={errors}
				onSubmit={onSubmit}
				checkedId={checkedId}
				handleChoiceOffer={handleChoiceOffer}
				isShowOffer={isShowOffer}
				checkedModePayementId={checkedModePayementId}
				handleChoiceModePaiement={handleChoiceModePaiement}
				isOpen={isOpen}
				toggle={toggle}
			/>
		</React.Fragment>
	);
};

export default Abonnement;
