import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
	useDeleteAdminMutation,
	useGetAdminsListQuery,
	useEditAdminMutation,
} from "../../../../../utils/api/admin/admin.api";
import { IAdmin,IAdminFormData  } from "../../../../../utils/api/admin/admin.type";
import { useDeleteConfirme } from "../../../../../utils/helpers";
import { getName } from "../../../../../utils/utils";
import {
	ButtonDelete,
	ButtonEditLink,
	ButtonViewLink,
	ButtonArchive,
} from "../../../../common/Button";
import { CustomPagination } from "../../../../common/CustomPagination";
import { Color } from "../../../../../utils/theme";
import Swal from "sweetalert2";
import TableSkeleton from "../../../../common/TableSkeleton";

function AdminsTable() {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { data = { results: [] }, isLoading } = useGetAdminsListQuery({
		page,
		limit: perPage,
	});
	const actionFormatter = (cell: string, row: IAdmin) => {
		return (
			<div className="gap-3 d-flex">
				<ArchiveAdmin user={row} />
				<ButtonViewLink to={`/admin/${row?.slug}/profil`} state={row} />
				<ButtonEditLink to={`/admin/${row?.slug}/modifier`} state={row} />
				{/* <DeleteAdmin item={row} /> */}
			</div>
		);
	};

	const columns = [
		{
			dataField: "id",
			text: "N°",
		},
		{
			dataField: "nom",
			text: "Prénom & Nom",
			formatter: (cell: string, row: IAdmin) => getName(row),
		},
		{
			dataField: "telephone",
			text: "Téléphone",
		},
		{
			dataField: "email",
			text: "Adresse Mail",
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: string, row: IAdmin) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	return (
		<>
			{isLoading && (
				<TableSkeleton
					headers={["N°", "Prénom & Nom", "Téléphone", "Adresse Mail", "Actions"]}
				/>
			)}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={false}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table"
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
		</>
	);
}

export function DeleteAdmin({ item }: { item: IAdmin }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteAdminMutation();
	const onDelete = useDeleteConfirme<IAdmin>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet administrateur?",
		successMessage: "Administrateur supprimé",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export function ArchiveAdmin({ user }: { user: IAdmin }) {
	const [editData] = useEditAdminMutation();
	const onArchive = async () => {
		let data: IAdminFormData = {
			is_active: user.is_archive,
			is_archive: !user.is_archive,
		};
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir  ${
				user?.is_archive === true ? "désarchiver" : "archiver"
			} cet admin ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.primary,
			confirmButtonColor: Color.primary,
			preConfirm: () => {
				return editData({ slug: user.slug, user_type: "admin", data: data });
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Admin ${
						result?.value?.data?.is_archive ? "archivé " : "désarchivé"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	return <ButtonArchive label="Archiver" isArchive={user.is_archive} onClick={onArchive} />;
}

export default AdminsTable;
