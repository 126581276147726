import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IStepsSignupProps } from "../../../../../utils/api/auth/auth.type";
import { BtnPrimary } from "../../../../common/Button";
import { Input, InputIcon } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";

const AuthInfos: React.FC<IStepsSignupProps> = ({ register, onSubmit, errors, setValue }) => {
	const {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	} = usePasswordRule(setValue);
	return (
		<form onSubmit={onSubmit}>
			<RequiredMessage />
			<Input
				label="Adresse mail*"
				type="text"
				id="email"
				{...register("email")}
				error={errors?.email}
			/>
			<InputIcon
				label="Mot de passe*"
				id="password"
				{...register("password")}
				error={errors?.password}
				onChange={handleChangePassword}
				onFocus={showPasswordRule}
				onBlur={showPasswordRule}
			/>
			<InputIcon
				label="Confirmation du mot de passe*"
				id="confirm-password"
				{...register("confirmPassword")}
				error={errors?.confirmPassword}
			/>
			{isShowPasswordRules && (
				<div className="col-md-6">
					<div className="password-validation-terms-container">
						<h4 className="password-validation-terms-title">
							Votre mot de passe doit contenir :{" "}
						</h4>
						<ul className="password-validation-terms-ul px-2">
							<li
								className={
									"password-rule-item " + (haveMinCharacter && "fas-text-success")
								}
							>
								Au minimum 8 caractères
							</li>
							<li
								className={
									"password-rule-item " + (haveMinUppercase && "fas-text-success")
								}
							>
								Au minimum 1 caractère en majuscule
							</li>
							<li
								className={
									"password-rule-item " + (haveMinLowercase && "fas-text-success")
								}
							>
								Au minimum 1 caractère en minuscule
							</li>
							<li
								className={
									"password-rule-item " + (haveMinNumber && "fas-text-success")
								}
							>
								Au minimum 1 nombre
							</li>
							<li
								className={
									"password-rule-item " +
									(haveMinSpecialCharacter && "fas-text-success")
								}
							>
								Au minimum 1 caractère spéciale
							</li>
						</ul>
					</div>
				</div>
			)}
			<div className="btn-container mt-5">
				<BtnPrimary label="Commencer l’inscription" width="w-100" />
			</div>
			<div className="auth-redirect flex-m-sb mt-3">
				<p className="auth-redirect-question">Déjà membre sur notre plateforme ?</p>
				<Link to="/se-connecter" className="auth-link">
					Connectez-vous ici
				</Link>
			</div>
		</form>
	);
};

const usePasswordRule = (setValue: any) => {
	const [haveMinUppercase, setHaveMinUppercase] = useState(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState(false);
	const [haveMinNumber, setHaveMinNumber] = useState(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
	const [isShowPasswordRules, setIsShowPasswordRules] = useState(false);

	const countUppercase = (str: string) => {
		if (str.replace(/[^A-Z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str: string) => {
		if (str.replace(/[^a-z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str: string) => {
		if (str.replace(/[^0-9]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str: string) => {
		if (str.length >= 8) {
			return true;
		}
		return false;
	};
	const countSpecial = (str: string) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		if (count >= 1) {
			return true;
		}
		return false;
	};
	const handleChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
		var password = e.currentTarget.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}
		setValue("password", e.currentTarget.value);
	};
	const showPasswordRule = () => {
		setIsShowPasswordRules((isShowPasswordRules) => !isShowPasswordRules);
	};
	return {
		haveMinCharacter,
		haveMinNumber,
		haveMinLowercase,
		haveMinSpecialCharacter,
		haveMinUppercase,
		handleChangePassword,
		isShowPasswordRules,
		showPasswordRule,
	};
};

export default AuthInfos;
