import { BsThreeDotsVertical } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useDeleteCollectionMutation } from "../../../../utils/api/collection/collection.api";
import { ICollection } from "../../../../utils/api/collection/collection.type";
import { useDelete } from "../../../../utils/helpers";
import { getImage, useModal } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import PanelFilterCollection from "../../../common/PanelFilterCollection";
import AddCollectionModal from "../../modals/user/AddCollectionModal";
import ModalDiffusionMassProducts from "../Stock/ModalDiffusionMassProducts";
import { useCheckTokenStatus } from "../Stock/useStock";
import useCollection from "./hooks/useCollection";

const MesCollections = () => {
	const {
		data,
		isLoading,
		handlePageClick,
		pageCount,
		isShowPagination,
		getProduitsId,
		produitsId,
		handleFilterCollection,
	} = useCollection();
	const { isShowModal, toggle, openModal, openEditModal, item, setItem } =
		useModal<ICollection>();
	const { tokenStatus, onCheckTokenStatus } = useCheckTokenStatus();

	return (
		<div className="collection__page">
			<div className="d-flex flex-column flex-md-row justify-content-md-between gap-3">
				<h1>Mes collections</h1>
				<div className="flex-end">
					<button className="fashion__btn btn__add px-3 py-2" onClick={openModal}>
						<FiPlus />
						Ajouter une collection
					</button>
				</div>
			</div>
			<PanelFilterCollection handleFilterCollection={handleFilterCollection} />
			<div className="row pt-3">
				{!isLoading ? (
					data && data?.results?.length > 0 ? (
						data?.results?.map((item) => (
							<div className="fashion__card mb-3 gap-3" key={item?.slug}>
								<div className="row pepite__item py-2">
									<div className="col-md-6  position-relative">
										<div className="pepite-left-block d-md-flex gap-md-3">
											<div className="pepite_img_container">
												<img
													src={getImage(item?.image)}
													alt={item?.libelle}
													className="pepite__img"
												/>
											</div>
											<div className="pepites__infos align-items-center flex-fill pe-3">
												<h4 className="pepite__title">{item?.libelle}</h4>
												<div>
													<div className="group-info">
														<h5 className="group_info_title">
															Nombre de pépites
														</h5>
														<h6
															className="group_info_value"
															style={{ fontSize: "16px" }}
														>
															{item?.produits?.length}
														</h6>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="d-md-flex justify-content-between  align-items-center col-md-6">
										<div className="group-info">
											<h5 className="group_info_title">statut</h5>
											<div className="position-relative ps-3">
												<h6
													className={
														`group_info_statut `
														// ${
														// 	item?.en_ligne
														// 		? "online"
														// 		: "ebauche"
													}
												>
													{/* {item?.en_ligne ? "En ligne" : "Ébauche"} */}
													Ebauche
												</h6>
											</div>
										</div>
										<div className="flex-m gap-3">
											<Link
												to={`/user/mes-collections/${item?.slug}`}
												className="btn__show"
												state={item}
											>
												Détails
											</Link>
											{item?.produits?.length > 0 ? (
												<button
													className="btn__show"
													data-bs-toggle="modal"
													data-bs-target="#ModalDiffusionMassProducts"
													onClick={() => {
														onCheckTokenStatus();
														getProduitsId(item?.id);
													}}
												>
													Diffuser
												</button>
											) : (
												<button
													className="btn__show btn__disabled"
													disabled={true}
												>
													Diffuser
												</button>
											)}
											<div className="dropdown">
												<button
													className="dropdown-toggle"
													type="button"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													<BsThreeDotsVertical />
												</button>
												<ul className="dropdown-menu fashion__card pepite_action_container mt-2">
													<div className="d-flex flex-column align-items-end px-2 gap-2">
														<>
															<li>
																<button
																	className="btn_action btn__edit"
																	onClick={() =>
																		openEditModal(item)
																	}
																>
																	Modifier
																</button>
															</li>

															<li>
																<DeleteCollection item={item} />
															</li>
														</>
													</div>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<AlertInfo message="Aucune collection trouvée" />
					)
				) : (
					<>
						{[...Array(4)]?.map((pepite, index) => (
							<CollectionItemSkeleton key={index} />
						))}
					</>
				)}
				{isShowPagination ? (
					<div className="flex-c">
						<Pagination
							title=""
							handlePageClick={handlePageClick}
							pageCount={pageCount}
						/>
					</div>
				) : null}
			</div>
			<AddCollectionModal
				item={item}
				isOpen={isShowModal}
				closeModal={toggle}
				setItem={setItem}
			/>
			<ModalDiffusionMassProducts
				tokenStatus={tokenStatus}
				reset={() => {}}
				arrayOfProductsId={produitsId}
			/>
		</div>
	);
};
export function DeleteCollection({ item }: { item?: ICollection }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteCollectionMutation();
	const onDelete = useDelete<ICollection>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette collection?",
		successMessage: "Collection supprimée",
	});
	return (
		<button className="btn_action btn__delete" onClick={onDelete}>
			supprimer
		</button>
	);
}

const CollectionItemSkeleton = () => {
	return (
		<div className="fashion__card px-1 py-2 row mb-3 pepite__item">
			<div className="col-md-6  position-relative">
				<div className="d-md-flex gap-md-3">
					<div className="pepite_img_container">
						<VolkenoSkeleton variant="rect" width="90px" height="90px" />
					</div>
					<div className="pepites__infos flex-fill pe-3">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
						<div className="flex-sb ">
							<div className="group-info">
								<VolkenoSkeleton variant="rect" width={100} height={10} />
								<VolkenoSkeleton variant="rect" width={100} height={10} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex-m-sb col-md-6">
				<div className="group-info">
					<VolkenoSkeleton variant="rect" width={100} height={10} />
					<VolkenoSkeleton variant="rect" width={100} height={10} />
				</div>
				<div className="flex-m gap-3">
					<VolkenoSkeleton variant="rect" width={50} height={20} />
					<VolkenoSkeleton variant="rect" width={5} height={25} />
				</div>
			</div>
		</div>
	);
};

export default MesCollections;
