import React from "react";
import { Modal } from "reactstrap";
import { IOfferModalProps, PayememntModeProps } from "../../../../utils/api/auth/auth.type";
import { useGetPaymentModeForVisitorQuery } from "../../../../utils/api/paymentMode/paymentMode.api";
import { PaymentMode } from "../../../../utils/api/paymentMode/paymentMode.type";
import { Color } from "../../../../utils/theme";
import { BtnPrimary } from "../../../common/Button";
import { FormError } from "../../../common/Input";

const ChooseAbonnementModal: React.FC<IOfferModalProps> = ({
	onSubmit,
	errors,
	isShowOffer,
	checkedModePayementId,
	handleChoiceModePaiement,
	isOpen,
	toggle,
}) => {
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-content fas__modal  px-3 py-4">
				<div className="container-fluid mx-auto">
					<h3 className="mb-4">Choisir un mode de paiement</h3>
					<form onSubmit={onSubmit}>
						<ModePaiementsLists
							handleChoiceModePaiement={handleChoiceModePaiement}
							checkedModePayementId={checkedModePayementId}
							errors={errors}
						/>
						<div className="d-flex justify-content-center gap-5 mt-5">
							<BtnPrimary
								label={isShowOffer ? "Suivant" : "Valider"}
								mode="Secondary"
							/>
							<button
								type="button"
								className="fashion__btn btn__primary btn__danger"
								style={{
									borderRadius: "4px",
									color: Color.red,
								}}
								// data-bs-dismiss="modal"
								onClick={toggle}
							>
								annuller
							</button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export function ModePaiementsLists({
	errors,
	checkedModePayementId,
	handleChoiceModePaiement,
}: PayememntModeProps) {
	const { data, isLoading } = useGetPaymentModeForVisitorQuery();
	return (
		<>
			<div className="mode-paiements-lists d-flex flex-wrap gap-3">
				{!isLoading &&
					data?.map((item: PaymentMode) => (
						<div
							className={`mode-paiement-item bg-white  shadow p-2 mb-4 ${
								checkedModePayementId === item?.id ? "active" : ""
							}`}
							style={{ borderRadius: "10px" }}
							key={item?.slug}
							role="button"
							onClick={() => handleChoiceModePaiement(item)}
						>
							<h5>{item?.titre}</h5>
						</div>
					))}
			</div>
			<FormError error={errors?.moyen_paiement} />
		</>
	);
}

export default ChooseAbonnementModal;
