import React, { useState } from "react";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useGetGraphUserByWeekQuery } from "../../../../../utils/api/admin/admin.api";
import moment from "moment";
import VolkenoSkeleton from "volkeno-react-skeleton";

const UserLineChart = ({ title }: { title: string }) => {
	const labels = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
	const [date, setDate] = useState<Date>(new Date());

	const { data, isLoading } = useGetGraphUserByWeekQuery({
		date: moment(date).format("DD-MM-YYYY"),
	});
	const series = [
		{
			data: data
				? data.map((date) => Number(date?.user_count_by_week))
				: [0, 0, 0, 0, 0, 0, 0],
			name: title,
		},
	];

	const options: any = {
		chart: {
			height: 340,
			type: "area",
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		colors: ["#E97D24"],
		fill: {
			type: "gradient",
			gradient: {
				type: "horizontal",
				shadeIntensity: 1,
				opacityFrom: 0.4,
				opacityTo: 0.3,
				stops: [100, 60, 30, 0],
			},
			colors: ["#FFD4B1"],
		},
		xaxis: {
			categories: labels,
			labels: {
				style: {
					colors: "#E97D24",
				},
			},
		},
		yaxis: {
			labels: {
				style: {
					colors: "#9FA2B4",
				},
			},
		},
	};
	return (
		<div className="bg-white p-2 p-md-5 mb-5">
			<div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center">
				<h6 className="chart_title">{title}</h6>
				<div className="select-filter-container ">
					<DatePicker
						selected={date}
						onChange={(date: Date) => setDate(date)}
						className="filter_control"
						maxDate={new Date()}
						dateFormat="dd/MM/yyyy"
					/>
				</div>
			</div>
			<div className="bar-chart-container mt-5">
				{!isLoading && data ? (
					<Chart
						className="chart-dashboard"
						options={options}
						series={series}
						data={title}
						type="area"
						width="100%"
						height="350"
					/>
				) : (
					<VolkenoSkeleton variant="rect" width="100%" height="350px" />
				)}
			</div>
		</div>
	);
};

export default UserLineChart;
