import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onlogout } from "../../../../../redux/slice/User.slice";
import { useDeleteUserAccountMutation } from "../../../../../utils/api/user/user.api";
import { DeleteUserAccountData } from "../../../../../utils/api/user/user.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";

export function useDeleteUserAccount() {
	const validationSchema = yup.object().shape({
		password: yup.string().required().label("Mot de passe"),
	});

	const [deleteUserAccount, { error, isSuccess, isLoading, isError }] =
		useDeleteUserAccountMutation();

	const { user } = useAppSelector((s) => s.user);
	const dispatch = useAppDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
	} = useForm<DeleteUserAccountData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Votre compte a été supprimé avec succès",
				timer: 2500,
			}).then(() => {
				onHide("DeleteAccountModal");
				dispatch(onlogout());
			});
		}
		if (isError) {
			const err = error as any;
			const message =
				err?.data[0] === "Le mot de passe est incorrect."
					? "Le mot de passe est incorrect."
					: "Une erreur a survenue lors de la connexion";
			setError("password", { message: message });
		}
	}, [isLoading]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = (data: DeleteUserAccountData) => {
		deleteUserAccount({ slug: user?.slug, data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		clearErrors,
		isLoading,
	};
}
