import React, { useState } from "react";
import { BtnPrimary } from "../../../../common/Button";
import { FormError, RadioButton } from "../../../../common/Input";
import Crushon from "../../../../../assets/images/Diffusion/Crushon.png";
import Ebay from "../../../../../assets/images/Diffusion/Ebay.png";
import Shopify from "../../../../../assets/images/Diffusion/Shopify.png";
import MarketPlace from "../../../../../assets/images/Diffusion/MarketPlace.png";
import RequiredMessage from "../../../../common/RequiredMessage";
import { IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { useGetMarketPlaceQuery } from "../../../../../utils/api/marketplace/marketplace.api";
import { IMarketPlace, ItokenStatus } from "../../../../../utils/api/marketplace/marketplace.type";
import { getImage } from "../../../../../utils/utils";
import VolkenoSkeleton from "volkeno-react-skeleton";

export const logoPlatformes = [
	{
		id: 0,
		logo: Crushon,
		name: "Crushon",
	},
	{
		id: 1,
		logo: Ebay,
		name: "Ebay",
	},
	{
		id: 2,
		logo: MarketPlace,
		name: "MarketPlace",
	},
	{
		id: 3,
		logo: Shopify,
		name: "Shopify",
	},
];

const Diffusion: React.FC<IStepsCrudPepiteProps> = ({ register, onSubmit, errors, setValue }) => {
	const [isChecking, setIsChecking] = useState<IMarketPlace>();
	const [isShowMarketplace, setIsShowMarketplace] = useState<boolean>(false);

	const handleChecked = (item: IMarketPlace) => {
		console.log({ item });
		setIsChecking(item);
		setValue("marketplace", String(item?.id));
		setValue("marketplace_name", item?.nom);
	};

	const handleShowDiffusionPlateforme = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.value === "true") {
			setIsShowMarketplace(true);
		} else {
			setIsShowMarketplace(false);
		}
	};
	return (
		<>
			<h2>Diffuser ma pépite</h2>
			<h4 className="sous__title mt-3">
				Voulez-vous diffuser maintenant votre pépite sur les marketplaces ?
			</h4>
			<form action="" className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="col-md-6">
					<div className="flex-m gap-4 pt-2">
						<RadioButton
							label="Oui"
							id="oui"
							value="true"
							{...register("online")}
							onChange={handleShowDiffusionPlateforme}
						/>
						<RadioButton
							label="Non"
							id="non"
							value="false"
							{...register("online")}
							onChange={handleShowDiffusionPlateforme}
						/>
					</div>
					<FormError error={errors?.online} />
				</div>
				{isShowMarketplace && (
					<RadioDiffusionPlatforme1
						checked={isChecking}
						handleChecked={handleChecked}
						title="Voulez-vous diffuser maintenant votre pépite sur les marketplaces ?"
						paddingX="px-3"
					/>
				)}
				<FormError error={errors?.marketplace?.message} />
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</>
	);
};

export function DiffusionPlatforme({
	title,
	checked = [],
	handleChecked,
	paddingX,
	imgStyle,
}: {
	title: string;
	checked: number[];
	handleChecked: (e: any) => void;
	paddingX?: string;
	imgStyle?: boolean;
}) {
	const { data, isLoading } = useGetMarketPlaceQuery();
	return (
		<>
			<h4 className="sous__title mt-4">{title}</h4>
			<p className="required-text mb-2 mt-4">1 choix obligatoire (choix multiple)</p>
			<div className="d-flex flex-column flex-sm-row flex-wrap gap-3">
				{!isLoading ? (
					data?.map((item: IMarketPlace) => (
						<label
							className={`plateformes__logo_container ${paddingX} flex-m justify-content-center ${
								checked?.includes(item?.id) && "checked"
							}`}
							htmlFor={item?.slug}
							key={item?.slug}
						>
							<img
								src={getImage(item.logo)}
								alt={item?.slug}
								style={!imgStyle ? { width: "65px" } : {}}
							/>
							<input
								type="checkbox"
								id={item?.slug}
								value={item?.id}
								onChange={(e) => handleChecked(e)}
								className="invisible"
							/>
						</label>
					))
				) : (
					<div className="row flex-fill">
						{[...Array(2)]?.map((item, _i) => (
							<div className="col-6 col-lg-3" key={_i}>
								<VolkenoSkeleton
									variant="rect"
									widht="100%"
									height={50}
									style={{ backgroundColor: "#cccac8" }}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
}

export function RadioDiffusionPlatforme1({
	title,
	checked,
	handleChecked,
	paddingX,
}: {
	title: string;
	checked?: IMarketPlace;
	handleChecked: (e: IMarketPlace) => void;
	paddingX?: string;
	imgStyle?: boolean;
}) {
	const { data, isLoading } = useGetMarketPlaceQuery();
	return (
		<>
			<h4 className="sous__title mt-4">{title}</h4>
			<p className="required-text mb-2 mt-4">
				1 choix obligatoire
				{/* (choix multiple) */}
			</p>
			<div className="d-flex flex-column flex-sm-row flex-wrap gap-3">
				{!isLoading ? (
					data?.map((item: IMarketPlace) => (
						<label
							className={`plateformes__logo_container ${paddingX} flex-m justify-content-center ${
								checked?.id === item?.id && "checked"
							}`}
							htmlFor={item?.nom}
							key={item?.slug}
						>
							<img
								src={getImage(item.logo)}
								alt={item?.slug}
								style={{ width: "60px" }}
							/>
							<input
								type="radio"
								id={item?.nom}
								value={item?.id}
								onChange={() => handleChecked(item)}
								className="invisible"
								name="marketplace"
							/>
						</label>
					))
				) : (
					<div className="row flex-fill">
						{[...Array(2)]?.map((item, _i) => (
							<div className="col-6 col-lg-3" key={_i}>
								<VolkenoSkeleton
									variant="rect"
									widht="100%"
									height={50}
									style={{ backgroundColor: "#cccac8" }}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
}
type Props = {
	title: string;
	checked?: string;
	handleChecked: (e: any, tokenStatus?: number) => void;
	paddingX?: string;
	imgStyle?: boolean;
	id: string;
	tokenStatus?: ItokenStatus;
};
export function RadioDiffusionPlatforme(props: Props) {
	const { title, checked = "", handleChecked, paddingX, id, tokenStatus } = props;
	const { data, isLoading } = useGetMarketPlaceQuery();
	return (
		<>
			<h4 className="sous__title mt-4">{title}</h4>
			<p className="required-text mb-2 mt-4">
				1 choix obligatoire
				{/* (choix multiple) */}
			</p>
			<div className="d-flex flex-column flex-sm-row flex-wrap gap-3">
				{!isLoading ? (
					data?.map((item: IMarketPlace) => (
						<label
							className={`plateformes__logo_container ${paddingX} flex-m justify-content-center position-relative ${
								checked === item?.nom ? "checked" : ""
							}`}
							htmlFor={item?.nom + id}
							key={item?.slug}
						>
							<img
								src={getImage(item.logo)}
								alt={item?.slug}
								style={{ width: "65px" }}
							/>
							<input
								type="radio"
								id={item?.nom + id}
								value={item?.nom}
								onChange={(e) =>
									handleChecked(e, tokenStatus && tokenStatus[item?.nom])
								}
								className="invisible"
								name="marketplace"
							/>

							{tokenStatus ? (
								<div
									className={`token-market-status ${
										tokenStatus[item?.nom] === 200 ? "active" : "unactive"
									}`}
								/>
							) : (
								<div className="token-market-status" />
							)}
						</label>
					))
				) : (
					<div className="row flex-fill">
						{[...Array(2)]?.map((item, _i) => (
							<div className="col-6 col-lg-3" key={_i}>
								<VolkenoSkeleton
									variant="rect"
									widht="100%"
									height={50}
									style={{ backgroundColor: "#cccac8" }}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
}

export default Diffusion;
