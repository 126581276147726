import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/Logo.png";
import { Color } from "../../../../utils/theme";
import StepperVertical from "../../../common/StepperVertical";
import AuthLayout, { AuthMobileBanner } from "../AuthLayout";
import "./Onboarding.css";

interface props {
	children: React.ReactNode;
	onClick?: () => void;
	onRestart?: () => void;
	index?: number;
}

const levels = [
	"Informations personnelles",
	"Informations de ma boutique",
	"Adresse de facturation",
	"Mon abonnement",
	"Finaliser mon inscription",
];

export const OnBoardingWrapper: React.FC<props & { index: number }> = ({
	children,
	index,
	onRestart,
}) => {
	return (
		<div className="onboarding_page">
			<div className="d-lg-flex justify-content-lg-around">
				<nav>
					<Link className="logo-container pt-3" to="/">
						<img src={Logo} alt="fashion-logo" className="logo-app" />
					</Link>
				</nav>
				<div className="pt-3 px-3 px-md-5 px-lg-2 w-lg-80 pt-lg-6">
					<h1>Bienvenue</h1>
					<div className="row d-md-flex flex-row-reverse">
						<div className="col-md-3 col-xl-2 mb-5">
							<div className="flex-end mb-3">
								<button
									className="logout__btn common_style px-3 py-2"
									style={{ background: "#FBEEE9", color: Color.red }}
									onClick={onRestart}
								>
									<span>Recommencer</span>
								</button>
							</div>
							<StepperVertical index={index} steps={levels} name="authStepper" />
						</div>
						<div className="onboard__card px-xl-3 py-3 col-xl-10 col-md-9">
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const FirstSignupFormWrapper: React.FC<props> = ({ children }) => {
	return (
		<AuthLayout>
			<AuthMobileBanner title="Inscription" />
			<div className="px-2 px-md-4 px-lg-1">
				<div className="d-lg-none mt-3">
					<h2 className="auth-title-mobile text-sm-center">S’inscrire</h2>
				</div>
				<div className="mb-4">
					<p className="auth-accroche-text">
						Diffuser votre pépite sur plusieurs marketplaces n’a jamais été aussi rapide
						!
					</p>
				</div>
				{children}
			</div>
		</AuthLayout>
	);
};
