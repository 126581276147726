import React from "react";

export function IconGrid() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 10H1C0.45 10 0 9.55 0 9V1C0 0.45 0.45 0 1 0H7C7.55 0 8 0.45 8 1V9C8 9.55 7.55 10 7 10ZM7 18H1C0.45 18 0 17.55 0 17V13C0 12.45 0.45 12 1 12H7C7.55 12 8 12.45 8 13V17C8 17.55 7.55 18 7 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 5V1C10 0.45 10.45 0 11 0H17C17.55 0 18 0.45 18 1V5C18 5.55 17.55 6 17 6H11C10.45 6 10 5.55 10 5Z"
			/>
		</svg>
	);
}

export function IconStore() {
	return (
		<svg
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.00007 2H17.0001C17.5501 2 18.0001 1.55 18.0001 1C18.0001 0.45 17.5501 0 17.0001 0H3.00007C2.45007 0 2.00007 0.45 2.00007 1C2.00007 1.55 2.45007 2 3.00007 2ZM18.1601 3.8C18.0701 3.34 17.6601 3 17.1801 3H2.82007C2.34007 3 1.93007 3.34 1.84007 3.8L0.840074 8.8C0.720074 9.42 1.19007 10 1.82007 10H2.00007V15C2.00007 15.55 2.45007 16 3.00007 16H11.0001C11.5501 16 12.0001 15.55 12.0001 15V10H16.0001V15C16.0001 15.55 16.4501 16 17.0001 16C17.5501 16 18.0001 15.55 18.0001 15V10H18.1801C18.8101 10 19.2801 9.42 19.1601 8.8L18.1601 3.8ZM4.00007 14H10.0001V10H4.00007V14Z"
			/>
		</svg>
	);
}

export function IconShare() {
	return (
		<svg
			width="18"
			height="20"
			viewBox="0 0 18 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M15 14.12C14.24 14.12 13.56 14.42 13.04 14.89L5.91 10.74C5.96 10.51 6 10.28 6 10.04C6 9.80004 5.96 9.57004 5.91 9.34004L12.96 5.23004C13.5 5.73004 14.21 6.04004 15 6.04004C16.66 6.04004 18 4.70004 18 3.04004C18 1.38004 16.66 0.0400391 15 0.0400391C13.34 0.0400391 12 1.38004 12 3.04004C12 3.28004 12.04 3.51004 12.09 3.74004L5.04 7.85004C4.5 7.35004 3.79 7.04004 3 7.04004C1.34 7.04004 0 8.38004 0 10.04C0 11.7 1.34 13.04 3 13.04C3.79 13.04 4.5 12.73 5.04 12.23L12.16 16.39C12.11 16.6 12.08 16.82 12.08 17.04C12.08 18.65 13.39 19.96 15 19.96C16.61 19.96 17.92 18.65 17.92 17.04C17.92 15.43 16.61 14.12 15 14.12Z" />
		</svg>
	);
}

export function IconBag() {
	return (
		<svg
			width="18"
			height="22"
			viewBox="0 0 18 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 5.5H14C14 2.74 11.76 0.5 9 0.5C6.24 0.5 4 2.74 4 5.5H2C0.9 5.5 0 6.4 0 7.5V19.5C0 20.6 0.9 21.5 2 21.5H16C17.1 21.5 18 20.6 18 19.5V7.5C18 6.4 17.1 5.5 16 5.5ZM9 2.5C10.66 2.5 12 3.84 12 5.5H6C6 3.84 7.34 2.5 9 2.5ZM4.16 8.75C4.71 10.91 6.67 12.5 9 12.5C11.33 12.5 13.29 10.91 13.84 8.75C14 8.12 13.52 7.5 12.87 7.5C12.4 7.5 12.02 7.84 11.89 8.3C11.54 9.57 10.38 10.5 9 10.5C7.62 10.5 6.46 9.57 6.11 8.3C5.98 7.84 5.6 7.5 5.13 7.5C4.48 7.5 3.99 8.12 4.16 8.75Z"
			/>
		</svg>
	);
}

export function IconArrowDropdown() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.70663 11.4136L11.2966 14.0036C11.6866 14.3936 12.3166 14.3936 12.7066 14.0036L15.2966 11.4136C15.9266 10.7836 15.4766 9.70361 14.5866 9.70361H9.40663C8.51663 9.70361 8.07663 10.7836 8.70663 11.4136Z"
				fill="#C3A083"
			/>
		</svg>
	);
}

export function IconArrowDropUp() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.70663 12.5861L11.2966 9.99611C11.6866 9.60611 12.3166 9.60611 12.7066 9.99611L15.2966 12.5861C15.9266 13.2161 15.4766 14.2961 14.5866 14.2961H9.40663C8.51663 14.2961 8.07663 13.2161 8.70663 12.5861Z"
				fill="#C3A083"
			/>
		</svg>
	);
}

export function IconCarte() {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5547 1.4865L13.8614 9.4665C14.1414 10.1398 13.8147 10.9198 13.1347 11.1998L8.22804 13.2332C8.06137 13.2998 7.88804 13.3332 7.71471 13.3332C7.19471 13.3265 6.70137 13.0198 6.49471 12.5132L3.18804 4.53317C3.11471 4.35984 3.08137 4.17984 3.08804 3.99984C3.10137 3.4865 3.40804 3.0065 3.90804 2.79984L8.82137 0.766504C8.99471 0.693171 9.17471 0.666504 9.34804 0.666504C9.86137 0.679837 10.348 0.986504 10.5547 1.4865ZM1.75471 12.3065L0.861375 11.9332C0.188041 11.6532 -0.138625 10.8732 0.134708 10.1932L1.75471 6.2865V12.3065ZM4.42804 4.6665C4.06137 4.6665 3.76137 4.3665 3.76137 3.99984C3.76137 3.63317 4.06137 3.33317 4.42804 3.33317C4.79471 3.33317 5.09471 3.63317 5.09471 3.99984C5.09471 4.3665 4.79471 4.6665 4.42804 4.6665ZM4.42804 13.3332C3.69471 13.3332 3.09471 12.7332 3.09471 11.9998V7.77317L5.39471 13.3332H4.42804Z"
				fill="#F2BA8C"
			/>
		</svg>
	);
}

export function IconSearch() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.81379 14.4713H8.02379L3.78379 18.7313C3.37379 19.1413 3.37379 19.8113 3.78379 20.2213C4.19379 20.6313 4.86379 20.6313 5.27379 20.2213L9.52379 15.9713V15.1813L9.79379 14.9013C11.1938 16.1013 13.1038 16.7213 15.1338 16.3813C17.9138 15.9113 20.1338 13.5913 20.4738 10.7913C20.9938 6.56133 17.4338 3.00133 13.2038 3.52133C10.4038 3.86133 8.08379 6.08133 7.61379 8.86133C7.27379 10.8913 7.89379 12.8013 9.09379 14.2013L8.81379 14.4713ZM18.5238 9.97133C18.5238 12.4613 16.5138 14.4713 14.0238 14.4713C11.5338 14.4713 9.52379 12.4613 9.52379 9.97133C9.52379 7.48133 11.5338 5.47133 14.0238 5.47133C16.5138 5.47133 18.5238 7.48133 18.5238 9.97133Z"
				fill="#CCCAC8"
			/>
		</svg>
	);
}

export function IconBook() {
	return (
		<svg
			width="16"
			height="20"
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2 0H14C15.1 0 16 0.9 16 2V18C16 19.1 15.1 20 14 20H2C0.9 20 0 19.1 0 18V2C0 0.9 0.9 0 2 0ZM7 2H2V10L4.5 8.5L7 10V2Z"
				fill="white"
			/>
		</svg>
	);
}
export function IconImage() {
	return (
		<svg
			width="38"
			height="30"
			viewBox="0 0 38 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.00033 0H34.0003C35.667 0 37.3337 1.66667 37.3337 3.33333V26.6667C37.3337 28.3333 35.667 30 34.0003 30H4.00033C2.16699 30 0.666992 28.5 0.666992 26.6667V3.33333C0.666992 1.66667 2.33366 0 4.00033 0ZM12.5337 16.65L8.38366 21.9833C7.95033 22.5333 8.35033 23.3333 9.03366 23.35H29.017C29.7003 23.35 30.1003 22.5667 29.6837 22.0167L23.8337 14.2167C23.5003 13.7667 22.8337 13.7667 22.5003 14.2L17.3337 20.85L13.8337 16.6333C13.5003 16.2167 12.867 16.2333 12.5337 16.65Z"
				fill="#CCCAC8"
			/>
		</svg>
	);
}

export function IconEuro() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.3223 15.5C9.81227 15.5 7.64227 14.08 6.56227 12H11.3223C11.8723 12 12.3223 11.55 12.3223 11C12.3223 10.45 11.8723 10 11.3223 10H5.90227C5.85227 9.67 5.82227 9.34 5.82227 9C5.82227 8.66 5.85227 8.33 5.90227 8H11.3223C11.8723 8 12.3223 7.55 12.3223 7C12.3223 6.45 11.8723 6 11.3223 6H6.56227C7.64227 3.92 9.82227 2.5 12.3223 2.5C13.5723 2.5 14.7423 2.86 15.7423 3.47C16.2423 3.78 16.8923 3.73 17.3123 3.31C17.8923 2.73 17.7623 1.78 17.0623 1.35C15.6823 0.5 14.0523 0 12.3223 0C8.40227 0 5.08227 2.51 3.84227 6H1.32227C0.772266 6 0.322266 6.45 0.322266 7C0.322266 7.55 0.772266 8 1.32227 8H3.38227C3.34227 8.33 3.32227 8.66 3.32227 9C3.32227 9.34 3.34227 9.67 3.38227 10H1.32227C0.772266 10 0.322266 10.45 0.322266 11C0.322266 11.55 0.772266 12 1.32227 12H3.84227C5.08227 15.49 8.40227 18 12.3223 18C14.0623 18 15.6823 17.51 17.0623 16.65C17.7523 16.22 17.8823 15.26 17.3023 14.68C16.8823 14.26 16.2323 14.21 15.7323 14.53C14.7423 15.15 13.5823 15.5 12.3223 15.5Z"
				fill="#CCCAC8"
			/>
		</svg>
	);
}

export function IconDeleteLarge() {
	return (
		<svg
			width="34"
			height="42"
			viewBox="0 0 34 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.166 2.33333L30.9994 2.33333C32.2827 2.33333 33.3327 3.38333 33.3327 4.66667C33.3327 5.95 32.2827 7 30.9994 7L2.99935 7C1.71602 7 0.666016 5.95 0.666016 4.66667C0.666016 3.38333 1.71602 2.33333 2.99935 2.33333L8.83268 2.33333L10.4893 0.676667C10.9093 0.256667 11.516 0 12.1227 0L21.876 0C22.4827 0 23.0893 0.256667 23.5093 0.676667L25.166 2.33333ZM7.66602 42C5.09935 42 2.99935 39.9 2.99935 37.3333L2.99935 14C2.99935 11.4333 5.09935 9.33333 7.66602 9.33333L26.3327 9.33333C28.8994 9.33333 30.9994 11.4333 30.9994 14L30.9994 37.3333C30.9994 39.9 28.8994 42 26.3327 42L7.66602 42Z"
				fill="#C22A29"
			/>
		</svg>
	);
}

export function IconStoreLarge() {
	return (
		<svg
			width="44"
			height="38"
			viewBox="0 0 44 38"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.66586 4.99967L38.3325 4.99967C39.6159 4.99967 40.6659 3.94967 40.6659 2.66634C40.6659 1.38301 39.6159 0.333008 38.3325 0.333008L5.66586 0.333008C4.38253 0.333008 3.33253 1.38301 3.33253 2.66634C3.33253 3.94967 4.38253 4.99967 5.66586 4.99967ZM41.0392 9.19967C40.8292 8.12634 39.8725 7.33301 38.7525 7.33301L5.24586 7.33301C4.12586 7.33301 3.1692 8.12634 2.9592 9.19967L0.625862 20.8663C0.345862 22.313 1.44253 23.6663 2.91253 23.6663H3.33253L3.33253 35.333C3.33253 36.6163 4.38253 37.6663 5.66586 37.6663L24.3325 37.6663C25.6159 37.6663 26.6659 36.6163 26.6659 35.333L26.6659 23.6663L35.9992 23.6663V35.333C35.9992 36.6163 37.0492 37.6663 38.3325 37.6663C39.6159 37.6663 40.6659 36.6163 40.6659 35.333V23.6663H41.0859C42.5559 23.6663 43.6525 22.313 43.3725 20.8663L41.0392 9.19967ZM7.9992 32.9997H21.9992V23.6663L7.9992 23.6663L7.9992 32.9997Z"
				fill="#C3A083"
			/>
		</svg>
	);
}

export function IconDissatisfied() {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.667969 23.9998C0.667969 11.0965 11.098 0.666504 23.978 0.666504C36.8813 0.666504 47.3346 11.0965 47.3346 23.9998C47.3346 36.9032 36.858 47.3332 23.978 47.3332C11.098 47.3332 0.667969 36.9032 0.667969 23.9998ZM15.4846 22.7632L16.7213 21.5265L17.958 22.7632C18.6346 23.4398 19.7546 23.4398 20.4313 22.7632C21.108 22.0865 21.108 20.9665 20.4313 20.2898L19.1946 19.0532L20.4313 17.8165C21.108 17.1398 21.108 16.0198 20.4313 15.3432C19.7546 14.6665 18.6346 14.6665 17.958 15.3432L16.7213 16.5798L15.4846 15.3432C14.808 14.6665 13.688 14.6665 13.0113 15.3432C12.3346 16.0198 12.3346 17.1398 13.0113 17.8165L14.248 19.0532L13.0113 20.2898C12.3346 20.9665 12.3346 22.0865 13.0113 22.7632C13.688 23.4398 14.808 23.4398 15.4846 22.7632ZM24.0013 27.4998C19.2646 27.4998 15.1346 30.0898 12.918 33.9165C12.4746 34.6865 13.058 35.6665 13.9446 35.6665L34.058 35.6665C34.9446 35.6665 35.528 34.6865 35.0846 33.9165C32.868 30.0898 28.738 27.4998 24.0013 27.4998ZM24.0013 42.6665C13.688 42.6665 5.33464 34.3132 5.33464 23.9998C5.33464 13.6865 13.688 5.33317 24.0013 5.33317C34.3146 5.33317 42.668 13.6865 42.668 23.9998C42.668 34.3132 34.3146 42.6665 24.0013 42.6665ZM31.2813 16.5798L32.518 15.3432C33.1946 14.6665 34.3146 14.6665 34.9913 15.3432C35.668 16.0198 35.668 17.1398 34.9913 17.8165L33.7546 19.0532L34.9913 20.2898C35.668 20.9665 35.668 22.0865 34.9913 22.7632C34.3146 23.4398 33.1946 23.4398 32.518 22.7632L31.2813 21.5265L30.0446 22.7632C29.368 23.4398 28.248 23.4398 27.5713 22.7632C26.8946 22.0865 26.8946 20.9665 27.5713 20.2898L28.808 19.0532L27.5713 17.8165C26.8946 17.1398 26.8946 16.0198 27.5713 15.3432C28.248 14.6665 29.368 14.6665 30.0446 15.3432L31.2813 16.5798Z"
				fill="#C22A29"
			/>
		</svg>
	);
}

export function IconLock() {
	return (
		<svg
			width="18"
			height="20"
			viewBox="0 0 18 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5227 5.39601V6.92935C16.2451 7.46696 17.5 9.02614 17.5 10.8884V15.8253C17.5 18.1308 15.5886 20 13.2322 20H4.7688C2.41136 20 0.5 18.1308 0.5 15.8253V10.8884C0.5 9.02614 1.75595 7.46696 3.47729 6.92935V5.39601C3.48745 2.41479 5.95667 0 8.98476 0C12.0535 0 14.5227 2.41479 14.5227 5.39601ZM9.00508 1.73904C11.0678 1.73904 12.7445 3.37871 12.7445 5.39601V6.7137H5.25553V5.37613C5.26569 3.36878 6.94232 1.73904 9.00508 1.73904ZM9.88912 14.4549C9.88912 14.9419 9.49283 15.3294 8.99492 15.3294C8.50717 15.3294 8.11088 14.9419 8.11088 14.4549V12.2488C8.11088 11.7718 8.50717 11.3843 8.99492 11.3843C9.49283 11.3843 9.88912 11.7718 9.88912 12.2488V14.4549Z"
				fill="#C4C4C4"
			/>
		</svg>
	);
}

export function IconBriefCase() {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.4081 3.04105C16.0676 3.04105 14.9271 3.9726 14.607 5.22817H25.3713C25.0512 3.9726 23.9107 3.04105 22.5702 3.04105H17.4081ZM28.4114 5.22478H32.373C36.5747 5.22478 39.9961 8.68772 39.9961 12.9405C39.9961 12.9405 39.8761 14.7408 39.836 17.2479C39.832 17.4463 39.736 17.6407 39.5779 17.7582C38.6156 18.469 37.7352 19.0563 37.6552 19.0968C34.3338 21.3244 30.4743 22.8918 26.3626 23.6715C26.0945 23.7242 25.8304 23.5844 25.6944 23.3455C24.5419 21.3487 22.389 20.0486 19.9881 20.0486C17.6031 20.0486 15.4302 21.3345 14.2437 23.3333C14.1057 23.5682 13.8456 23.7039 13.5795 23.6533C9.50183 22.8716 5.64227 21.3062 2.34094 19.117L0.420169 17.7805C0.260105 17.6792 0.160064 17.4969 0.160064 17.2944C0.10004 16.2616 0 12.9405 0 12.9405C0 8.68772 3.42138 5.22478 7.62307 5.22478H11.5647C11.9448 2.28838 14.4058 0 17.407 0H22.5691C25.5703 0 28.0313 2.28838 28.4114 5.22478ZM39.3148 21.6305L39.2347 21.671C35.1931 24.3847 30.3312 26.187 25.2291 26.9363C24.5088 27.0376 23.7885 26.5718 23.5884 25.8428C23.1483 24.1822 21.7277 23.0886 20.027 23.0886H20.007H19.967C18.2663 23.0886 16.8457 24.1822 16.4055 25.8428C16.2055 26.5718 15.4852 27.0376 14.7649 26.9363C9.66283 26.187 4.80087 24.3847 0.75924 21.671C0.739232 21.6508 0.539152 21.5293 0.379087 21.6305C0.199014 21.7318 0.199014 21.9748 0.199014 21.9748L0.339071 32.3029C0.339071 36.5556 3.74044 39.9983 7.94213 39.9983H32.0318C36.2335 39.9983 39.6349 36.5556 39.6349 32.3029L39.795 21.9748C39.795 21.9748 39.795 21.7318 39.6149 21.6305C39.5149 21.5698 39.3948 21.59 39.3148 21.6305ZM21.4875 30.1159C21.4875 30.9664 20.8272 31.6347 19.9869 31.6347C19.1666 31.6347 18.4863 30.9664 18.4863 30.1159V27.5035C18.4863 26.6732 19.1666 25.9846 19.9869 25.9846C20.8272 25.9846 21.4875 26.6732 21.4875 27.5035V30.1159Z"
				fill="#F2BA8C"
			/>
		</svg>
	);
}

export function IconBoldGame() {
	return (
		<svg
			width="52"
			height="55"
			viewBox="0 0 52 55"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.2484 13.9417C32.9185 13.9516 33.5902 13.9615 34.2642 13.969C41.5061 13.969 46.7863 19.5325 46.7863 27.2156V37.0843C46.7863 44.7674 41.5061 50.331 34.2642 50.331C31.3632 50.3972 28.4623 50.4193 25.5405 50.4193C22.6187 50.4193 19.676 50.3972 16.775 50.331C9.53307 50.331 4.25293 44.7674 4.25293 37.0843V27.2156C4.25293 19.5325 9.53308 13.969 16.7959 13.969C19.5299 13.9248 22.3265 13.8807 25.1648 13.8807V13.5053C25.1648 11.982 23.9752 10.7456 22.556 10.7456H20.4899C18.1316 10.7456 16.2115 8.71447 16.2115 6.24177C16.2115 5.33658 16.9211 4.58594 17.7768 4.58594C18.6533 4.58594 19.342 5.33658 19.342 6.24177C19.342 6.9041 19.8638 7.43396 20.4899 7.43396H22.556C25.7074 7.45604 28.2745 10.1716 28.2953 13.4833V13.9027C29.611 13.9027 30.9267 13.9222 32.2484 13.9417ZM23.0779 33.7949H20.8866V36.1351C20.8866 37.0403 20.177 37.791 19.3213 37.791C18.4447 37.791 17.756 37.0403 17.756 36.1351V33.7949H15.5438C14.6881 33.7949 13.9785 33.0663 13.9785 32.1391C13.9785 31.2339 14.6881 30.4832 15.5438 30.4832H17.756V28.1651C17.756 27.2599 18.4447 26.5093 19.3213 26.5093C20.177 26.5093 20.8866 27.2599 20.8866 28.1651V30.4832H23.0779C23.9336 30.4832 24.6432 31.2339 24.6432 32.1391C24.6432 33.0663 23.9336 33.7949 23.0779 33.7949ZM31.9486 30.0618H32.1573C33.013 30.0618 33.7226 29.3333 33.7226 28.406C33.7226 27.5008 33.013 26.7502 32.1573 26.7502H31.9486C31.0721 26.7502 30.3834 27.5008 30.3834 28.406C30.3834 29.3333 31.0721 30.0618 31.9486 30.0618ZM35.5166 37.6547H35.7253C36.581 37.6547 37.2906 36.9261 37.2906 35.9989C37.2906 35.0937 36.581 34.343 35.7253 34.343H35.5166C34.638 34.343 33.9513 35.0937 33.9513 35.9989C33.9513 36.9261 34.638 37.6547 35.5166 37.6547Z"
				fill="#E97D24"
			/>
		</svg>
	);
}

export function IconDelete() {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0178 2.23036C12.2864 2.23036 12.5101 2.4311 12.5101 2.68654V2.92271C12.5101 3.17193 12.2864 3.37889 12.0178 3.37889H0.573034C0.303721 3.37889 0.0800781 3.17193 0.0800781 2.92271V2.68654C0.0800781 2.4311 0.303721 2.23036 0.573034 2.23036H2.58649C2.9955 2.23036 3.35145 1.96871 3.44346 1.59954L3.5489 1.17568C3.71277 0.598308 4.25207 0.214844 4.86927 0.214844H7.72089C8.33137 0.214844 8.87671 0.598308 9.03454 1.14523L9.14737 1.59892C9.23871 1.96871 9.59466 2.23036 10.0043 2.23036H12.0178ZM10.9947 10.8631C11.2049 9.09992 11.573 4.91103 11.573 4.86877C11.5864 4.74074 11.5401 4.61955 11.4481 4.52197C11.3493 4.43061 11.2244 4.37654 11.0867 4.37654H1.50836C1.37001 4.37654 1.23838 4.43061 1.14704 4.52197C1.05436 4.61955 1.00869 4.74074 1.01541 4.86877C1.01664 4.87653 1.02985 5.02408 1.05193 5.27077C1.15001 6.36663 1.42318 9.41882 1.5997 10.8631C1.72462 11.9271 2.50032 12.5958 3.62391 12.6201C4.49094 12.6381 5.38417 12.6443 6.29755 12.6443C7.15787 12.6443 8.03163 12.6381 8.92553 12.6201C10.0881 12.6021 10.8631 11.9451 10.9947 10.8631Z"
				fill="#FA1414"
			/>
		</svg>
	);
}

export function IconEdit() {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.48333 11.9736L10.5508 4.12714C10.8806 3.70402 10.9978 3.21482 10.8879 2.71672C10.7926 2.2639 10.5142 1.83335 10.0965 1.50672L9.07792 0.697586C8.19124 -0.00762604 7.09206 0.0666068 6.46186 0.875745L5.78037 1.75986C5.69244 1.87047 5.71442 2.03378 5.82434 2.12286C5.82434 2.12286 7.54639 3.50359 7.58303 3.53328C7.70027 3.64463 7.78821 3.7931 7.81019 3.97125C7.84683 4.32015 7.60501 4.64677 7.24595 4.69131C7.0774 4.71358 6.91619 4.66162 6.79895 4.56512L4.98896 3.125C4.90102 3.05893 4.76912 3.07304 4.69584 3.16212L0.394376 8.72958C0.115917 9.07847 0.0206544 9.5313 0.115917 9.96927L0.665508 12.3521C0.69482 12.4783 0.804738 12.5674 0.93664 12.5674L3.35484 12.5377C3.79451 12.5303 4.20487 12.3299 4.48333 11.9736ZM7.86927 11.2329H11.8124C12.1971 11.2329 12.51 11.5498 12.51 11.9396C12.51 12.33 12.1971 12.6463 11.8124 12.6463H7.86927C7.48456 12.6463 7.17166 12.33 7.17166 11.9396C7.17166 11.5498 7.48456 11.2329 7.86927 11.2329Z"
				fill="#E97D24"
			/>
		</svg>
	);
}

export function IconCheck() {
	return (
		<svg
			width="16"
			height="12"
			viewBox="0 0 16 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.5 9.47656L14.3281 0.648438L15.5 1.82031L5.5 11.8203L0.851562 7.17188L2.02344 6L5.5 9.47656Z"
				fill="#F2BA8C"
			/>
		</svg>
	);
}
export function IconClose() {
	return (
		<svg
			width="10"
			height="10"
			viewBox="0 0 10 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.8"
				d="M5.91615 4.99993L9.80995 8.89392C10.0634 9.14721 10.0634 9.55675 9.80995 9.81003C9.55667 10.0633 9.14714 10.0633 8.89386 9.81003L4.99994 5.91604L1.10614 9.81003C0.85274 10.0633 0.443335 10.0633 0.190051 9.81003C-0.0633505 9.55675 -0.0633505 9.14721 0.190051 8.89392L4.08385 4.99993L0.190051 1.10593C-0.0633505 0.852639 -0.0633505 0.443107 0.190051 0.189818C0.316278 0.0634708 0.482246 0 0.648097 0C0.813947 0 0.979797 0.0634708 1.10614 0.189818L4.99994 4.08382L8.89386 0.189818C9.0202 0.0634708 9.18605 0 9.3519 0C9.51775 0 9.6836 0.0634708 9.80995 0.189818C10.0634 0.443107 10.0634 0.852639 9.80995 1.10593L5.91615 4.99993Z"
				fill="#EB5757"
			/>
		</svg>
	);
}
export function IconEuroOrange() {
	return (
		<svg
			width="23"
			height="28"
			viewBox="0 0 23 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.368 34.368C20.3287 34.69 18.3737 34.6517 16.503 34.253C14.6323 33.8543 12.915 33.1567 11.351 32.16C9.787 31.148 8.43 29.8907 7.28 28.388C6.13 26.8853 5.24067 25.1833 4.612 23.282H0.84V18.682H3.784V17.21H0.84V12.61H4.612C5.21 10.678 6.084 8.953 7.234 7.435C8.39933 5.917 9.764 4.652 11.328 3.64C12.9073 2.628 14.6323 1.93033 16.503 1.547C18.3737 1.14833 20.3287 1.11 22.368 1.432V8.562C21.2947 8.22467 20.252 8.079 19.24 8.125C18.2433 8.171 17.3003 8.38567 16.411 8.769C15.5217 9.137 14.709 9.65066 13.973 10.31C13.2523 10.954 12.6313 11.7207 12.11 12.61H17.584V17.21H10.684L10.73 18.682H17.584V23.282H12.156C12.708 24.1867 13.352 24.961 14.088 25.605C14.8393 26.249 15.6597 26.7473 16.549 27.1C17.4383 27.4527 18.3737 27.6443 19.355 27.675C20.3363 27.7057 21.3407 27.56 22.368 27.238V34.368Z"
				fill="#F2994A"
			/>
		</svg>
	);
}

export function IconNote() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 5.75C7.59 5.75 7.25 5.41 7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75Z"
				fill="#F2BA8C"
			/>
			<path
				d="M16 5.75C15.59 5.75 15.25 5.41 15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75Z"
				fill="#F2BA8C"
			/>
			<path
				d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75ZM8 4.25C5.14 4.25 3.75 5.64 3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8Z"
				fill="#F2BA8C"
			/>
			<path
				d="M16 11.75H8C7.59 11.75 7.25 11.41 7.25 11C7.25 10.59 7.59 10.25 8 10.25H16C16.41 10.25 16.75 10.59 16.75 11C16.75 11.41 16.41 11.75 16 11.75Z"
				fill="#F2BA8C"
			/>
			<path
				d="M12 16.75H8C7.59 16.75 7.25 16.41 7.25 16C7.25 15.59 7.59 15.25 8 15.25H12C12.41 15.25 12.75 15.59 12.75 16C12.75 16.41 12.41 16.75 12 16.75Z"
				fill="#F2BA8C"
			/>
		</svg>
	);
}
