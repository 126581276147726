import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { IEtat } from "../../../../utils/api/etat/etat.type";
import { FormModalProps } from "../../../../utils/type";
import { ButtonSubmit } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import useCrudEtat from "../../admin/Parametres/useParametreForm/useCrudEtat";

const AddEtatModal = ({ item, isOpen, closeModal }: FormModalProps<IEtat>) => {
	const { register, onSubmit, errors, isLoading, reset } = useCrudEtat(closeModal, item);
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-header">
				<h1 className="modal-title fs-5 fas-admin-modal-title" id="AddEtatModalLabel">
					{item ? "Modifier la" : "Ajouter un"} état
				</h1>
				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={() => {
						closeModal();
						reset();
					}}
				></button>
			</div>
			<ModalBody>
				<form onSubmit={onSubmit}>
					<InputAdmin
						label=""
						placeholder="Etat du vêtement"
						type="text"
						{...register("nom")}
						error={errors.nom}
						defaultValue={item?.nom}
					/>
					<div className="flex-c py-2">
						<ButtonSubmit label="ENREGISTRER" isLoading={isLoading} />
					</div>
				</form>
			</ModalBody>
		</Modal>
	);
};

export default AddEtatModal;
