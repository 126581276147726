import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from "../../../../common/AdminPagination";
import ListeAbonnesData from "./ListeAbonnesData";
import ListeAbonnesTableSkeleton from "./ListeAbonnesTableSkeleton";

function ListeAbonnesTable() {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(5);
	const [isLoading, setIsLoading] = useState(true);

	const actionFormatter: any = (cell: any, row: any) => {
		return (
			<div className="gap-1 d-flex">
				<button className="abonnes-btn-action btn-suspendre">Suspendre</button>
				<button className="abonnes-btn-action btn-abonne-delete">Supprimer</button>
			</div>
		);
	};

	const columns = [
		{
			dataField: "name",
			text: "Nom et Prenom",
		},
		{
			dataField: "date_debut",
			text: "Date de debut",
		},
		{
			dataField: "date_fin",
			text: "Date de fin",
		},
		{
			dataField: "statut",
			text: "Statut",
		},
		{
			dataField: "actions",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(false);
		}, 0);
	}, []);

	return (
		<>
			{isLoading && <ListeAbonnesTableSkeleton />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={ListeAbonnesData}
						columns={columns}
						bordered={false}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table abonnes-table"
					/>
					<CustomPagination
						nbPages={ListeAbonnesData.length}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
		</>
	);
}

export default ListeAbonnesTable;

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-end">
			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
