import React from "react";
import { IPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { DimensionsUnit, WeightUnit } from "../../../../../utils/constants";
import Collapse from "../../../../common/Collapse";
import { CustomInput } from "../../../../common/Input";
import { OptionalMessage } from "../../../../common/RequiredMessage";
import { Select as CustomSelect } from "../../../../common/Select";

const DimensionsPepites: React.FC<IPepiteProps> = ({ register, errors }) => {
	return (
		<Collapse id="dimensions" title="Dimensions et poids (Option facultative)">
			<OptionalMessage />
			<div className="row">
				<div className="col-md-6 mb-3">
					<CustomSelect
						label="Poids"
						firstOption="Choisissez une unité"
						id="weight_unit_etsy"
						options={WeightUnit}
						{...register("weight_unit_etsy")}
						error={errors?.weight_unit_etsy}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<CustomSelect
						label="Dimensions"
						firstOption="Choisissez une unité"
						id="dimensions_unit_etsy"
						options={DimensionsUnit}
						{...register("dimensions_unit_etsy")}
						error={errors?.dimensions_unit_etsy}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<CustomInput
						label="Poids"
						type="text"
						id="weight"
						firstLabel="Poids"
						{...register("weight")}
						error={errors?.weight}
					/>
				</div>

				<div className="col-md-6 mb-3">
					<CustomInput
						label="Hauteur"
						type="text"
						id="height"
						firstLabel="Hauteur"
						{...register("height")}
						error={errors?.height}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<CustomInput
						label="Longueur"
						type="text"
						id="length"
						firstLabel="Longueur"
						{...register("length")}
						error={errors?.length}
					/>
				</div>
				<div className="col-md-6 mb-3">
					<CustomInput
						label="Largeur"
						type="text"
						id="width"
						firstLabel="Largeur"
						{...register("width")}
						error={errors?.width}
					/>
				</div>
			</div>
		</Collapse>
	);
};

export default DimensionsPepites;
