import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { useDiffuserPepiteMutation } from "../../../../../utils/api/pepite/pepite.api";
import { IPepite, TDiffusionFormData } from "../../../../../utils/api/pepite/pepite.type";
import { ApiBaseUrl, EBAY_REDIRECT_URL } from "../../../../../utils/http";
import { ORIGIN } from "../../../../../utils/constants";
import { TypeMarketplace } from "../../../../../utils/api/marketplace/marketplace.type";
import { useAppSelector } from "../../../../../redux/hooks";

function useDiffusion(item?: IPepite) {
	const validationSchema = yup.object().shape({
		marketplace: yup.string().required().label("La marketplace"),
	});
	const { user } = useAppSelector((s) => s?.user);
	const ETSY_REDIRECT_URL = `https://www.etsy.com/oauth/connect?response_type=code&redirect_uri=${ApiBaseUrl}/api/etsy/user_accces_token/&scope=transactions_r%20transactions_w%20listings_w%20listings_r%20shops_r%20shops_w%20profile_r%20profile_w%20listings_d&client_id=hwk0z527fjhfg8w12h0bw217&state=${user?.slug}&code_challenge=DSWlW2Abh-cf8CeLL8-g3hQ2WQyYdKyiu83u_s7nRhI&code_challenge_method=S256`;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<TDiffusionFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [difuserPepite, { isLoading }] = useDiffuserPepiteMutation();
	const [checked, setChecked] = useState<string>();

	const handleChecked = (e: React.ChangeEvent<HTMLInputElement>, tokenStatus?: number) => {
		const selectedMarket = e.currentTarget.value;
		console.log(e.currentTarget.value);
		if (e?.currentTarget?.value && tokenStatus !== 200) {
			return Swal.fire({
				title: `Votre token pour cette markeplace a expiré, voulez-vous le mettre à jour `,
				icon: "info",
				showCancelButton: true,
				confirmButtonText: "Oui",
				cancelButtonText: "Non",
				showLoaderOnConfirm: true,
				confirmButtonColor: Color.primary,
			}).then((result) => {
				console.log({ result });
				if (result.isConfirmed) {
					if (selectedMarket === TypeMarketplace.etsy) {
						return window.open(ETSY_REDIRECT_URL, "_self");
					}
					if (selectedMarket === TypeMarketplace.ebay)
						return window.open(EBAY_REDIRECT_URL, "_self");
				}
			});
		}
		if (e.currentTarget.value) {
			setChecked(e.currentTarget.value);
			setValue("marketplace", e.currentTarget.value);
		}
	};
	const cleannerChecked = () => {
		setChecked("");
		setValue("marketplace", "");
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: TDiffusionFormData) => {
		// console.log({ data });
		const newData = {
			slug: item?.slug,
			marketplace: data?.marketplace,
			env: ORIGIN,
			page: "user/mon-stock",
		};
		// console.log({ newData });
		const res = await difuserPepite(newData);
		if ("data" in res) {
			const url = res?.data?.url;
			const data = res?.data as any;
			if (url) {
				// window.location.href = url;
				window.open(url, "_self");
			} else {
				console.log({ res });
				Swal.fire({
					icon: "success",
					title: "🎉 Pépite en ligne 🎉",
					text: `Votre pépite vient d’être mise en ligne sur les marketplaces${
						data?.status_image === 400
							? " par contre les images n'ont pas étés diffusés"
							: ""
					}.`,
					iconColor: Color.success,
					showConfirmButton: true,
					confirmButtonText: "Fermer",
					customClass: {
						confirmButton: "btn-swal-close mx-4",
						container: "validation__step",
						title: "swal__success__title",
					},
				}).then(() => {
					reset();
					setChecked("");
					onHide("DiffuserModalPepite");
				});
			}
		} else if ("error" in res) {
			const err = res.error as any;
			console.log("err", err);

			if (err?.status === 401 && checked === "ebay") {
				// return console.log({ err });
				window.open(EBAY_REDIRECT_URL, "_self");
			} else if (
				(err?.status === 401 && checked === "etsy") ||
				(err?.status === 401 &&
					checked === "etsy" &&
					JSON.parse(err?.data?.message[0])?.error?.includes("invalid_token"))
			) {
				return window.open(ETSY_REDIRECT_URL, "_self");
			} else if (err?.data?.message?.includes("Ce produit est déja diffusé")) {
				Swal.fire({
					icon: "error",
					title: err?.data?.message,
					showConfirmButton: false,
					timer: 5000,
				});
			} else if (JSON.parse(err?.data?.message)?.error?.includes("greater than 0")) {
				Swal.fire({
					icon: "error",
					title: "les dimensions du produit sont invalides",
					showConfirmButton: false,
					timer: 5000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur a survenue lors de la diffusion`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		checked,
		handleChecked,
		setChecked,
		cleannerChecked,
	};
}

export default useDiffusion;
