import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { TailleFormData, ITaille } from "./taille.type";

export const TailleApi = createApi({
	reducerPath: "tailleAPi",
	tagTypes: ["taille"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getTaille: builder.query<PaginationResults<ITaille>, void>({
			query: () => `tailles/`,
			providesTags: ["taille"],
		}),
		addOrUpdateTaille: builder.mutation<
			ITaille,
			{ slug?: string; data: TailleFormData | FormData }
		>({
			invalidatesTags: ["taille"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `tailles/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `tailles/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteTaille: builder.mutation<ITaille, string>({
			query: (slug) => ({
				url: `tailles/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["taille"],
		}),
	}),
});

export const {
	useGetTailleQuery,
	useLazyGetTailleQuery,
	useAddOrUpdateTailleMutation,
	useDeleteTailleMutation,
} = TailleApi;
