import React from "react";
import { Outlet } from "react-router-dom";
import SideBarUser from "./SideBar/SidebarAdmin";
import TopBar from "./TopBar/TopBar";
import "./AdminLayout.css";
import LogoutModal from "../../modals/user/LogoutModal";

const AdminLayout = () => {
	return (
		<div className="layout__dashboard  admin-layout">
			<TopBar />
			<SideBarUser />
			<div className="d-lg-flex justify-content-lg-end">
				<div className="outlet__admin__container pb-5" style={{ paddingTop: "4rem" }}>
					<Outlet />
					<LogoutModal />
				</div>
			</div>
		</div>
	);
};

export function AdminCard({
	children,
	marginTop = true,
}: {
	children: React.ReactNode;
	marginTop?: boolean;
}) {
	return (
		<div className={`bg-white px-4 pt-4 rounded-10  pb-20 ${marginTop && "mt-5"}`}>
			{children}
		</div>
	);
}

export function AdminFasCard({ children }: { children: React.ReactNode }) {
	return (
		<div className="fashion__card px-xl-3 px-1 py-3 col-xl-10 col-md-9 mb-4">{children}</div>
	);
}

export default AdminLayout;
