import React from "react";
import { isAdmin } from "../../../../../routes/routerHelper";
import { IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { Spinner } from "../../../../common/Button";

const Valider: React.FC<IStepsCrudPepiteProps> = ({ onSubmit, isLoading, formValues, user }) => {
	return (
		<>
			<h2>Valider les modification de {isAdmin(user) ? "la" : "votre"} pépite</h2>
			{/* <p className="common_style color-red my-3">
				Vous n’avez pas effetué de modifications concernant votre pépite.
			</p> */}
			<div className="mt-3">
				{isAdmin(user) ? (
					<p style={{ color: "#CCCAC8", fontWeight: 500, fontSize: 16 }}>
						Si vous avez diffusé cette pépite, les modifications seront automatiquement
						mises à jour sur les marketplaces.
					</p>
				) : (
					<>
						{formValues?.en_ligne && (
							<div>
								<p style={{ color: "#CCCAC8", fontWeight: 500, fontSize: 16 }}>
									En validant les modifications de votre pépite, elle sera
									automatiquement mise à jour sur les marketplaces où elle est
									diffusée.
								</p>
							</div>
						)}
					</>
				)}

				<div className="flex-c pt-5">
					<button
						className="btn__primary w-50 fashion__btn py-3"
						type="button"
						onClick={onSubmit}
						disabled={isLoading}
					>
						{isLoading ? <Spinner /> : "Valider les modifications"}
					</button>
				</div>
			</div>
		</>
	);
};

export default Valider;
