import React from "react";
import { IStepsSignupProps } from "../../../../../utils/api/auth/auth.type";
import { BtnPrimary } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";

const InformationsPersonnelles: React.FC<IStepsSignupProps> = ({ register, onSubmit, errors, data }) => {
	return (
		<React.Fragment>
			<h2>Informations personnelles</h2>
			<form action="" className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="row">
					<div className="col-md-6">
						<Input
							label="Nom*"
							type="text"
							id="nom"
							{...register("nom")}
							error={errors?.nom}
						/>
					</div>
					<div className="col-md-6">
						<Input
							label="Prenom*"
							type="text"
							id="prenom"
							{...register("prenom")}
							error={errors?.prenom}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							label="Téléphone"
							type="tel"
							id="tel"
							{...register("telephone")}
							error={errors?.telephone}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<Input
							label="Adresse mail"
							value={data?.email}
							type="email"
							id="email"
							disabled
						/>
					</div>
				</div>
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</React.Fragment>
	);
};

export default InformationsPersonnelles;
