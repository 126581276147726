import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { EtatFormData, IEtat } from "./etat.type";

export const EtatApi = createApi({
	reducerPath: "etatAPi",
	tagTypes: ["etat"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getEtat: builder.query<PaginationResults<IEtat>, void>({
			query: () => `etat_vetements/`,
			providesTags: ["etat"],
		}),
		addOrUpdateEtat: builder.mutation<IEtat, { slug?: string; data: EtatFormData | FormData }>({
			invalidatesTags: ["etat"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `etat_vetements/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `etat_vetements/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteEtat: builder.mutation<IEtat, string>({
			query: (slug) => ({
				url: `etat_vetements/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["etat"],
		}),
	}),
});

export const {
	useGetEtatQuery,
	useLazyGetEtatQuery,
	useAddOrUpdateEtatMutation,
	useDeleteEtatMutation,
} = EtatApi;
