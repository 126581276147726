import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "./style/App.css";
import "./style/responsive.css";
import AppRoutes from "./routes/router";
import { useMeQuery } from "./utils/api/user/user.api";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { onSetUser } from "./redux/slice/User.slice";
import { useMeAdminQuery } from "./utils/api/admin/admin.api";
import { isAdmin } from "./routes/routerHelper";
import "volkeno-react-skeleton/dist/index.css";
import { useGetCategoryQuery } from "./utils/api/category/category.api";
import { useGetEtatQuery } from "./utils/api/etat/etat.api";
import { onSetCategory, onSetEtatPepite } from "./redux/slice/caracteristique.slice";

function App() {
	const routes = useRoutes(AppRoutes);
	const isConnected = useAppSelector((s) => !!s.user?.token);
	const is_admin = useAppSelector((s) => isAdmin(s.user?.user));
	return (
		<div className="App">
			{isConnected && !is_admin && <FetchUser />}
			{isConnected && !is_admin && <FetchCaraterisitiques />}
			{isConnected && is_admin && <FetchAdminUser />}
			{routes}
			<ScrollToTop />
		</div>
	);
}

function ScrollToTop() {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname, location.state]);
	return null;
}

export default App;

function FetchUser() {
	const { isSuccess, data } = useMeQuery();
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (isSuccess) {
			dispatch(onSetUser(data));
		}
	}, [isSuccess, data]);

	return null;
}

function FetchAdminUser() {
	const { isSuccess, data } = useMeAdminQuery();
	const dispatch = useAppDispatch();
	useEffect(() => {
		if (isSuccess) {
			dispatch(onSetUser(data));
		}
	}, [isSuccess, data]);

	return null;
}

function FetchCaraterisitiques() {
	const { data: categories } = useGetCategoryQuery({});
	const { data: etats } = useGetEtatQuery();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (categories) {
			dispatch(onSetCategory(categories?.results));
		}
	}, [categories]);
	useEffect(() => {
		if (etats) {
			dispatch(onSetEtatPepite(etats?.results));
		}
	}, [etats]);
	return null;
}
