import Pagination from "./AdminPagination";

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="d-flex align-items-baseline justify-content-between">
				<div className="footer-form d-flex mt-3">
					<select
						className="form-select form-control-lg form-control-admin"
						name="limit"
						id="limit"
						value={perPage}
						onChange={(e) => {
							onChange(1, parseInt(e.target.value, 10));
						}}
					>
						<option value="10">10</option>
						<option value="30">30</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
