import React from "react";
import { IShopifyProduct } from "../../../../utils/api/pepite/pepite.type";
import market from "../../../../assets/images/Diffusion/Shopify.png";

type Props = {
	product: IShopifyProduct;
	onToggleCheckedItem: (e: React.ChangeEvent<HTMLInputElement>) => void;
	arrayOfProductsId: number[];
};

const ShopifyProductItemCard = ({ product, onToggleCheckedItem, arrayOfProductsId }: Props) => {
	return (
		<>
			<div className="fashion__card px-1 py-2 row mb-3 pepite__item">
				<div className="col-md-6  position-relative">
					<div className="pepite-left-block d-md-flex gap-md-3">
						<div className="pepite_img_container">
							<img
								src={product?.image?.src}
								alt={product?.title}
								className="pepite__img mb-2"
							/>
						</div>
						<div className="pepites__infos flex-fill pe-3">
							<h4 className="pepite__title">{product?.title}</h4>
							<div className="flex-sb ">
								<div className="group-info">
									<h5 className="group_info_title">Référence</h5>
									<h6 className="group_info_value">{product?.id}</h6>
								</div>
								<div className="group-info">
									<h5 className="group_info_title">Taille</h5>
									<h6 className="group_info_value">
										{product?.options[0]?.values?.map((v, i) => (
											<span key={i}>{v}</span>
										))}
									</h6>
								</div>
								<div className="group-info">
									<h5 className="group_info_title">Prix</h5>
									<h6 className="group_info_value">
										{product?.variants[0]?.price}€
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-m-sb col-md-6">
					<div className="group-info">
						<div className="img-container">
							<img src={market} alt="shopify-logo" height="30px" width="100px" />
						</div>
					</div>
					<div className="flex-m gap-3">
						<input
							type="checkbox"
							name={product?.id}
							value={product?.id}
							className="form-check-input fas__check"
							style={{ cursor: "pointer" }}
							onChange={onToggleCheckedItem}
							checked={arrayOfProductsId?.includes(Number(product?.id))}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShopifyProductItemCard;
