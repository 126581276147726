import React, { ButtonHTMLAttributes } from "react";
import { BsFillEyeFill, BsPlus } from "react-icons/bs";
import { FiArrowLeft} from "react-icons/fi";
import { FaLockOpen, FaLock } from "react-icons/fa";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import { Color } from "../../utils/theme";
import { IconDelete, IconEdit, IconBook } from "../shared/Icons";

type BtnPropsType = {
	label: string;
	width?: string;
	isLoading?: boolean;
	isArchive?: boolean;
	mode?: "Primary" | "Secondary";
};

type ButtonActionProps = {
	path?: string;
};

export function BtnPrimary({
	isLoading = false,
	label,
	width,
	mode = "Primary",
	...rest
}: BtnPropsType & ButtonHTMLAttributes<HTMLButtonElement>) {
	return (
		<React.Fragment>
			{!isLoading ? (
				<button
					className={`${
						mode === "Primary" ? "btn__primary" : "btn__add"
					} fashion__btn ${width}`}
					{...rest}
				>
					{label}
				</button>
			) : (
				<button className={`btn__add fashion__btn ${width}`} disabled={true} {...rest}>
					<Spinner />
				</button>
			)}
		</React.Fragment>
	);
}

export function BtnBack({
	label = "Retour",
	color = Color.primary,
	fontSize = 12,
}: {
	label?: string;
} & React.CSSProperties) {
	const navigate = useNavigate();
	return (
		<button className="btn-back flex-m" onClick={() => navigate(-1)}>
			<FiArrowLeft style={{ color, fontSize }} />
			<span style={{ color }}>{label}</span>
		</button>
	);
}

export const ButtonDelete: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...rest }) => {
	return (
		<button className="action-btn-delete action-btn" {...rest} type="button">
			<IconDelete />
		</button>
	);
};

export const ButtonEdit: React.FC<ButtonActionProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	...rest
}) => {
	return (
		<button className="action-btn-edit action-btn " type="button" {...rest}>
			<IconEdit />
		</button>
	);
};

export const ButtonView: React.FC<ButtonActionProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	path,
	...rest
}) => {
	return (
		<>
			{path ? (
				<Link to={path} className="action-btn-view action-btn ">
					<BsFillEyeFill />
				</Link>
			) : (
				<button className="action-btn-view action-btn " type="button" {...rest}>
					<BsFillEyeFill />
				</button>
			)}
		</>
	);
};

export const ButtonAdd: React.FC<
	ButtonActionProps & ButtonHTMLAttributes<HTMLButtonElement> & { icon?: boolean; label: string }
> = ({ path, icon, label, ...rest }) => {
	return (
		<>
			{path ? (
				<Link to={path} className="admin-btn-add">
					{icon && <BsPlus style={{ fontSize: 20 }} />}
					{label}
				</Link>
			) : (
				<button className="admin-btn-add" type="button" {...rest}>
					{icon && <BsPlus style={{ fontSize: 20 }} />}
					{label}
				</button>
			)}
		</>
	);
};

export function ButtonSubmit({
	isLoading = false,
	label,
	width,
	...rest
}: BtnPropsType & ButtonHTMLAttributes<HTMLButtonElement>) {
	return (
		<React.Fragment>
			{!isLoading ? (
				<button className={`admin-btn-add ${width}`} {...rest}>
					{label}
				</button>
			) : (
				<button className={`admin-btn-add ${width}`} disabled={true} {...rest}>
					<Spinner />
				</button>
			)}
		</React.Fragment>
	);
}

export function ButtonArchive({
	isArchive,
	label,
	width,
	...rest
}: BtnPropsType & ButtonHTMLAttributes<HTMLButtonElement>) {
	return (
		<React.Fragment>
			{!isArchive ? (
				<button className={`action-btn-view action-btn ${width}`} {...rest}>
					<FaLock/>
				</button>
			) : (
				<button className={`action-btn-view action-btn ${width}`} {...rest}>
					<FaLockOpen/>
				</button>
			)}
		</React.Fragment>
	);
}

export const CloseModalButton = ({
	text,
	...rest
}: { text?: string } & ButtonHTMLAttributes<HTMLButtonElement>) => {
	return (
		<>
			{text ? (
				<button
					type="button"
					data-bs-dismiss="modal"
					style={{ fontWeight: 500, fontSize: 13 }}
					{...rest}
				>
					{text}
				</button>
			) : (
				<button className="no-style-btn" data-bs-dismiss="modal" type="button" {...rest}>
					<svg
						width="33"
						height="33"
						viewBox="0 0 33 33"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="16.5" cy="16.5" r="16.5" fill="#E4E6EB" />
						<path
							d="M21.8203 12.3516L17.1719 17L21.8203 21.6484L20.6484 22.8203L16 18.1719L11.3516 22.8203L10.1797 21.6484L14.8281 17L10.1797 12.3516L11.3516 11.1797L16 15.8281L20.6484 11.1797L21.8203 12.3516Z"
							fill="#666666"
						/>
					</svg>
				</button>
			)}
		</>
	);
};
export const ButtonEditLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link className="action-btn-edit action-btn " {...rest}>
			<IconEdit />
		</Link>
	);
};
export const ButtonViewLink: React.FC<LinkProps> = ({ ...rest }) => {
	return (
		<Link className="action-btn-view action-btn " {...rest}>
			<BsFillEyeFill />
		</Link>
	);
};
export const Spinner = ({ mode }: { mode?: string }) => {
	return (
		<>
			<div
				className={`spinner-border ${mode ? mode : "text-light"} spinner-border-sm me-1`}
				role="status"
			></div>
			<span>En cours...</span>
		</>
	);
};
