import React, { useState } from "react";
import { ButtonAdd, ButtonDelete, ButtonEdit } from "../../../common/Button";
import AddEditFaqModal from "../../modals/admin/AddEditFaqModal";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import { useGetFaqQuery, useDeleteFaqMutation } from "../../../../utils/api/faq/faq.api";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import { useDelete } from "../../../../utils/helpers";
import moment from "moment";
import { CustomPagination } from "../../../common/CustomPagination";

const GestionFaq = () => {
	const { data, isLoading } = useGetFaqQuery();
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [item, setItem] = useState<IFaq>();
	const openEditModal = (item: IFaq) => {
		setItem(item);
	};

	return (
		<div className="faq__page">
			<h1>FAQ</h1>
			<AdminCard>
				<div className="flex-end mb-5">
					<ButtonAdd
						label="Nouvelle question"
						icon={true}
						data-bs-toggle="modal"
						data-bs-target="#AddEditFaqModal"
						onClick={() => setItem(undefined)}
					/>
				</div>
				<div className="faq-cards-container">
					{!isLoading && (
						<>
							{data?.results.map((item, i) => (
								<FaqItem key={i} id={i} faq={item} openEditModal={openEditModal} />
							))}
							{data?.count && data?.count > perPage && (
								<CustomPagination
									nbPages={data?.count}
									page={page}
									onChange={(page, perPage) => {
										setPerPage(perPage);
										setPage(page);
									}}
									perPage={perPage}
								/>
							)}
						</>
					)}
				</div>
			</AdminCard>
			<AddEditFaqModal item={item} />
		</div>
	);
};

export function FaqItem({ id, faq, openEditModal }: { id: number; faq: IFaq; openEditModal: any }) {
	return (
		<div className="faq__card shadow px-3 py-4 mb-3" style={{ borderRadius: "10px" }}>
			<div className="flex-sb align-items-center">
				<div
					role="button"
					data-bs-toggle="collapse"
					data-bs-target={`#collapseFaq${id}`}
					aria-expanded="false"
					aria-controls="collapseFaq"
				>
					<h3 className="faq_item_title mb-2" style={{ color: "#824D21" }}>
						{faq?.titre}
					</h3>
					<h6 style={{ fontWeight: 400, lineHeight: "18px", fontSize: 14 }}>
						{moment(faq?.created_at).format("DD/MM/YYYY")}
					</h6>
				</div>
				<div>
					<h6 className="faq-total">{faq?.nb_consultation}</h6>
					<p className="faq-label-total">Consultations</p>
				</div>
				<div className="d-flex gap-3">
					<ButtonEdit
						onClick={() => openEditModal(faq)}
						data-bs-toggle="modal"
						data-bs-target="#AddEditFaqModal"
					/>
					<DeleteFaq item={faq} />
				</div>
			</div>
			<div className="collapse mt-3" id={`collapseFaq${id}`}>
				<div className="faq__card__body">
					<p>{faq?.description?.replace(/(<([^>]+)>)/gi, "")}</p>
				</div>
			</div>
		</div>
	);
}

export function DeleteFaq({ item }: { item: IFaq }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteFaqMutation();
	const onDelete = useDelete<IFaq>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette question ?",
		successMessage: "Question supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export default GestionFaq;
