import React from "react";
import { Link } from "react-router-dom";
import AuthLayout, { AuthMobileBanner } from "../AuthLayout";
import { BtnBack, BtnPrimary } from "../../../common/Button";
import { Input } from "../../../common/Input";
import RequiredMessage from "../../../common/RequiredMessage";
import { UseForgetPasswordForm } from "../useAuthForm/useForgetPasswordForm";

const ForgotPassword = () => {
	const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
	return (
		<AuthLayout>
			<AuthMobileBanner title="Mot de passe oublié" />
			<div className="px-2 px-md-4 px-lg-1">
				<div className="mb-4 view-lg">
					<BtnBack />
				</div>
				<div className="d-lg-none mt-3">
					<h2 className="auth-title-mobile text-sm-center">Mot de passe oublié</h2>
				</div>
				<div className="mb-4">
					<p className="auth-accroche-text">
						Vous avez oublié votre mot de passe ? Pas de problème. Nous vous enverrons
						un e-mail pour définir un nouveau mot de passe.
					</p>
				</div>
				<form onSubmit={onSubmit}>
					<RequiredMessage />
					<Input label="Adresse mail*" type="text" id="email" {...register("email")} error={errors?.email}/>
					<div className="btn-container mt-5">
						<BtnPrimary label="Envoyer ma demande" width="w-100" isLoading={isLoading}/>
					</div>
				</form>
			</div>
		</AuthLayout>
	);
};

export default ForgotPassword;
