import React from "react";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import UseCrudAdmin from "../../admin/Parametres/useParametreForm/UseCrudAdmin";

const AddAdminModal = () => {
	const { register, onSubmit, errors, isLoading } = UseCrudAdmin();
	return (
		<div
			className="modal fade"
			id="AddAdminModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="AddAdminModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<div className="flex-sb">
							<h4 className="fas-modal-title mb-4">Ajouter un administrateur</h4>
							<CloseModalButton />
						</div>

						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-md-6">
									<InputAdmin
										label="Prénom"
										id="prenom"
										type="text"
										placeholder="Prénom"
										{...register("prenom")}
										error={errors?.prenom}
									/>
								</div>
								<div className="col-md-6">
									<InputAdmin
										label="Nom"
										id="name"
										type="text"
										placeholder="Nom"
										{...register("nom")}
										error={errors?.nom}
									/>
								</div>
								<div className="col-md-6">
									<InputAdmin
										label="Téléphone"
										id="tel"
										type="tel"
										placeholder="Téléphone"
										{...register("telephone")}
										error={errors?.telephone}
									/>
								</div>
								<div className="col-md-6">
									<InputAdmin
										label="Adresse mail"
										id="email"
										type="email"
										placeholder="Adresse mail"
										{...register("email")}
										error={errors?.email}
									/>
								</div>
							</div>
							<div className="flex-end mt-5 gap-4">
								<CloseModalButton text="Fermer" />
								<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddAdminModal;
