import moment from "moment";
import React, { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetContactMessagesByUserQuery } from "../../../../utils/api/contact/contact.api";
import { IContact } from "../../../../utils/api/contact/contact.type";
import { ButtonStatusStyle } from "../../../../utils/theme";
import {
	calculatePageCount,
	formatMessageStatus,
	formatMessageStatusClass,
} from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import { ButtonAdd, ButtonView } from "../../../common/Button";
import Pagination from "../../../common/Pagination";
import { DeleteContactMessage, MessageItemSkeleton } from "../../admin/Messages/Messages";
import DetailsMessageModal from "../../modals/user/DetailMessageModal";

const MessagesContact = () => {
	const { data, isLoading, item, setItem, pageCount, handlePageClick, isShowPagination } =
		useMessagesContact();
	return (
		<div className="messagesContact__page">
			<div className="d-flex flex-column flex-md-row justify-content-md-between gap-3 mb-3">
				<h1>Mes messages de contact</h1>
			</div>
			<div className="row">
				{!isLoading && (
					<>
						{data && data?.results?.length > 0 ? (
							data?.results?.map((item) => (
								<MessageItem key={item?.slug} item={item} setItem={setItem} />
							))
						) : (
							<AlertInfo message="Aucune message trouvé" />
						)}
						{isShowPagination ? (
							<div className="flex-c">
								<Pagination
									title=""
									handlePageClick={handlePageClick}
									pageCount={pageCount}
								/>
							</div>
						) : null}
					</>
				)}
				{isLoading && [...Array(5)].map((item, i) => <MessageItemSkeleton key={i} />)}
			</div>
			<DetailsMessageModal item={item} />
		</div>
	);
};

function MessageItem(props: { item: IContact; setItem: (item: IContact) => void }) {
	const { item, setItem } = props;
	return (
		<div className="message__card shadow px-3 py-4 mb-3" style={{ borderRadius: "10px" }}>
			<div className="row align-items-center">
				<div
					role="button"
					data-bs-toggle="modal"
					data-bs-target="#DetailsMessageModal"
					className="col-md-7"
					onClick={() => setItem(item)}
				>
					<h3 className="admin-card-title mb-2" style={{ fontWeight: 400 }}>
						{item?.subject.slice(0, 70)}
					</h3>
					<h6
						style={{
							fontWeight: 400,
							lineHeight: "18px",
							fontSize: 14,
							color: "#9FA2B4",
						}}
					>
						{moment(item?.created_at).format("DD/MM/YYYY à HH:MM")}
					</h6>
				</div>

				<div className="d-flex gap-3 col-md-2">
					<ButtonAdd
						label={formatMessageStatus(item?.status)}
						style={ButtonStatusStyle}
						className={`admin-btn-add ${formatMessageStatusClass(item?.status)}`}
					/>
				</div>
				<div className="col-md-3 flex-end gap-2">
					<ButtonView
						data-bs-toggle="modal"
						data-bs-target="#DetailsMessageModal"
						onClick={() => setItem(item)}
					/>
					<DeleteContactMessage item={item} />
				</div>
			</div>
		</div>
	);
}
function useMessagesContact() {
	const userSlug = useAppSelector((s) => s?.user?.user?.slug);
	const [item, setItem] = useState<IContact>();
	const [perPage] = useState(10);
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetContactMessagesByUserQuery({
		limit: perPage,
		page,
		slug: userSlug,
	});
	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	return {
		data,
		isLoading,
		item,
		setItem,
		pageCount,
		handlePageClick,
		setPage,
		perPage,
		isShowPagination: data?.count && data?.count > perPage,
	};
}
export default MessagesContact;
