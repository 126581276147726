import { Modal } from "reactstrap";
import { ICategory, Subcategory } from "../../../../utils/api/category/category.type";
import { FormModalProps } from "../../../../utils/type";
import { ButtonSubmit, CloseModalButton } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import useSubCategoryForm from "../../admin/Categories/useForm/useSubCategoryForm";
type AddSubCategoryModalProps = FormModalProps<Subcategory> & { category: ICategory };

const AddSubCategoryModal = ({ item, isOpen, closeModal, category }: AddSubCategoryModalProps) => {
	const { register, onSubmit, errors, isLoading } = useSubCategoryForm(
		closeModal,
		category,
		item
	);
	return (
		<Modal isOpen={isOpen} centered>
			<div className="modal-header">
				<h5 className="modal-title modal-title-custom" id="AddSubCategoryModalTitle">
					{item ? "Modifier" : "Ajouter"} une sous-catégorie
				</h5>
				<CloseModalButton />
			</div>
			<div className="modal-body">
				<form onSubmit={onSubmit}>
					<div className="col-12">
						<InputAdmin
							label="nom"
							id="nom"
							type="text"
							placeholder="Nom de la sous catégorie"
							{...register("nom")}
							error={errors?.nom}
							defaultValue={item?.nom}
						/>
					</div>
					<div className="flex-end">
						<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default AddSubCategoryModal;
