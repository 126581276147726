import React, { FormEvent, useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useDiffusionMultipleMutation } from "../../../../utils/api/pepite/pepite.api";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hooks";
import { IPepite, TDiffusionMultipleFormData } from "../../../../utils/api/pepite/pepite.type";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleannerError, onHide } from "../../../../utils/utils";
import { ORIGIN } from "../../../../utils/constants";
import { EBAY_REDIRECT_URL, ETSY_REDIRECT_URL } from "../../../../utils/http";
import { TypeMarketplace } from "../../../../utils/api/marketplace/marketplace.type";

export function useDiffuseMassProducts(listProduct?: IPepite[]) {
	const [arrayOfProductsId, setArrayOfProductsId] = useState<number[]>([]);
	const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
	const { user } = useAppSelector((s) => s.user);

	const handleSelectAll = (e: any) => {
		setIsCheckAll(!isCheckAll);
		if (listProduct?.length) {
			setArrayOfProductsId(listProduct.map((item) => Number(item?.id)));
		}
		if (isCheckAll) {
			setArrayOfProductsId([]);
		}
	};

	const onToggleCheckedItem = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.checked) {
			setArrayOfProductsId([...arrayOfProductsId, Number(e.currentTarget.value)]);
		} else {
			setArrayOfProductsId(
				arrayOfProductsId?.filter((id) => Number(e.currentTarget.value) !== id)
			);
		}
	};

	const reset = () => {
		setArrayOfProductsId([]);
	};

	return {
		onToggleCheckedItem,
		user,
		handleSelectAll,
		arrayOfProductsId,
		isShowBroadcastBtn: arrayOfProductsId?.length > 1,
		reset,
	};
}

export function useDiffusionMultiple(reset: () => void, produitsIds?: number[]) {
	const validationSchema = yup.object().shape({
		marketplace: yup.string().required("Au moins une marketplace"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<TDiffusionMultipleFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [difuserPepite, { isLoading }] = useDiffusionMultipleMutation();
	const [checked, setChecked] = useState<string>();

	const handleChecked = (e: React.ChangeEvent<HTMLInputElement>, tokenStatus?: number) => {
		console.log(e.currentTarget.value);
		const selectedMarket = e.currentTarget.value;
		if (e?.currentTarget?.value && tokenStatus !== 200) {
			return Swal.fire({
				title: `Votre token pour cette markeplace a expiré, voulez-vous le mettre à jour `,
				icon: "info",
				showCancelButton: true,
				confirmButtonText: "Oui",
				cancelButtonText: "Non",
				showLoaderOnConfirm: true,
				confirmButtonColor: Color.primary,
			}).then((result) => {
				console.log({ result });
				if (result.isConfirmed) {
					if (selectedMarket === TypeMarketplace.etsy) {
						return window.open(ETSY_REDIRECT_URL, "_self");
					}
					if (selectedMarket === TypeMarketplace.ebay)
						return window.open(EBAY_REDIRECT_URL, "_self");
				}
			});
		}
		if (e.currentTarget.value) {
			setChecked(e.currentTarget.value);
			setValue("marketplace", e.currentTarget.value);
		}
	};
	const cleannerChecked = () => {
		setChecked("");
		setValue("marketplace", "");
	};

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = async (data: TDiffusionMultipleFormData) => {
		// console.log({ data });
		const newData: TDiffusionMultipleFormData = {
			produits: produitsIds as number[],
			marketplace: data?.marketplace,
			env: ORIGIN,
			page: "user/mon-stock",
		};
		// console.log({ newData });
		const res = await difuserPepite(newData);
		if ("data" in res) {
			const url = res?.data?.url;
			if (url) {
				// window.location.href = url;
				window.open(url, "_self");
			} else {
				Swal.fire({
					icon: "success",
					title: "🎉 Pépites en ligne 🎉",
					text: `Vos pépites viennent d’être mises en ligne sur les marketplaces`,
					iconColor: Color.success,
					showConfirmButton: true,
					confirmButtonText: "Fermer",
					customClass: {
						confirmButton: "btn-swal-close mx-4",
						container: "validation__step",
						title: "swal__success__title",
					},
				}).then(() => {
					reset();
					setChecked("");
					onHide("ModalDiffusionMassProducts");
				});
			}
		} else if ("error" in res) {
			const err = res.error as any;
			console.log("err", err);

			if (err?.status === 401 && checked === "ebay") {
				// return console.log({ err });
				window.open(EBAY_REDIRECT_URL, "_self");
			} else if (
				(err?.status === 401 && checked === "etsy") ||
				(err?.status === 401 &&
					checked === "etsy" &&
					JSON.parse(err?.data?.message[0])?.error?.includes("invalid_token"))
			) {
				return window.open(ETSY_REDIRECT_URL, "_self");
			} else if (err?.data?.message?.includes("Ce produit est déja diffusé")) {
				Swal.fire({
					icon: "error",
					title: err?.data?.message,
					showConfirmButton: false,
					timer: 5000,
				});
			} else if (JSON.parse(err?.data?.message)?.error?.includes("greater than 0")) {
				Swal.fire({
					icon: "error",
					title: "les dimensions du produit sont invalides",
					showConfirmButton: false,
					timer: 5000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur a survenue lors de la diffusion`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	};
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		reset,
		checked,
		handleChecked,
		setChecked,
		cleannerChecked,
	};
}
