import React from "react";
import { FormEditAdminProfil } from "./ProfilAdmin";

const EditAdminProfil = () => {
	return (
		<div className="profil-admin-page">
			<h1>Modifier les informations de l'administrateur</h1>
			<div className="fashion__card px-xl-3 px-1 py-3 mb-4 mt-4">
				<div className="profil_content">
					<FormEditAdminProfil />
				</div>
			</div>
		</div>
	);
};

export default EditAdminProfil;
