import { CloseModalButton } from "../../../common/Button";
import "react-quill/dist/quill.snow.css";
import { IContact } from "../../../../utils/api/contact/contact.type";
import { AlertInfo } from "../../../common/Alert";
import { createMarkup } from "../../../../utils/utils";

const DetailsMessageModal = ({ item }: { item?: IContact }) => {
	return (
		<div
			className="modal fade"
			id="DetailsMessageModal"
			aria-labelledby="DetailsMessageModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content py-2">
					<div className="fas-modal-header d-flex justify-content-between align-items-center px-3">
						<h3 className="fas-modal-title">
							Details du message {item?.subject?.slice(0, 20)}...
						</h3>
						<CloseModalButton />
					</div>
					<div className="px-3 pt-3">
						<div className="row">
							<div className="mb-2">
								<h3 className="fas-modal-title">Sujet</h3>
								<p>{item?.subject}</p>
							</div>
							<div className="mb-2">
								<h3 className="fas-modal-title">Message</h3>
								<p
									className="admin-card-description "
									dangerouslySetInnerHTML={createMarkup(item?.message)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 mt-4">
								<h3 className="fas-modal-title mb-3">Réponses au message</h3>
								{item?.responses && item?.responses?.length > 0 ? (
									item?.responses?.map((res) => (
										<div key={res?.id} className="mb-2 border-top pt-1">
											<p
												className="admin-card-description "
												dangerouslySetInnerHTML={createMarkup(
													res?.response
												)}
											/>
										</div>
									))
								) : (
									<AlertInfo message="Aucune réponse" />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailsMessageModal;
