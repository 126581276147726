import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppDispatch } from "../../../../redux/hooks";
import { onSetMessage, onSetUserToken } from "../../../../redux/slice/User.slice";
import { useLazyMeAdminQuery } from "../../../../utils/api/admin/admin.api";
import { useLoginUserMutation } from "../../../../utils/api/auth/auth.api";
import { LoginFormData } from "../../../../utils/api/auth/auth.type";
import { UserType } from "../../../../utils/api/user/user.type";
import { AppStorage } from "../../../../utils/storage";
import { Color } from "../../../../utils/theme";
import { cleannerError, onOpen } from "../../../../utils/utils";

function useSigninForm() {
	const validationSchema = yup.object().shape({
		email: yup.string().email().required().label("Email"),
		password: yup.string().required().label("Mot de passe"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<LoginFormData>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();

	//  Calling the login Mutation
	const [loginUser, { isLoading, isSuccess, error, isError, data }] = useLoginUserMutation();
	const [userInfos, setUserInfos] = useState<any>();
	const [fetchMe, { data: adminInfo, isError: IsErr }] = useLazyMeAdminQuery();
	const { search } = useLocation();
	useEffect(() => {
		if (search?.includes("status=success&slug") || search?.includes("success_freemium")) {
			onOpen("OnboardingSuccessModal");
		}
	}, [search]);

	useEffect(() => {
		if (adminInfo) {
			dispatch(onSetUserToken({ user: adminInfo, token: userInfos?.token }));
		}
	}, [adminInfo]);

	useEffect(() => {
		if (IsErr) {
			if (userInfos) {
				dispatch(onSetUserToken({ user: userInfos.data, token: userInfos.token }));
			}
		}
	}, [isLoading]);

	useEffect(() => {
		if (isSuccess) {
			AppStorage.setItem("user", {
				token: data?.token,
			});
			console.log({ data });
			setUserInfos(data);
			if (data?.data?.user_type === UserType.admin) {
				fetchMe();
			}
			Swal.fire({
				icon: "success",
				title: "Connexion réussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1200,
			}).then(() => {
				if (data?.data?.user_type !== UserType.admin) {
					dispatch(onSetUserToken({ user: data?.data, token: data?.token }));
				}
				if (data?.data?.user_type === UserType.vendeur) {
					dispatch(onSetMessage(data?.message));
				}
			});
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur a survenue lors de la connexion" } = err.data || {};
			setError("email", { message: message });
		}
	}, [isLoading]);

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const onSubmit = (data: LoginFormData) => {
		console.log(data);
		loginUser(data);
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useSigninForm;
