import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IconArrowDropdown, IconArrowDropUp } from "../../../../shared/Icons";
import "./TopBar.css";
import { Color } from "../../../../../utils/theme";
import { LogoApp, MenuContent } from "../SideBar/SidebarUser";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { onlogout } from "../../../../../redux/slice/User.slice";
import { IUser } from "../../../../../utils/api/user/user.type";
import { getUserPseudo } from "../../../../../utils/utils";

const TopBar = () => {
	const { user } = useAppSelector((s) => s.user);
	const dispatch = useAppDispatch();
	const onLogout = () => {
		dispatch(onlogout());
	};
	return (
		<div className="top-bar">
			<div className="d-flex justify-content-between align-items-center  justify-content-lg-end">
				<div className="d-lg-none">
					<button
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#FashionMenu"
						aria-controls="FashionMenu"
					>
						<FiMenu style={{ fontSize: 25, color: Color.dark }} />
					</button>
				</div>
				<ProfilDropdown user={user} onLogout={onLogout} />
			</div>
			<div
				className="offcanvas offcanvas-start"
				id="FashionMenu"
				aria-labelledby="FashionMenuLabel"
			>
				<div className="offcanvas-header">
					<div className="offcanvas-title" id="FashionMenuLabel">
						<LogoApp />
					</div>
					<button
						type="button"
						className="btn-close btn_close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>
				<div className="offcanvas-body">
					<div className="menu-mobile pb-3">
						<MenuContent />
					</div>
				</div>
			</div>
		</div>
	);
};

export function ProfilDropdown(props: { user?: IUser; onLogout?: () => void }) {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleSetActive = () => {
		setIsActive((isActive) => !isActive);
	};
	const { user, onLogout } = props;
	return (
		<div className="flex-m">
			<div className="dropdown top-bar-btn">
				<button
					className="dropdown-toggle"
					type="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					onClick={toggleSetActive}
				>
					<div className="flex-m">
						<div className="auth-user-avatar flex-mc rounded-circle">
							<h6 className="auth-sigle m-0">{getUserPseudo(user)}</h6>
						</div>
						{isActive ? <IconArrowDropUp /> : <IconArrowDropdown />}
					</div>
				</button>

				<ul className="dropdown-menu fashion__card pepite_action_container mt-2">
					<div className="d-flex flex-column align-items-end px-2 gap-2">
						<li>
							<Link to="/user/profil" className="btn_action btn__edit">
								Mon compte
							</Link>
						</li>
						<li>
							<button
								className="btn_action btn__delete"
								data-bs-target="#LogoutModal"
								data-bs-toggle="modal"
							>
								Me déconnecter
							</button>
						</li>
					</div>
				</ul>
			</div>
		</div>
	);
}

export default TopBar;
