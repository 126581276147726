import React from "react";
import { ButtonAdd, ButtonSubmit } from "../../../common/Button";
import { InputAdmin } from "../../../common/Input";
import camera from "../../../../assets/images/camera.png";
import { getAvatar } from "../../../../utils/utils";
import ChangePasswordModal from "../../modals/admin/ChangePasswordModal";
import useAdminProfil from "./useAdminProfil";

const ProfilAdmin = () => {
	return (
		<div className="profil-admin-page">
			<h1>Profil</h1>
			<div className="fashion__card px-xl-3 px-1 py-3 mb-4 mt-4">
				<div className="profil_content">
					<FormEditAdminProfil />
				</div>
			</div>
			<div className="fashion__card px-xl-3 px-1 py-4">
				<h3 style={{ fontSize: 20 }}>Mot de passe</h3>
				<div className="mt-4">
					<ButtonAdd
						label="Modifier mot de passe"
						icon={false}
						data-bs-toggle="modal"
						data-bs-target="#ChangePasswordModal"
					/>
				</div>
			</div>
			<ChangePasswordModal />
		</div>
	);
};

export function FormEditAdminProfil() {
	const { register, onSubmit, errors, user, isLoading, handleChangeAvatar, avatar } =
		useAdminProfil();
	return (
		<form onSubmit={onSubmit}>
			<div className="flex-c">
				<div className="position-relative">
					<div className="img-container">
						<img
							src={avatar ? avatar : getAvatar(user?.avatar)}
							alt="admin-avatar"
							style={{
								width: "150px",
								height: "150px",
								borderRadius: "30px",
							}}
						/>
					</div>
					<label
						htmlFor="avatar"
						style={{
							position: "absolute",
							top: 4,
							right: -10,
							cursor: "pointer",
						}}
					>
						<img src={camera} alt="camera" />
						<input type="file" id="avatar" hidden onChange={handleChangeAvatar} />
					</label>
				</div>
			</div>
			<div className="row pt-5">
				<div className="col-md-6">
					<InputAdmin
						label="Prénom"
						placeholder="Prénom"
						type="text"
						id="prenom"
						defaultValue={user?.prenom}
						{...register("prenom")}
						error={errors?.prenom}
					/>
				</div>
				<div className="col-md-6">
					<InputAdmin
						label="Nom"
						placeholder="Nom"
						type="text"
						id="nom"
						defaultValue={user?.nom}
						{...register("nom")}
						error={errors?.nom}
					/>
				</div>
				<div className="col-md-6">
					<InputAdmin
						label="Téléphone"
						placeholder="Téléphone"
						type="text"
						id="phone"
						defaultValue={user?.telephone}
						{...register("telephone")}
						error={errors?.telephone}
					/>
				</div>
				<div className="col-md-6">
					<InputAdmin
						label="Adresse mail"
						placeholder="Adresse mail"
						type="text"
						id="email"
						defaultValue={user?.email}
						{...register("email")}
						error={errors?.email}
					/>
				</div>
				<div className="col-md-6">
					<InputAdmin
						label="Adresse"
						placeholder="Adresse"
						type="text"
						id="adress"
						defaultValue={user?.adresse}
						{...register("adresse")}
						error={errors?.adresse}
					/>
				</div>
			</div>
			<div className="flex-end">
				<ButtonSubmit label="Enregistrer" isLoading={isLoading} />
			</div>
		</form>
	);
}

export default ProfilAdmin;
