import React, { useCallback, useEffect, useState } from "react";
import { BsSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import {
	useDeletePepiteDiffuserMutation,
	useGetPepitesDiffuserByUserQuery,
	useLazyDiffusePepiteByScriptQuery,
} from "../../../../utils/api/pepite/pepite.api";
import { IDiffusedPepite, IPepite } from "../../../../utils/api/pepite/pepite.type";
import { EBAY_REDIRECT_URL, ETSY_REDIRECT_URL } from "../../../../utils/http";
import { Color } from "../../../../utils/theme";
import { calculatePageCount } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import PanelFilter from "../../../common/PanelFilter";
import DeletePepiteModal from "../../modals/user/DeletePepiteModal";
import ModifierDiffusionModalPepite from "../../modals/user/ModifierDiffusionModalPepite";
import PepiteCard, { PepiteCardSkeleton } from "../Stock/PepiteCard";

const Diffuser = () => {
	const [page, setPage] = useState(1);
	const { user } = useAppSelector((s) => s.user);
	const { data, isLoading } = useGetPepitesDiffuserByUserQuery({
		page,
		limit: 10,
		slug: user?.slug,
	});
	const perPage = 10;
	const isData = data?.results && data?.results?.length > 0;

	const isShowPaginate = data?.count && data?.count > perPage;

	const pageCount = calculatePageCount(data?.count, perPage);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event?.selected + 1);
	};

	const [diffuser] = useLazyDiffusePepiteByScriptQuery();
	const navigate = useNavigate();

	const onSell = async (slug: string) => {
		const res = await diffuser(slug);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Produit vendu avec succès",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2500,
			}).then(() => {
				navigate("/user/mes-ventes");
			});
		} else if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenu lors de l'inscription",
				showConfirmButton: false,
				confirmButtonColor: Color.primary,
				timer: 2000,
			});
		}
	};
	return (
		<div className="diffusion__page">
			<h1>Diffuser</h1>
			<PanelFilter />
			<div className="pepites__container mt-4 px-md-3 px-lg-0">
				<div className="flex-sb mb-3">
					<div className="d-flex justify-content-between w-100">
						<div className="flex-m gap-2">
							<h3 className="title__level3">Mes pépites dans mon stock</h3>
							<BsSquare
								style={{
									fontSize: 7,
									color: "#999591",
								}}
							/>
							<p className="total-pepite  m-0">{data?.count} Résultat</p>
						</div>
					</div>
					{isShowPaginate && (
						<div className="view-md">
							<Pagination
								title="Page"
								pageCount={pageCount}
								handlePageClick={handlePageClick}
							/>
						</div>
					)}
				</div>
				<div className="pepites__cards__container">
					{!isLoading ? (
						isData ? (
							data?.results?.map((item: IDiffusedPepite) => (
								<React.Fragment key={item?.slug}>
									<PepiteCard
										pepite={item.produit}
										broadcast={true}
										path="/user/mon-stock/pepites/"
										marketplace={item?.marketplace}
										marketplace_detail={item?.marketplace_detail}
										onSell={onSell}
										sales={item.sales}
									/>
								</React.Fragment>
							))
						) : (
							<AlertInfo message="Aucune pépite trouvée" />
						)
					) : (
						<>
							{[...Array(5)]?.map((item, i) => (
								<PepiteCardSkeleton key={i} />
							))}
						</>
					)}
				</div>
				{isShowPaginate ? (
					<div className="flex-c">
						<Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
					</div>
				) : null}
			</div>
			<ModifierDiffusionModalPepite />
			<DeletePepiteModal message="En supprimant cette pépite, elle sera automatiquement retirée de notre plateforme et des marketplaces." />
		</div>
	);
};

export function DeleteDiffusion({ item, marketplace }: { item: IPepite; marketplace?: string }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeletePepiteDiffuserMutation();
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer cette diffusion`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return deleteItem({ slug: item.slug, marketplace: marketplace });
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Diffusion supprimée avec succès`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			console.log(err.status);
			if (err?.status === 401 && marketplace === "ebay") {
				window.open(EBAY_REDIRECT_URL, "_self");
			} else if ((err?.status === 400 || err?.status === 401) && marketplace === "etsy") {
				window.open(ETSY_REDIRECT_URL, "_self");
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur a survenue lors de la diffusion`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isError]);
	return (
		<button className="btn_action btn__delete" data-bs-toggle="modal" onClick={onDelete}>
			supprimer
		</button>
	);
}

export default Diffuser;
