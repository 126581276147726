import React from "react";
import EditPepite from "../../user/EditPepite/EditPepite";

const AdminEditPepite = () => {
	return (
		<div className="bg-white p-3 mt-5">
			<EditPepite />
		</div>
	);
};

export default AdminEditPepite;
