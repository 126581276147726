import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "./Input";

type InputPropsType = InputHTMLAttributes<HTMLSelectElement> & {
	label: string;
	options?: {
		label: string;
		value: string | number;
	}[];
	value?: any;
	error?: string | FieldError;
	firstOption?: string;
};
export const Select = React.forwardRef<HTMLSelectElement, InputPropsType>((props, ref) => {
	const { name, error, label, options, required, firstOption, ...rest } = props;
	return (
		<div className="form-group mb-4">
			<label htmlFor="nombre_stock" className="custom-form-label" aria-labelledby={name}>
				{label}
				{required && <span className="badge-danger">*</span>}
			</label>
			<select
				name={name}
				id={name}
				ref={ref}
				className="form-select form-custom-control fas-form-select py-3"
				{...rest}
			>
				<option value="">{firstOption ? firstOption : label}</option>
				{options?.map((option) => (
					<option value={option.value} key={option.value}>
						{option.label}
					</option>
				))}
			</select>

			{<FormError error={error} />}
		</div>
	);
});
