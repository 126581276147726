import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "../../../redux/store";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { ChangePasswordData } from "../auth/auth.type";
import {
	AuthState,
	DeleteUserAccountData,
	IUser,
	TypeDashboardUserQuery,
	UserDashboardDataType,
	UserFormData,
} from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }: { getState: any }) => {
	const token =
		(getState() as RootState).user.token ?? AppStorage.getItem<AuthState>("user")?.token;
	if (token) {
		headers.set("Authorization", `Bearer ${token}`);
	}
	return headers;
};
export const UserApi = createApi({
	reducerPath: "userApi",
	tagTypes: ["user", "userList"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		me: builder.query<IUser, void>({
			providesTags: ["user"],
			transformResponse: ({ data }) => data,
			query: () => {
				return "auth/me/";
			},
		}),
		dashboardUserData: builder.query<UserDashboardDataType, TypeDashboardUserQuery>({
			providesTags: ["user"],
			query: ({ slug, year, marketplace }) => ({
				url: `dashboardbyvendeur/${slug}`,
				params: { year, marketplace },
			}),
		}),
		// usersList: builder.query<PaginationResults<IUser>, TypeQuery>({
		// 	query: (query) => QueryUrl("/users/", query),
		// 	providesTags: ["userList"],
		// }),
		addOrUpdateUser: builder.mutation<IUser, { slug?: string; data: UserFormData }>({
			invalidatesTags: ["user", "userList"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `users/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `users/`,
					method: "POST",
					body: data,
				};
			},
		}),
		changePassword: builder.mutation<any, ChangePasswordData>({
			query: (data) => ({
				url: `auth/change-password/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		deleteUserAccount: builder.mutation<IUser, { slug: string; data: DeleteUserAccountData }>({
			query: ({ slug, data }) => ({
				url: `/user/${slug}/suppression/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		cancelSubscription: builder.mutation<IUser, string>({
			query: (slug) => ({
				url: `/cancel_subscription/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: ["user"],
		}),
	}),
});

export const {
	useMeQuery,
	useLazyMeQuery,
	useAddOrUpdateUserMutation,
	useChangePasswordMutation,
	useDeleteUserAccountMutation,
	useDashboardUserDataQuery,
	useCancelSubscriptionMutation,
} = UserApi;
