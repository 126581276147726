import React from "react";
import Breadcrumb from "../../../common/Breadcrumb";
import { ButtonSubmit } from "../../../common/Button";
import { Input } from "../../../common/Input";
import RequiredMessage from "../../../common/RequiredMessage";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import "./Sellers.css";
import UseCrudVendeur from "./useForm/UseCrudVendeur";
import { useLocationState } from "../../../../utils/utils";
import { Vendeur } from "../../../../utils/api/vendeur/vendeur.type";

const AddSeller = () => {
	const item = useLocationState<Vendeur>(undefined);
	const { register, onSubmit, errors } = UseCrudVendeur(item);

	const BreadRoutes = [
		{ name: "Vendeurs", path: "/admin/vendeurs" },
		{
			name: `${!item?.slug ? "Ajouter" : "Modifier"} un vendeur`,
			path: `/admin/vendeurs/${
				!item?.slug ? "ajouter-un-vendeur" : item?.slug + "/modifier"
			}`,
		},
	];

	return (
		<div>
			<h1>Vendeurs</h1>
			<Breadcrumb routes={BreadRoutes} />
			<AdminCard marginTop={false}>
				<form onSubmit={onSubmit} className="pt-4">
					<div className="fashion__card p-3 mb-4">
						<h2 className="add-seller-bloc-title">Informations personelles</h2>

						<RequiredMessage />
						<div className="row">
							<div className="col-md-6">
								<Input
									label="Nom*"
									type="text"
									id="nom"
									{...register("nom")}
									error={errors?.nom}
								/>
							</div>
							<div className="col-md-6">
								<Input
									label="Prénom*"
									type="text"
									id="prenom"
									{...register("prenom")}
									error={errors?.prenom}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Téléphone"
									type="tel"
									id="tel"
									{...register("telephone")}
									error={errors?.telephone}
								/>
							</div>
							<div className="col-md-6 mb-3">
								<Input
									label="Adresse mail"
									type="email"
									id="email"
									{...register("email")}
									error={errors?.email}
								/>
							</div>
						</div>
					</div>
					{/* <div className="fashion__card p-3 mb-4">
						<h2 className="add-seller-bloc-title">Informations de ma boutique</h2>
						<RequiredMessage />
						<div className="row">
							<div className="col-md-6">
								<Input label="Nom*" type="text" id="nom_boutique" />
							</div>
							<div className="col-md-6">
								<Input
									label="Numéro de SIRET (9 chiffres) *"
									type="text"
									id="siret"
								/>
							</div>
						</div>
					</div> 
					<div className="fashion__card p-3 mb-4">
						<h2 className="add-seller-bloc-title">Adresse de facturation</h2>
						<RequiredMessage />
						<div className="row">
							<div className="col-12">
								<Input label="Adresse*" type="text" id="adresse"/>
							</div>
							<div className="col-md-6">
								<Input label="Ville*" type="text" id="ville" />
							</div>
							<div className="col-md-6">
								<Input label="Code postal*" type="text" id="code_postal" />
							</div>
						</div>
					</div>*/}
					<div className="btn-container flex-end mt-5">
						<ButtonSubmit label="Enregistrer" />
					</div>
				</form>
			</AdminCard>
		</div>
	);
};

export default AddSeller;
