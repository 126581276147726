import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";
import {
	IDiffusedPepite,
	ImportPepiteData,
	IPepite,
	IPepiteDiffusion,
	IShopifyProduct,
	PepiteFormData,
	PepiteQuery,
	ShopifyProductData,
	TDiffusionFormData,
	TDiffusionMultipleFormData,
} from "./pepite.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../utils";

export const PepiteApi = createApi({
	tagTypes: ["pepite", "sells"],
	reducerPath: "pepiteApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getPepite: builder.query<PaginationResults<IPepite>, TypeQuery & PepiteQuery>({
			query: (query) => QueryUrl("articles/", query),
			providesTags: ["pepite"],
		}),
		getPepiteDiffuser: builder.query<PaginationResults<IPepite>, TypeQuery>({
			query: (query) => QueryUrl("diffusion/list_produit/", query),
			providesTags: ["pepite"],
		}),
		getPepitesByUser: builder.query<PaginationResults<IPepite>, TypeQuery & PepiteQuery>({
			query: ({ slug, ...query }) => QueryUrl(`user/${slug}/articles/`, query),
			providesTags: ["pepite"],
		}),
		getPepitesDiffuserByUser: builder.query<PaginationResults<IDiffusedPepite>, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`user/${slug}/diffusion_produit/`, query),
			providesTags: ["pepite"],
		}),
		addOrUpdatePepite: builder.mutation<
			IPepite,
			{ slug?: string; data: PepiteFormData | FormData }
		>({
			invalidatesTags: ["pepite"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `articles/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `articles/`,
					method: "POST",
					body: data,
				};
			},
		}),
		diffuserPepite: builder.mutation<IPepiteDiffusion, TDiffusionFormData>({
			query: ({ slug, ...data }) => ({
				url: `diffuser_produit/${slug}`,
				method: "GET",
				params: { ...data },
			}),
			invalidatesTags: ["pepite", "sells"],
		}),
		deletePepite: builder.mutation<IPepite, string>({
			query: (slug) => ({
				url: `articles/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["pepite", "sells"],
		}),
		deletePepiteDiffuser: builder.mutation<IPepite, { slug: string; marketplace?: string }>({
			query: ({ slug, marketplace }) => ({
				url: `diffusion/suppression_produit/${slug}/`,
				method: "GET",
				params: { marketplace },
			}),
			invalidatesTags: ["pepite", "sells"],
		}),
		importPepite: builder.mutation<IPepite, ImportPepiteData | FormData>({
			query: (data) => ({
				url: `importproduitexcel/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["pepite"],
		}),
		importCsv: builder.mutation<IPepite, ImportPepiteData | FormData>({
			query: (data) => ({
				url: `importproduitetsyebaybycsv/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["pepite"],
		}),
		importJson: builder.mutation<IPepite, ImportPepiteData | FormData>({
			query: (data) => ({
				url: `importproduitshopifyfromjsonv2/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["pepite"],
		}),
		getSellPepitesBySeller: builder.query<
			PaginationResults<IDiffusedPepite>,
			TypeQuery & { marketplace: string | null }
		>({
			query: ({ slug, marketplace, ...query }) => ({
				url: QueryUrl(`ventesbyvendeur/${slug}`, query),
				params: { marketplace },
			}),
			providesTags: ["sells"],
		}),
		diffusePepiteByScript: builder.query<IPepite, string>({
			query: (slug) => ({
				url: `script_diffusion_product/${slug}/`,
				method: "GET",
			}),
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(PepiteApi.util.invalidateTags(["pepite", "sells"]));
			},
		}),
		diffusionMultiple: builder.mutation<IPepiteDiffusion, TDiffusionMultipleFormData>({
			query: (data) => ({
				url: `diffusion_multiple/`,
				method: "POST",
				body: { ...data },
			}),
			invalidatesTags: ["pepite", "sells"],
		}),
		putBackPepite: builder.query<IPepite, string>({
			query: (slug) => ({
				url: `reset_product_in_stock/${slug}/`,
				method: "GET",
			}),
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(PepiteApi.util.invalidateTags(["pepite", "sells"]));
			},
		}),
		getShopifyProducts: builder.mutation<IShopifyProduct[], { user: string; boutique: string }>(
			{
				query: (data) => ({
					url: `shopify/product/`,
					method: "POST",
					body: data,
				}),
				invalidatesTags: ["pepite"],
			}
		),
		importShopifyProduct: builder.mutation<IPepite, ShopifyProductData>({
			query: (data) => ({
				url: `shopify/product/import/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["pepite"],
		}),
		sendEbayCode: builder.query<IPepite, { code: string; user: string }>({
			query: ({ user, code }) => ({
				url: `ebay/user_accces_token/`,
				method: "GET",
				params: {
					user,
					code,
				},
			}),
		}),
	}),
});

export const {
	useGetPepiteQuery,
	useGetPepitesByUserQuery,
	useLazyGetPepiteQuery,
	useAddOrUpdatePepiteMutation,
	useDeletePepiteMutation,
	useDiffuserPepiteMutation,
	useGetPepiteDiffuserQuery,
	useGetPepitesDiffuserByUserQuery,
	useDeletePepiteDiffuserMutation,
	useImportPepiteMutation,
	useGetSellPepitesBySellerQuery,
	useDiffusePepiteByScriptQuery,
	useLazyDiffusePepiteByScriptQuery,
	useImportCsvMutation,
	useImportJsonMutation,
	usePutBackPepiteQuery,
	useLazyPutBackPepiteQuery,
	useGetShopifyProductsMutation,
	useImportShopifyProductMutation,
	useDiffusionMultipleMutation,
	useLazySendEbayCodeQuery,
} = PepiteApi;
