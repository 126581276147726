import { FiAlertCircle } from "react-icons/fi";
type PropsType = {
	message: string;
};
export function AlertInfo({ message }: PropsType) {
	return (
		<div className="px-3 mb-4">
			<div className="fashion-alert fashion-alert-info" role="alert">
				<FiAlertCircle style={{ fontSize: 24 }} />
				<h4 className="m-0">{message}</h4>
			</div>
		</div>
	);
}
