import React from "react";
import { IconSearch } from "../shared/Icons";
type PanelFilterProps = {
	handleFilterCollection: (e: React.FormEvent<HTMLInputElement>) => void;
};
const PanelFilterCollection = ({ handleFilterCollection }: Partial<PanelFilterProps>) => {
	return (
		<div className="panel-filter py-4 row mt-4 bg__slate">
			<div className="col-md-6 mb-3 mb-md-0">
				<label htmlFor="search_pepite" className="fas__label__imp">
					Rechercher une collection
				</label>
				<div className="search-input-container position-relative mt-2">
					<span style={{ position: "absolute", top: "8px", left: "4px" }}>
						<IconSearch />
					</span>
					<input
						type="search"
						id="search_pepite"
						className="form-control fas__control"
						placeholder="Écrivez le nom de la collection"
						onChange={handleFilterCollection}
					/>
				</div>
			</div>
		</div>
	);
};

export default PanelFilterCollection;
