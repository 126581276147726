import React from "react";
import { Link } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { IconBag, IconCarte, IconShare } from "../../../shared/Icons";
import "./Dashboard.css";
import BarChart from "./BarChart/BarChart";
import { formatMontant } from "../../../../utils/utils";
import VolkenoSkeleton from "volkeno-react-skeleton";
import DatePicker from "react-datepicker";
import useDashboard from "./useDashboard";

const Dashboard = () => {
	const { data, isLoading, date, handleFilterByMarket, handleFilterByYear } = useDashboard();
	return (
		<div className="user__dashboard">
			<h1>Dashboard</h1>
			<div className="stat__container pt-4">
				<h3 className="title__level3">Statistiques de mes pépites</h3>
				<div className="d-flex flex-column flex-sm-row gap-4 mt-3">
					<CardItem
						icon={<IconCarte />}
						path="/user/mon-stock"
						total={data?.pepites_stock_count}
						title="Pépites en stock"
					/>
					<CardItem
						icon={<IconShare />}
						path="/user/diffuser"
						total={data?.pepites_online_count}
						title="Pépites en ligne"
					/>
					<CardItem
						icon={<IconBag />}
						path="/user/mes-ventes"
						total={formatMontant(data?.revenu_total)}
						title="Ventes totales"
					/>
				</div>
			</div>
			<div className="graph__container mt-4">
				<h3 className="title__level3">Statistiques de mes revenus</h3>
				<div className="row d-flex flex-column-reverse flex-md-row pt-md-5">
					<div className="col-md-9 col-xl-10">
						<div className="d-flex flex-column flex-md-row justify-content-md-end align-items-center gap-3">
							<div className="filter-by-market">
								<select
									id="marketplace"
									className="form-select form-control-lg form-control-admin"
									style={{ padding: "0.7rem 2.25rem 0.7rem 0.75rem" }}
									onChange={handleFilterByMarket}
								>
									<option value="">Tous</option>
									<option value="etsy">Etsy</option>
									<option value="ebay">Ebay</option>
								</select>
							</div>
							<div className="select-filter-container ">
								<DatePicker
									selected={date}
									onChange={(date: Date) => handleFilterByYear(date)}
									className="filter_control"
									maxDate={new Date()}
									dateFormat="yyyy"
									showYearPicker
									minDate={new Date("01/01/2022")}
								/>
							</div>
						</div>
						{!isLoading ? (
							<BarChart data={data?.graph} />
						) : (
							<VolkenoSkeleton variant="rect" width="100%" height="500px" />
						)}
					</div>
					<div className="col-md-3 col-xl-2 mb-5 pt-md-5 mb-md-0">
						<CardItem
							icon={<IconBag />}
							total={formatMontant(data?.revenu_total)}
							title="Revenu total"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

interface Propstype {
	icon: React.ReactNode;
	path?: string;
	total?: string | number;
	title: string;
}

function CardItem({ icon, path, total, title }: Propstype) {
	return (
		<div className="fashion__card  dashboard__card px-sm-3 px-2 py-2 w-100">
			<div className="flex-m-sb">
				<div
					style={{
						background: Color.secondary,
						padding: "1px 6px",
						borderRadius: "2px",
					}}
				>
					{icon}
				</div>
				{path && (
					<Link to={path} className="fashion__link">
						Voir
					</Link>
				)}
			</div>
			<div className="stats">
				<p className="total__value my-2">{total}</p>
				<h6 className="item__title">{title}</h6>
			</div>
		</div>
	);
}

export default Dashboard;
