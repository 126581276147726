import React, { FormEvent } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import {
	useImportCsvMutation,
	useImportPepiteMutation,
} from "../../../../utils/api/pepite/pepite.api";
import { Color } from "../../../../utils/theme";
import { onHide } from "../../../../utils/utils";
import { useAppSelector } from "../../../../redux/hooks";

export function useImportFile() {
	const { user } = useAppSelector((s) => s.user);

	const [importPepite, { isLoading, isSuccess, error, isError }] = useImportPepiteMutation();
	const [
		importCSV,
		{ isLoading: isLoadCsv, isSuccess: isSuccessCsv, error: errorCsv, isError: isErrorCsv },
	] = useImportCsvMutation();

	useEffect(() => {
		if (isSuccess || isSuccessCsv) {
			Swal.fire({
				icon: "success",
				title: "Pépites importées avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				onHide("ModaImportFilePepite");
			});
		}
	}, [isSuccess, isSuccessCsv]);

	useEffect(() => {
		let err = error as any;
		if (isErrorCsv) {
			err = errorCsv as any;
		}
		if (isError || isErrorCsv) {
			console.log({ err });
			if (err?.status === "FETCH_ERROR") {
				Swal.fire({
					icon: "error",
					title: "Une erreur de serveur a survenue  dû au nombre total de produits importés",
					showConfirmButton: false,
					timer: 5000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur a survenue lors de l'importation`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isError, isErrorCsv]);

	const onChangeFile = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			const fd = new FormData();
			fd.append("user", user?.id);
			fd.append("fichier", e.currentTarget.files[0]);
			fd.append("boutique", user?.boutique[0]?.id);
			importPepite(fd);
		}
	};

	const onImportCsvFile = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			const fd = new FormData();
			fd.append("user", user?.id);
			fd.append("file", e.currentTarget.files[0]);
			fd.append("boutique", user?.boutique[0]?.id);
			importCSV(fd);
		}
	};

	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	};

	return {
		onSubmit,
		isLoading,
		onChangeFile,
		isLoadCsv,
		onImportCsvFile,
	};
}
