import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useLazyPutBackPepiteQuery } from "../../../../utils/api/pepite/pepite.api";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { onHide } from "../../../../utils/utils";
import { BtnPrimary } from "../../../common/Button";
import { IconStoreLarge } from "../../../shared/Icons";
import { SmallPepiteCard } from "../../user/Stock/PepiteCard";

const RemettrePepiteModalPepite = (props: { pepite?: IPepite; orderSlug?: string }) => {
	const { pepite, orderSlug } = props;
	const [putPepiteBack, { isLoading }] = useLazyPutBackPepiteQuery();
	// const navigate = useNavigate();
	const putPepiteBackInTheStock = async () => {
		console.log(orderSlug);
		const res = await putPepiteBack(orderSlug as string);
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Pépite remise avec succès!",
				iconColor: Color.success,
				showConfirmButton: true,
				confirmButtonText: "Fermer",
				customClass: {
					confirmButton: "btn-swal-close mx-4",
					container: "validation__step",
					title: "swal__success__title",
				},
			}).then(() => {
				onHide("RemettrePepiteModalPepite");
				// navigate("/user/mon-stock");
			});
		} else if ("error" in res) {
			const err = res.error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de la remise`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		putPepiteBackInTheStock();
	};

	return (
		<div
			className="modal fade"
			id="RemettrePepiteModalPepite"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="RemettrePepiteModalPepiteLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<form action="" onSubmit={onSubmit}>
							<div className="modal__header">
								<div className="flex-c mb-3 icon__share">
									<IconStoreLarge />
								</div>
								<h2 className="modal__title">
									Remettre cette pépite dans le stock
								</h2>
							</div>
							<div className="modal__body mt-3">
								<SmallPepiteCard pepite={pepite} />
							</div>
							<div className="modal__footer flex-end gap-5 mt-5">
								<button
									type="button"
									className="fashion__btn"
									data-bs-dismiss="modal"
									style={{ color: Color.primary }}
								>
									Annuler
								</button>
								<BtnPrimary label="remettre" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RemettrePepiteModalPepite;
