import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { InputFilter, RadioButton } from "../../../common/Input";
import PepitesTable from "../../tables/admin/Pepites/PepitesTable";

const Pepites = () => {
	const {
		pepiteName,
		status,
		vendeur,
		handleFilterPepiteByName,
		handleFilterPepiteByStatut,
		handleFilterPepiteBySellerName,
	} = useFilterPepite();
	return (
		<div className="pepites__page">
			<h1>Pépites</h1>
			<div className="bg-white px-4 py-4 rounded-10 mt-5 mb-4">
				<div className="d-flex justify-content-between my-4">
					<div className="filter-container">
						<label htmlFor="" className="me-2">
							Filtrer
						</label>
						<InputFilter
							label="par produit"
							id="name"
							type="search"
							onChange={handleFilterPepiteByName}
						/>
						<InputFilter
							label="par vendeur"
							id="seller"
							type="search"
							onChange={handleFilterPepiteBySellerName}
						/>
					</div>
					<div className="d-flex gap-3">
						<RadioButton
							label="En ligne"
							id="en_ligne"
							onChange={handleFilterPepiteByStatut}
							value="on"
							name="status"
						/>
						<RadioButton
							label="Ébauche"
							id="ebauche"
							onChange={handleFilterPepiteByStatut}
							value="off"
							name="status"
						/>
					</div>
					<Link to="/admin/pepites/ajouter" className="admin-btn-add">
						Ajouter une pépite
					</Link>
				</div>
				<PepitesTable pepiteName={pepiteName} status={status} vendeur={vendeur} />
			</div>
		</div>
	);
};

export const useFilterPepite = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const pepiteName = searchParams.get("nom_pepite");
	const status = searchParams.get("statut");
	const vendeur = searchParams.get("vendeur");

	const params = Object.fromEntries(searchParams);

	const handleFilterPepiteByName = (e: React.FormEvent<HTMLInputElement>): void => {
		setSearchParams({ ...params, nom_pepite: e.currentTarget.value });
	};
	const handleFilterPepiteByStatut = (e: React.FormEvent<HTMLInputElement>): void => {
		setSearchParams({ ...params, statut: e.currentTarget.value });
	};

	const handleFilterPepiteBySellerName = (e: React.FormEvent<HTMLInputElement>): void => {
		setSearchParams({ ...params, vendeur: e.currentTarget.value });
	};

	return {
		pepiteName,
		status,
		handleFilterPepiteByName,
		handleFilterPepiteByStatut,
		handleFilterPepiteBySellerName,
		vendeur,
	};
};

export default Pepites;
