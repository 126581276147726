import React from "react";
import LogoutModal from "../../modals/user/LogoutModal";
import { LogoApp } from "../Layouts/SideBar/SidebarUser";
import { ProfilDropdown } from "../Layouts/TopBar/TopBar";
import { AssideButtons } from "../Profil/Profil";
import "./Faq.css";
import FaqItem from "./FaqItem";
import { useGetFaqQuery } from "../../../../utils/api/faq/faq.api";


const Faq = () => {
	const { data, isLoading } = useGetFaqQuery();

	return (
		<div className="faq_page px-3 px-lg-5 py-3 contaier-fluid">
			<div className="flex-sb">
				<LogoApp />
				<ProfilDropdown />
			</div>
			<div className="pt-3 pt-md-4">
				<h1>FAQ</h1>
				<div className="row pt-2 pt-md-5 d-flex flex-md-row-reverse">
					<div className="col-md-3 col-xl-2 mb-4">
						<AssideButtons path="/user/profil" label="Revenir au profil" />
					</div>
					<div className="col-md-9 col-xl-10">
						{data?.results?.map((item, i) => (
							<FaqItem key={i} id={i} faq={item} />
						))}
					</div>
				</div>
			</div>
			<LogoutModal />
		</div>
	);
};

export default Faq;
