import { useAppSelector } from "../../../../redux/hooks";
import { useDeleteOfferMutation, useGetOfferQuery } from "../../../../utils/api/offer/offer.api";
import { IOffer, OfferOptionType } from "../../../../utils/api/offer/offer.type";
import { IUser, UserType } from "../../../../utils/api/user/user.type";
import { useDelete } from "../../../../utils/helpers";
import { createMarkup } from "../../../../utils/utils";
import { BtnPrimary, ButtonAdd, ButtonDelete, ButtonEditLink } from "../../../common/Button";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import "./Abonnement.css";

const Abonnement = () => {
	return (
		<div className="abonnement__page">
			<h1>Abonnements</h1>
			<AdminCard>
				<div className="flex-end mb-5">
					<ButtonAdd
						path="/admin/abonnements/ajouter-un-abonnement"
						label="Ajouter un abonnement"
						icon={true}
					/>
				</div>
				<div className="abonnements-cards-container">
					<AbonnementsList />
				</div>
			</AdminCard>
		</div>
	);
};

export function AbonnementsList({ getItem }: { getItem?: (item: IOffer) => void }) {
	const { data, isLoading } = useGetOfferQuery();
	return (
		<>
			{!isLoading &&
				data?.map((item: IOffer) => (
					<AbonnementCard key={item?.slug} item={item} getItem={getItem} />
				))}
		</>
	);
}
type Props = {
	item: IOffer;
	checkedId?: number;
	getItem?: (item: IOffer) => void;
};
export function AbonnementCard(props: Props) {
	const { user } = useAppSelector((s) => s.user);
	const { item, checkedId, getItem } = props;
	return (
		<div
			className={`abonnement-card-item bg-white  shadow px-4 pt-4 mb-4 ${
				checkedId === item?.id ? "active" : ""
			}`}
			style={{ borderRadius: "10px" }}
		>
			<div className="row flex-m">
				<div className={`${user?.id ? "col-md-8" : "col-12"}`}>
					<div className="row">
						<div className="abonnement-infos col-md-6">
							<h3 className="fas-ab-title">
								{item?.title}{" "}
								{item?.option === OfferOptionType.BASIC && " (recommandé)*"}
							</h3>
							<div className="abonnement-price-container flex-c gap-2 mt-3">
								<span style={{ fontWeight: 800, fontSize: 20 }}>€</span>
								<h5 className="fas-ab-price">
									{item?.option !== OfferOptionType.FREEMIUM
										? item?.price
										: "0.00"}
								</h5>
								<h6
									className="m-0"
									style={{
										fontWeight: 600,
										fontSize: 20,
										lineHeight: "50px",
									}}
								>
									/ mois
								</h6>
							</div>
							{item?.details && (
								<div className="fas-ab-description">
									<p>{item?.details}</p>
								</div>
							)}
						</div>
						<ul
							className="col-md-6 mt-3 mt-md-0 avantages-offres"
							dangerouslySetInnerHTML={createMarkup(item?.description)}
						></ul>
					</div>
					{item?.option === OfferOptionType.FREEMIUM && (
						<div className="offer-info pb-2">
							<p>
								Diffusion sur les marketplaces à la carte : 0,95€ la diffusion de la
								pièce par marketplace*
							</p>
						</div>
					)}
				</div>
				{/* */}

				<div className="col-md-4">
					{user?.user_type === UserType.vendeur && getItem && (
						<div className="flex-end">
							<BtnPrimary
								label="SOUSCRIRE"
								mode="Secondary"
								data-bs-toggle="modal"
								data-bs-target="#ChooseRenewAbonnementModal"
								onClick={() => getItem(item)}
							/>
						</div>
					)}
					{user?.user_type !== UserType.vendeur && (
						<>
							<div className="gap-3 flex-end pt-4">
								<ButtonEditLink
									to={`/admin/abonnements/${item?.slug}/modifier`}
									state={item}
								/>
								<DeleteOffer item={item} />
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export function DeleteOffer({ item }: { item?: IOffer }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteOfferMutation();
	const onDelete = useDelete<IOffer>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet abonnement?",
		successMessage: "Abonnement supprimé",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export default Abonnement;
