import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../../redux/hooks";
import { AbonnementFormData } from "../../../../../utils/api/abonnement/abonnement.type";
import { IOffer } from "../../../../../utils/api/offer/offer.type";
import { PaymentMode } from "../../../../../utils/api/paymentMode/paymentMode.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { ORIGIN } from "../../../../../utils/constants";
import moment from "moment";
import { useRenewAbonnementMutation } from "../../../../../utils/api/abonnement/abonnement.api";

export function useRenewAbonnement(item?: IOffer) {
	const [createAbonnement, { isLoading }] = useRenewAbonnementMutation();
	const { user } = useAppSelector((s) => s?.user);

	const [checkedModePayementId, setCheckedModePayementId] = useState<number>(0);

	const validationSchema = yup.object().shape({
		moyen_paiement: yup.number().required("Veuillez choisir un mode de paiement"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm<AbonnementFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors);
		}
	}, [errors]);

	const handleChoiceModePaiement = (item: PaymentMode) => {
		setCheckedModePayementId(item?.id);
		if (item?.id) {
			console.log("moyen_paiement", item?.id);
			setValue("moyen_paiement", item?.id);
		}
	};

	const onSubmit = async (data: AbonnementFormData) => {
		const newData: AbonnementFormData = {
			...data,
			offre: item?.id as number,
			user: user?.id,
			env: ORIGIN,
			page: "user/profil",
			date_debut: moment(new Date()).format("YYYY-MM-DDThh:mm"),
		};
		console.log(newData);
		const res = await createAbonnement(newData);
		if ("data" in res) {
			window.location.href = res?.data?.url;
		} else if ("error" in res) {
			const err = res.error as any;
			console.log(err?.data?.messsage);
			Swal.fire({
				icon: "error",
				title: err?.data?.messsage?.includes("FREEMIUM")
					? "Veuillez choisir un abonnement différent du FREEMIUM"
					: "Une erreur inconnue a survenu",
				showConfirmButton: true,
				confirmButtonColor: Color.primary,
			});
			onHide("ChooseRenewAbonnementModal");
		}
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		clearErrors,
		isLoading,
		checkedModePayementId,
		handleChoiceModePaiement,
	};
}
