import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useDashboardUserDataQuery } from "../../../../utils/api/user/user.api";

export default function useDashboard() {
	const { user } = useAppSelector((s) => s.user);
	const [searchParams, setSearchParams] = useSearchParams();
	const [date, setDate] = useState<Date>(new Date());
	const marketplace = searchParams.get("marketplace");
	const year = searchParams.get("annee");

	const params = Object.fromEntries(searchParams);

	const handleFilterByYear = (date: Date): void => {
		setDate(date);
		const year = moment(date).format("YYYY");
		setSearchParams({ ...params, annee: year });
	};
	const handleFilterByMarket = (e: React.FormEvent<HTMLSelectElement>): void => {
		setSearchParams({ ...params, marketplace: e.currentTarget.value });
	};
	const { data, isLoading } = useDashboardUserDataQuery({
		slug: user?.slug,
		year: year ? year : moment(new Date()).format("YYYY"),
		marketplace,
	});

	return {
		data,
		isLoading,
		handleFilterByMarket,
		handleFilterByYear,
		date,
	};
}
