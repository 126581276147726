import React, { FormEvent, useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useImportShopifyProductMutation } from "../../../../utils/api/pepite/pepite.api";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hooks";
import { IShopifyProduct } from "../../../../utils/api/pepite/pepite.type";

export function useImportShopifyProducts(closeModal: () => void, listProduct?: IShopifyProduct[]) {
	const [arrayOfProductsId, setArrayOfProductsId] = useState<number[]>([]);
	const [isNotCheckedItem, setIsNotCheckedItem] = useState<boolean>(false);
	const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
	const { user } = useAppSelector((s) => s.user);

	const [importProducts, { isLoading, isSuccess, error, isError }] =
		useImportShopifyProductMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Pépites importées avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				closeModal();
				setArrayOfProductsId([]);
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		let err = error as any;
		if (isError) {
			console.log({ err });
			if (err?.status === "FETCH_ERROR") {
				Swal.fire({
					icon: "error",
					title: "Une erreur de serveur a survenue  dû à la quantité de produits importés",
					showConfirmButton: true,
					confirmButtonColor: Color.primary,
				}).then(() => {
					closeModal();
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: `Une erreur a survenue lors de l'importation`,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isError]);

	// console.log({ arrayOfProductsId });

	const handleSelectAll = (e: any) => {
		setIsCheckAll(!isCheckAll);
		if (listProduct?.length) {
			setArrayOfProductsId(listProduct.map((item) => Number(item?.id)));
		}
		if (isCheckAll) {
			setArrayOfProductsId([]);
		}
	};

	const onToggleCheckedItem = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.checked) {
			setArrayOfProductsId([...arrayOfProductsId, Number(e.currentTarget.value)]);
		} else {
			setArrayOfProductsId(
				arrayOfProductsId?.filter((id) => Number(e.currentTarget.value) !== id)
			);
		}
	};

	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!arrayOfProductsId?.length) {
			return setIsNotCheckedItem(true);
		}
		importProducts({
			boutique: user?.boutique[0]?.id,
			produits: arrayOfProductsId,
		});
		console.log({ arrayOfProductsId });
	};

	return {
		onSubmit,
		isLoadImport: isLoading,
		onToggleCheckedItem,
		user,
		isNotCheckedItem,
		handleSelectAll,
		arrayOfProductsId,
	};
}
