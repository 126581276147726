import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useDeleteSubCategoryMutation } from "../../../../../utils/api/category/category.api";
import { ICategory, Subcategory } from "../../../../../utils/api/category/category.type";
import { useDelete } from "../../../../../utils/helpers";
import { useModal } from "../../../../../utils/utils";
import { AlertInfo } from "../../../../common/Alert";
import { ButtonAdd, ButtonDelete, ButtonEdit } from "../../../../common/Button";
import AddSubCategoryModal from "../../../modals/admin/AddSubCategoryModal";

function SubCategoryTable({ category }: { category: ICategory }) {
	const { isShowModal, openModal, openEditModal, closeModal, item } = useModal<Subcategory>();

	const actionFormatter: any = (cell: string, row: Subcategory) => {
		return (
			<div className="gap-3 d-flex">
				<ButtonEdit onClick={() => openEditModal(row)} />
				<DeleteSubCategory item={row} />
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom",
			text: "Nom",
			style: { textAlign: "left" },
		},
		{
			dataField: "actions",
			text: "Actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			<div className="flex-end mb-2">
				<ButtonAdd label="Ajouter" onClick={openModal} />
			</div>
			{category?.souscategories && category?.souscategories?.length > 0 ? (
				<BootstrapTable
					keyField="id"
					data={category?.souscategories}
					columns={columns}
					bordered={true}
					condensed={false}
					responsive={true}
					wrapperClasses="table-responsive admin-table subcategory-table"
				/>
			) : (
				<AlertInfo message="Aucune Sous categorie trouvée" />
			)}
			<AddSubCategoryModal
				item={item}
				isOpen={isShowModal}
				closeModal={closeModal}
				category={category}
			/>
		</>
	);
}

export function DeleteSubCategory({ item }: { item: Subcategory }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteSubCategoryMutation();
	const onDelete = useDelete<Subcategory>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette sous-catégorie?",
		successMessage: "Sous-catégorie supprimée",
	});
	return <ButtonDelete onClick={onDelete} />;
}

export default SubCategoryTable;
