import React from "react";
import { useStep } from "react-hooks-helper";
import AddPepite from "../../user/AddPepite/AddPepite";

const AdminAddPepites = () => {
	return (
		<div className="pepites__page bg-white p-3 mt-5">
			<AddPepite />
		</div>
	);
};

export default AdminAddPepites;
