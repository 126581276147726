import React from "react";
import { IPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { Type, WhenMadeValues, WhoMadeValues } from "../../../../../utils/constants";
import Collapse from "../../../../common/Collapse";
import { DynamicInput } from "../../../../common/DynamicInput";
import { OptionalMessage } from "../../../../common/RequiredMessage";
import { Select as CustomSelect } from "../../../../common/Select";

const MoreCaracteristiques: React.FC<IPepiteProps> = ({ register, errors, setValue, pepite }) => {
	return (
		<>
			<Collapse
				id="more_caracteristiques"
				title="Plus de caractéristiques (Option facultative)"
			>
				<OptionalMessage />
				<div className="row">
					<div className="col-md-6 mb-3">
						<CustomSelect
							label="Qui a crée l'artcicle ?"
							id="who_made"
							options={WhoMadeValues}
							{...register("who_made")}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<label htmlFor="when_made">De quand date la fabrication?</label>
						<select
							id="when_made"
							className="form-select form-custom-control fas-form-select py-3"
							{...register("when_made")}
						>
							<option value="">De quand date la fabrication?</option>
							{WhenMadeValues?.map((item) => (
								<optgroup label={item?.groupName} key={item?.groupName}>
									{item?.options?.map((option) => (
										<option key={option.value} value={option.value}>
											{option?.label}
										</option>
									))}
								</optgroup>
							))}
						</select>
					</div>
					<div className="col-md-6 mb-3">
						<CustomSelect
							label="Type"
							id="type"
							options={Type}
							{...register("type")}
							error={errors?.type}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<DynamicInput
							label="Tags"
							type="text"
							id="tags"
							placeholder="Forme, couleur, style, fonction, etc."
							{...register("tags")}
							error={errors?.tags?.message}
							setValue={setValue}
							fieldName="tags"
							values={pepite?.tags?.tags}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<DynamicInput
							label="Matériaux"
							type="text"
							id="tags"
							placeholder="Ingrédients, composants etc."
							{...register("materials")}
							error={errors?.materials?.message}
							setValue={setValue}
							fieldName="materials"
							values={pepite?.materials?.materials}
						/>
					</div>
					<div className="col-md-6 mb-3">
						<DynamicInput
							label="Styles"
							type="text"
							id="tags"
							placeholder="Styles"
							{...register("style")}
							error={errors?.style?.message}
							setValue={setValue}
							fieldName="style"
							values={pepite?.style?.style}
						/>
					</div>
					{/* <div className="col-md-6">
						<label htmlFor="">Options de renouvellement</label>
						<div className="flex-m gap-4 pt-2">
							<RadioButton
								label="Automatique"
								id="oui"
								value="true"
								{...register("should_auto_renew")}
							/>
							<RadioButton
								label="Manuel"
								id="non"
								value="false"
								{...register("should_auto_renew")}
							/>
						</div>
					</div> */}
				</div>
			</Collapse>
		</>
	);
};

export default MoreCaracteristiques;
