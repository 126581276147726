import { MdDelete } from "react-icons/md";
import { PepiteInfo } from "../Stock/PepiteCard";
import { Color } from "../../../../utils/theme";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { IMarketPlace } from "../../../../utils/api/marketplace/marketplace.type";
import { getImage } from "../../../../utils/utils";
import { useVentes } from "./useVentes";
import ListVentes from "./ListVentes";

const MesVentes = () => {
	const { handleFilterPepiteByMarketplace } = useVentes();
	return (
		<div className="diffusion__page">
			<h1>Mes ventes</h1>
			<div className="panel-filter py-4 row mt-4 bg__slate">
				<div className="col-md-12 d-flex flex-column justify-content-between">
					<label htmlFor="filter" className="fas__label__imp">
						Filtrer mes pépites par marketplaces:
					</label>
					<div className="flex-m mt-2 gap-4">
						<div className="flex-m gap-2">
							<input
								type="radio"
								name="marketplace"
								className="form-check-input fas__check"
								id="etsy"
								onChange={handleFilterPepiteByMarketplace}
								value="etsy"
							/>
							<label htmlFor="etsy" className="fas__label">
								Etsy
							</label>
						</div>
						<div className="flex-m gap-2">
							<input
								type="radio"
								name="marketplace"
								className="form-check-input fas__check"
								id="ebay"
								onChange={handleFilterPepiteByMarketplace}
								value="ebay"
							/>
							<label htmlFor="ebay" className="fas__label">
								Ebay
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="pepites__container mt-4 px-md-3 px-lg-0">
				<ListVentes />
			</div>
		</div>
	);
};

interface PepiteItemProps {
	pepite?: IPepite;
	marketplace?: string;
	marketplace_detail?: IMarketPlace;
	getPepite: (item?: IPepite) => void;
	getOrderSlug: (slug?: string) => void;
	orderSlug?: string;
}
export function PepiteItem(props: PepiteItemProps) {
	const { pepite, marketplace, marketplace_detail, getPepite, getOrderSlug, orderSlug } = props;
	return (
		<div className="fashion__card px-1 py-2 row mb-3 pepite__item">
			<div className="col-md-6  position-relative">
				<div className="pepite-left-block d-md-flex gap-md-3">
					<PepiteInfo pepite={pepite} />
				</div>
			</div>
			<div className="flex-m-sb col-md-6">
				<div className="group-info">
					<h5 className="group_info_title">Vendu sur :</h5>
					<div className="flex-m gap-2">
						<div className="img__container">
							<img
								src={getImage(marketplace_detail?.logo)}
								alt={marketplace}
								className="logo-brodcast"
							/>
						</div>
					</div>
				</div>

				<div className="flex-m gap-3">
					<button
						className="btn__show"
						data-bs-toggle="modal"
						data-bs-target="#RemettrePepiteModalPepite"
						onClick={() => {
							getPepite(pepite);
							getOrderSlug(orderSlug);
						}}
					>
						Remettre dans mon stock
					</button>
					<button
						data-bs-toggle="modal"
						data-bs-target="#DeleteModalPepite"
						onClick={() => getPepite(pepite)}
					>
						<MdDelete style={{ fontSize: 20, color: Color.red }} />
					</button>
				</div>
			</div>
		</div>
	);
}

export default MesVentes;
