import { AlertInfo } from "../../../common/Alert";
import { BtnPrimary, CloseModalButton } from "../../../common/Button";
import { RadioButton } from "../../../common/Input";
import { IconSearch } from "../../../shared/Icons";
import useCollection from "./hooks/useCollection";
import { useAddPepitesToCollection } from "./hooks/useCrudCollection";
type Props = { produitsId: number[] };

const ModalChoiceCollection: React.FC<Props> = ({ produitsId }) => {
	const { collections, isLoading, setSearch } = useCollection();
	const { addPepitesToCollection, isLoad, checkedCollection, handleCheckedCollection } =
		useAddPepitesToCollection();
	return (
		<>
			<div
				className="modal fade"
				id="ModalChoiceCollection"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				aria-labelledby="ModalChoiceCollectionLabel"
				aria-hidden="true"
			>
				<div className={`modal-dialog modal-dialog-centered modal-lg`}>
					<div className="modal-content fas__modal py-4">
						<div className="d-flex justify-content-between align-items-center mb-3 border-b px-3 pb-3">
							<h4 style={{ fontWeight: 600 }}>
								Ajouter {produitsId?.length} produits à la collection{" "}
								{checkedCollection?.libelle}
							</h4>
							<CloseModalButton />
						</div>
						{/* <form> */}
						<div className="px-3 border-b mb-3">
							<div className="mb-4">
								<div className="search-input-container position-relative mt-2">
									<span
										style={{
											position: "absolute",
											top: "8px",
											left: "4px",
										}}
									>
										<IconSearch />
									</span>
									<input
										type="search"
										id="search_pepite"
										className="form-control fas__control"
										placeholder="Rechercher une collection"
										onChange={(e) => setSearch(e.currentTarget.value)}
									/>
								</div>
							</div>
							<div className="container-fluid mx-auto">
								{!isLoading ? (
									collections && collections?.length > 0 ? (
										collections?.map((item) => (
											<div key={item?.slug}>
												<RadioButton
													label={item?.libelle}
													name="collection"
													id={item.libelle}
													onChange={() => handleCheckedCollection(item)}
												/>
											</div>
										))
									) : (
										<AlertInfo message="Aucune collection trouvée" />
									)
								) : (
									<></>
								)}
							</div>
						</div>
						<div className="flex-end px-3">
							<BtnPrimary
								label="Ajouter"
								isLoading={isLoad}
								className={`${
									checkedCollection ? "btn__add" : "btn__primary btn__disabled"
								} fashion__btn`}
								mode={checkedCollection ? "Secondary" : "Primary"}
								onClick={() => addPepitesToCollection(produitsId)}
								disabled={!checkedCollection}
							/>
						</div>
						{/* </form> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalChoiceCollection;
