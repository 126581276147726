export enum TypeMarketplace {
	etsy = "etsy",
	ebay = "ebay",
}
export interface IMarketPlace {
	id: number;
	slug: string;
	logo: string;
	lien: string;
	nom: TypeMarketplace;
	type: TypeMarketplace;
}

export type MarketPlaceFormData = {
	slug?: string;
	logo: File;
	lien: string;
	nom: string;
	type: TypeMarketplace;
};

export interface ItokenStatus {
	ebay: number;
	etsy: number;
}
