import React from "react";
import { Link } from "react-router-dom";
import { BtnPrimary } from "../../../common/Button";
import { Input, InputIcon } from "../../../common/Input";
import RequiredMessage from "../../../common/RequiredMessage";
import OnboardingSuccessModal from "../../modals/user/OnboardingSuccessModal";
import AuthLayout, { AuthMobileBanner } from "../AuthLayout";
import useSigninForm from "../useAuthForm/useSigninForm";

const SignIn: React.FC = () => {
	const { register, onSubmit, errors, isLoading } = useSigninForm();
	return (
		<AuthLayout>
			<AuthMobileBanner title="Connexion" />
			<div className="px-2 px-md-4 px-lg-1">
				<div className="d-lg-none mt-3">
					<h2 className="auth-title-mobile text-sm-center">Se connecter</h2>
				</div>
				<div className="mb-4">
					<p className="auth-accroche-text">
						Diffuser vos pépites sur plusieurs marketplaces n’a jamais été aussi simple
						!
					</p>
				</div>
				<form onSubmit={onSubmit}>
					<RequiredMessage />
					<Input
						label="Adresse mail*"
						type="text"
						id="email"
						{...register("email")}
						error={errors?.email}
					/>
					<InputIcon
						label="Mot de passe*"
						id="password"
						{...register("password")}
						error={errors?.password}
					/>
					<div className="flex-end">
						<Link to="/mot-de-passe-oublie" className="auth-link">
							Mot de passe oublié ?
						</Link>
					</div>
					<div className="btn-container mt-5">
						<BtnPrimary label="Se connecter" width="w-100" isLoading={isLoading} />
					</div>
					<div className="auth-redirect flex-m-sb mt-3">
						<p className="auth-redirect-question">
							Pas encore membre sur notre plateforme ?
						</p>
						<Link to="/inscription" className="auth-link">
							Inscrivez-vous ici
						</Link>
					</div>
				</form>
			</div>
			<OnboardingSuccessModal />
		</AuthLayout>
	);
};

export default SignIn;
