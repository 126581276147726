import { configureStore } from "@reduxjs/toolkit";
import { AdminApi } from "../utils/api/admin/admin.api";
import { AuthApi } from "../utils/api/auth/auth.api";
import { BoutiqueApi } from "../utils/api/boutique/boutique.api";
import { CategoryApi } from "../utils/api/category/category.api";
import { CouleurApi } from "../utils/api/couleur/couleur.api";
import { VendeurApi } from "../utils/api/vendeur/vendeur.api";
import { EtatApi } from "../utils/api/etat/etat.api";
import { MarketPlaceApi } from "../utils/api/marketplace/marketplace.api";
import { OfferApi } from "../utils/api/offer/offer.api";
import { PaymentModeApi } from "../utils/api/paymentMode/paymentMode.api";
import { PepiteApi } from "../utils/api/pepite/pepite.api";
import { TailleApi } from "../utils/api/taille/taille.api";
import { UserApi } from "../utils/api/user/user.api";
import { FaqApi } from "../utils/api/faq/faq.api";
import { currentEnv, Env } from "../utils/http";
import { UserSlice } from "./slice/User.slice";
import { ContactApi } from "../utils/api/contact/contact.api";
import { CaracteristiquePepiteSlice } from "./slice/caracteristique.slice";
import { AbonnementApi } from "../utils/api/abonnement/abonnement.api";
import { CollectionApi } from "../utils/api/collection/collection.api";

const store = configureStore({
	reducer: {
		[AuthApi.reducerPath]: AuthApi.reducer,
		[UserSlice.name]: UserSlice.reducer,
		[UserApi.reducerPath]: UserApi.reducer,
		[AdminApi.reducerPath]: AdminApi.reducer,
		[PaymentModeApi.reducerPath]: PaymentModeApi.reducer,
		[OfferApi.reducerPath]: OfferApi.reducer,
		[PepiteApi.reducerPath]: PepiteApi.reducer,
		[CategoryApi.reducerPath]: CategoryApi.reducer,
		[TailleApi.reducerPath]: TailleApi.reducer,
		[EtatApi.reducerPath]: EtatApi.reducer,
		[CouleurApi.reducerPath]: CouleurApi.reducer,
		[EtatApi.reducerPath]: EtatApi.reducer,
		[VendeurApi.reducerPath]: VendeurApi.reducer,
		[MarketPlaceApi.reducerPath]: MarketPlaceApi.reducer,
		[BoutiqueApi.reducerPath]: BoutiqueApi.reducer,
		[FaqApi.reducerPath]: FaqApi.reducer,
		[ContactApi.reducerPath]: ContactApi.reducer,
		[CaracteristiquePepiteSlice.name]: CaracteristiquePepiteSlice.reducer,
		[AbonnementApi.reducerPath]: AbonnementApi.reducer,
		[CollectionApi.reducerPath]: CollectionApi.reducer,
	},
	devTools: Env === currentEnv,
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware(),
		AuthApi.middleware,
		UserApi.middleware,
		AdminApi.middleware,
		PaymentModeApi.middleware,
		OfferApi.middleware,
		PepiteApi.middleware,
		CategoryApi.middleware,
		TailleApi.middleware,
		EtatApi.middleware,
		CouleurApi.middleware,
		VendeurApi.middleware,
		MarketPlaceApi.middleware,
		BoutiqueApi.middleware,
		FaqApi.middleware,
		ContactApi.middleware,
		AbonnementApi.middleware,
		CollectionApi.middleware,
	],
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
