const StockSPepitesData = [
	{
		id: 1,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 2,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 3,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 4,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 5,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 6,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 7,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 8,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 9,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
	{
		id: 10,
		name: "Veste en jean Old school",
		statut: "En Ligne",
	},
];

export default StockSPepitesData;
