import React from "react";
import { IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { BtnPrimary } from "../../../../common/Button";
import { FormError } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";
import { IconEuro } from "../../../../shared/Icons";

const Prix: React.FC<IStepsCrudPepiteProps> = ({ register, onSubmit, errors }) => {
	return (
		<>
			<h2>Prix de vente</h2>
			<form action="" className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="col-md-6">
					<div className="input-group mb-3">
						<input
							type="text"
							className="form-control form-custom-control py-3 price-input"
							placeholder="Prix"
							aria-label="price"
							aria-describedby="basic-input"
							{...register("prix")}
						/>
						<span className="input-group-text" id="basic-input">
							<IconEuro />
						</span>
					</div>
					<FormError error={errors?.prix} />
				</div>
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</>
	);
};

export default Prix;
