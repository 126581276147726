import packageJson from "../../../package.json";
import { Color } from "../../utils/theme";

const VersionApp = () => {
	return (
		<div
			className="verion-container"
			style={{ position: "absolute", bottom: "20px", color: Color.dark, fontWeight: 400 }}
		>
			<p>v{packageJson.version}</p>
		</div>
	);
};

export default VersionApp;
