import React from "react";
import { BsPersonFill } from "react-icons/bs";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { TbBuildingStore } from "react-icons/tb";
import { useDashboardAdminQuery } from "../../../../utils/api/admin/admin.api";
import { Color } from "../../../../utils/theme";
import { IconBoldGame } from "../../../shared/Icons";
import UserLineChart from "./LineChart/UserLineChart";
import grid from "../../../../assets/images/grid.png";
import store from "../../../../assets/images/store.png";

const AdminDashboard = () => {
	const { data } = useDashboardAdminQuery();
	return (
		<div className="dashboard__admin px-2">
			<h1>Dashboard</h1>
			<div className="row my-5">
				<CardItem
					icon={<BsPersonFill style={{ color: Color.adminPrimary, fontSize: 40 }} />}
					total={data?.users_count}
					title="Utilisateurs"
				/>
				<CardItem img={grid} total={data?.pepites_count} title="Pépites" width="38px" />
				<CardItem
					img={store}
					total={data?.markeplaces_count}
					title="Marketplaces"
					width="55px"
				/>
				<CardItem
					icon={
						<HiOutlineChartSquareBar
							style={{ color: Color.adminPrimary, fontSize: 40 }}
						/>
					}
					total={data?.subscriber_count}
					title="Abonnements"
				/>
			</div>

			{/* Charts */}
			<UserLineChart title="Utilisateurs" />
			<UserLineChart title="Chiffre d’affaires" />
		</div>
	);
};

interface Propstype {
	icon?: React.ReactNode;
	total?: number;
	title: string;
	img?: string;
	width?: string;
}

function CardItem({ icon, total, title, img, width }: Propstype) {
	return (
		<div className="col-md-3 mb-3 col-sm-6">
			<div
				className="dash-card d-flex  align-items-center justify-content-center gap-4 py-4"
				style={{ background: "#FFFFFF", borderRadius: 10 }}
			>
				{icon && <div>{icon}</div>}
				{img && (
					<div className="img-container">
						<img src={img} alt="card-img" width={width} />
					</div>
				)}
				<div className="card_infos">
					<h4
						className="card_total"
						style={{
							fontWeight: 800,
							color: "#000000",
							lineHeight: "41px",
							fontSize: "clamp(20px, 2vw, 30px)",
							opacity: 0.8,
							whiteSpace: "nowrap",
						}}
					>
						{total}
					</h4>
					<h6
						className="card_label"
						style={{
							fontWeight: 400,
							color: "#030229",
							lineHeight: "19px",
							fontSize: 14,
							opacity: 0.7,
						}}
					>
						{title}
					</h6>
				</div>
			</div>
		</div>
	);
}

export default AdminDashboard;
