import React, { useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import "./AddPepite.css";
import Description from "./Steps/Description";
import Caracteristiques from "./Steps/Caracteristiques";
import Photos from "./Steps/Photos";
import Prix from "./Steps/Prix";
import Diffusion from "./Steps/Diffusion";
import Valider from "./Steps/Valider";
import StepperVertical from "../../../common/StepperVertical";
import Popup from "../../../common/Popup";
import useAddPepiteForm from "./useForm/useAddPepiteForm";
import { MyStepperContext } from "../../../../utils/StepperContext";

const steps = [
	{
		id: "description",
		Component: Description,
	},
	{
		id: "caracteristiques",
		Component: Caracteristiques,
	},
	{
		id: "photos",
		Component: Photos,
	},
	{
		id: "prix",
		Component: Prix,
	},
	{
		id: "diffusion",
		Component: Diffusion,
	},
	{
		id: "valider",
		Component: Valider,
	},
];

const levels = ["Description", "Caractéristiques", "Photos", "Prix", "Diffusion", "Valider"];

const AddPepite = () => {
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		setValue,
		images,
		onSelectImages,
		Controller,
		control,
		onChangeColors,
		onChangeSize,
		getSelectedBoutiqueItem,
		formValues,
	} = useAddPepiteForm(navigation);
	const { Component } = step;
	const props = {
		navigation,
		index,
		register,
		onSubmit,
		errors,
		isLoading,
		setValue,
		images,
		onSelectImages,
		Controller,
		control,
		onChangeColors,
		onChangeSize,
		getSelectedBoutiqueItem,
		formValues,
	};

	useEffect(() => {
		window.scrollTo(10, 10);
		setValue("step", index + 1);
	}, [index]);

	const onClickStep = (key: number) => {
		if (index > key) {
			navigation.go(key);
			setValue("step", key);
		}
	};

	return (
		<div className="add__pepite_page">
			<h1>Ajouter une pépite</h1>
			<div className="row">
				<div className="col-md-3 view-md d-lg-none">
					<Popup />
				</div>
				<MyStepperContext.Provider value={{ onClickStep }}>
					<div className="col-md-9 col-lg-12">
						<div className="mt-5 row  d-md-flex flex-row-reverse">
							<div className="col-md-3 col-xl-2">
								<StepperVertical index={index} steps={levels} />
							</div>
							<div className="fashion__card px-xl-3 px-1 py-3 col-xl-10 col-md-9">
								<Component {...props} />
							</div>
						</div>
					</div>
				</MyStepperContext.Provider>
			</div>
		</div>
	);
};

export default AddPepite;
