import React from "react";

export const Color = {
	primary: "#C3A083",
	secondary: "#FFF1E6",
	plight: "#F2BA8C",
	dark: "#33302E",
	success: "#2EB75C",
	red: "#C22A29",
	adminPrimary: "#D6B193",
	white: "#FFFFFF",
};

export const ButtonStatusStyle = {
	borderRadius: 100,
	padding: ".5rem 1rem",
	fontWeight: 500,
	textTransform: "uppercase",
	fontSize: 9,
	lineHeight: "11px",
} as React.CSSProperties;
