import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import {
	useCollectionFromLocation,
	useRemoveProductFromCollectionMutation,
} from "../../../../utils/api/collection/collection.api";
import { ICollection } from "../../../../utils/api/collection/collection.type";
import { IPepite } from "../../../../utils/api/pepite/pepite.type";
import { Color } from "../../../../utils/theme";
import { useLocationState } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import { BtnBack } from "../../../common/Button";
import DiffuserModalPepite from "../Diffuser/DiffuserModalPepite";
import PepiteCard from "../Stock/PepiteCard";
import { useCheckTokenStatus, useGetPepite } from "../Stock/useStock";

const DetailCollection = () => {
	const { currentCollection, isLoading } = useCollectionFromLocation();
	const { item, getCurrentPepite } = useGetPepite();
	const { tokenStatus, onCheckTokenStatus } = useCheckTokenStatus();
	return (
		<div className="collection__page">
			<div>
				<BtnBack label="Revenir sur mes collections" />
			</div>
			<div className="d-flex flex-column flex-md-row justify-content-md-between gap-3 mt-3">
				<h1>{currentCollection?.libelle}</h1>
			</div>
			<div className="row pt-3">
				{!isLoading ? (
					currentCollection && currentCollection?.produits.length > 0 ? (
						currentCollection?.produits?.map((item) => (
							<PepiteCard
								key={item?.slug}
								path="/user/mon-stock/pepites/"
								pepite={item}
								getCurrentPepite={getCurrentPepite}
								sales={item?.sales}
								checkTokenStatus={onCheckTokenStatus}
								collectionSlug={currentCollection?.slug}
							/>
						))
					) : (
						<AlertInfo message="Aucun produit trouvé pour cette collection" />
					)
				) : (
					<></>
				)}
			</div>
			<DiffuserModalPepite item={item} tokenStatus={tokenStatus} />
		</div>
	);
};

export function DeleteProductFromCollection(props: { item: IPepite; collectionSlug: string }) {
	const [deleteItem, { isSuccess, isError, error }] = useRemoveProductFromCollectionMutation();

	const { item, collectionSlug } = props;
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir retirer cette pépite de la collection ?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			iconColor: Color.red,
			confirmButtonColor: Color.red,
			preConfirm: () => {
				return deleteItem({ slug_p: item.slug, slug_c: collectionSlug });
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `Pépite retirée de la collection avec succès!`,
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: err?.data?.message ? err?.data?.message : `Une erreur a survenue`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isError]);
	return (
		<button className="btn_action btn__delete" data-bs-toggle="modal" onClick={onDelete}>
			Rétirer
		</button>
	);
}

export default DetailCollection;
