import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../utils";
import { IPepite } from "../pepite/pepite.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { AddProductsCollectionType, CollectionFormData, ICollection } from "./collection.type";

export const CollectionApi = createApi({
	reducerPath: "collectionsApi",
	tagTypes: ["collections", "collection"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCollection: builder.query<PaginationResults<ICollection>, TypeQuery>({
			query: (query) => QueryUrl(`collections/`, query),
			providesTags: ["collections"],
		}),
		getCollectionBySeller: builder.query<
			PaginationResults<ICollection>,
			TypeQuery & { nom?: string }
		>({
			query: ({ slug, ...query }) => QueryUrl(`user/${slug}/collections/`, query),
			providesTags: ["collections"],
		}),
		addOrUpdateCollection: builder.mutation<
			ICollection,
			{ slug?: string; data: CollectionFormData | FormData }
		>({
			invalidatesTags: ["collections"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `collections/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `collections/`,
					method: "POST",
					body: data,
				};
			},
		}),
		addPepitesToCollection: builder.mutation<ICollection, AddProductsCollectionType>({
			invalidatesTags: ["collections"],
			query: (data) => {
				return {
					url: `add_product_in_collection/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCollection: builder.mutation<ICollection, string>({
			query: (slug) => ({
				url: `collections/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["collections"],
		}),
		findCollectionBySlug: builder.query<ICollection, string>({
			query: (slug) => ({
				url: `collections/${slug}/`,
			}),
			providesTags: ["collections", "collection"],
		}),
		removeProductFromCollection: builder.mutation<IPepite, { slug_p: string; slug_c: string }>({
			query: ({ slug_c, slug_p }) => ({
				url: `remove_product_from_collections/${slug_p}/${slug_c}`,
				method: "GET",
			}),
			invalidatesTags: ["collections", "collection"],
		}),
	}),
});

export const {
	useGetCollectionQuery,
	useLazyGetCollectionQuery,
	useAddOrUpdateCollectionMutation,
	useDeleteCollectionMutation,
	useAddPepitesToCollectionMutation,
	useGetCollectionBySellerQuery,
	useRemoveProductFromCollectionMutation,
	useLazyFindCollectionBySlugQuery,
} = CollectionApi;

export function useCollectionFromLocation() {
	const itemState = useLocationState<ICollection | undefined>(undefined);
	const [currentCollection, setCurrentCollection] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyFindCollectionBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);

	useEffect(() => {
		if (isError && !itemState) {
			navigate("-1", { replace: false });
		}
	}, [isError]);

	useEffect(() => {
		if (data) {
			setCurrentCollection(data);
		}
	}, [data]);

	return {
		currentCollection,
		isLoading,
	};
}
