import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import { useLazyGetBoutiqueQuery } from "../../../../../utils/api/boutique/boutique.api";
import { IBoutique } from "../../../../../utils/api/boutique/boutique.type";
import { IPepite, IStepsCrudPepiteProps } from "../../../../../utils/api/pepite/pepite.type";
import { UserType } from "../../../../../utils/api/user/user.type";
import { BtnPrimary } from "../../../../common/Button";
import { CustomInput, FormError } from "../../../../common/Input";
import RequiredMessage from "../../../../common/RequiredMessage";
import ReactQuill from "react-quill";

const Description: React.FC<IStepsCrudPepiteProps> = ({
	register,
	onSubmit,
	errors,
	getSelectedBoutiqueItem,
	item,
	setValue,
}) => {
	const [description, setDescription] = useState<string>("");

	const { search, boutiques, user, onChangeBoutiqueInput, cleanState, selectedItem } =
		usePepiteDescription(item);

	const onChangeDescription = (data: string) => {
		setDescription(data);
		setValue("description", data);
	};
	useEffect(() => {
		if (item) {
			setDescription(item?.description);
			setValue("description", item?.description);
		}
	}, [item]);
	return (
		<>
			<h2>Décrivez votre pépite</h2>
			<form className="pt-4" onSubmit={onSubmit}>
				<RequiredMessage />
				<div className="row">
					<div className="col-md-6 mb-3">
						<CustomInput
							label="Titre de la pépite"
							type="text"
							id="title"
							firstLabel="Titre*"
							{...register("nom")}
							error={errors?.nom}
						/>
					</div>
					{user && (
						<div className="col-md-6 mb-3">
							<CustomInput
								label="Boutique"
								type="text"
								id="title"
								firstLabel="Boutique*"
								value={selectedItem}
								onChange={onChangeBoutiqueInput}
							/>
							{search && search?.length > 2 ? (
								<BoutiqueAutoCompleteLists
									boutiques={boutiques}
									cleanState={cleanState}
									getSelectedBoutiqueItem={getSelectedBoutiqueItem}
								/>
							) : (
								<>
									{!selectedItem ? (
										<span className="text-indicatif">
											Saisissez au moins 3 caractères
										</span>
									) : null}
								</>
							)}
							<FormError error={errors?.boutique?.message} />
						</div>
					)}
					<div className="col-12">
						<ReactQuill
							className="editor-cgu"
							value={description}
							onChange={onChangeDescription}
						/>
						<FormError error={errors?.description} />
					</div>
				</div>
				<div className="btn-container flex-end mt-5">
					<BtnPrimary label="Suivant" />
				</div>
			</form>
		</>
	);
};

function BoutiqueAutoCompleteLists(props: {
	boutiques?: IBoutique[];
	cleanState: (item: IBoutique) => void;
	getSelectedBoutiqueItem: (item: IBoutique) => void;
}) {
	const { boutiques, cleanState, getSelectedBoutiqueItem } = props;
	return (
		<ul className="py-2 px-0 shadow-lg boutique-items-container">
			{boutiques && boutiques?.length > 0 ? (
				boutiques?.map((item: IBoutique) => (
					<li
						key={item?.slug}
						className="px-2 py-1"
						onClick={() => {
							cleanState(item);
							getSelectedBoutiqueItem(item);
						}}
					>
						{item?.nom_boutique}
					</li>
				))
			) : (
				<span className="text-indicatif text-result">Aucune boutique trouvée</span>
			)}
		</ul>
	);
}

const usePepiteDescription = (item: IPepite) => {
	const [search, setSearch] = useState<string>();
	const [selectedItem, setSelectedItem] = useState<string>();
	const { user } = useAppSelector((s) => s?.user);
	const [findBoutiques, { data }] = useLazyGetBoutiqueQuery();
	const onChangeBoutiqueInput = (e: React.FormEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
		setSelectedItem(e.currentTarget.value);
	};
	const cleanState = (elem: IBoutique) => {
		setSelectedItem(elem?.nom_boutique);
		setSearch("");
	};

	useEffect(() => {
		if (search && search?.length > 2) {
			findBoutiques({ nom_boutique: search });
		}
	}, [search]);

	useEffect(() => {
		if (item) {
			setSelectedItem(item?.boutique?.nom_boutique);
		}
	}, [item]);

	return {
		search,
		selectedItem,
		user: user && user?.user_type !== UserType?.vendeur,
		boutiques: data?.results,
		onChangeBoutiqueInput,
		cleanState: cleanState,
	};
};

export default Description;
