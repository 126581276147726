import React, { InputHTMLAttributes, useState } from "react";
import { FieldError } from "react-hook-form";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import ErrorMessage from "./ErrorMessage";
type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	error?: string | FieldError;
};
export const Input = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { label, id, required, error, ...rest } = props;
	return (
		<div className="mb-3">
			<div className="form-floating">
				<input
					className="form-control form-custom-control"
					placeholder={label}
					id={id}
					{...rest}
					ref={ref}
				/>
				<label htmlFor={id} className="form-label">
					{label}
				</label>
			</div>
			{<FormError error={error} />}
		</div>
	);
});
export const CustomInput = React.forwardRef<HTMLInputElement, InputPropsType & {firstLabel: string}>((props, ref) => {
	const { label, id, firstLabel, required, error, ...rest } = props;
	return (
		<>
			<label htmlFor={id} className="form-first-label">
				{firstLabel}
			</label>
			<div className="form-floating mb-1">
				<input
					className="form-control form-custom-control"
					placeholder={label}
					id={id}
					{...rest}
					ref={ref}
				/>
				<label htmlFor={id} className="form-label">
					{label}
				</label>
			</div>
			{<FormError error={error} />}
		</>
	);
});
export const InputIcon = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { label, id, required, error, ...rest } = props;
	const [showPassword, setShowPassword] = useState<boolean>();
	const toggleShowPassword = () => {
		setShowPassword((showPassword) => !showPassword);
	};
	return (
		<div className="mb-3">
			<div className="input-group position-relative">
				<div className="form-floating">
					<input
						className="form-control form-custom-control form-icon pr-5"
						placeholder={label}
						type={showPassword ? "text" : "password"}
						id={id}
						{...rest}
						ref={ref}
						style={{ borderRight: "none" }}
					/>
					<label htmlFor={id} className="form-label">
						{label}
					</label>
				</div>
				<button
					className="input-group-text"
					style={{ backgroundColor: "transparent" }}
					onClick={toggleShowPassword}
					type="button"
				>
					{!showPassword ? (
						<BsEyeFill
							style={{
								fontSize: "22px",
								color: "#DADADA",
							}}
						/>
					) : (
						<BsEyeSlashFill
							style={{
								fontSize: "22px",
								color: "#DADADA",
							}}
						/>
					)}
				</button>
			</div>
			{<FormError error={error} />}
		</div>
	);
});

export const RadioButton = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { label, id, required, ...rest } = props;
	return (
		<>
			<div className="flex-m gap-2 mb-3">
				<input
					className="form-check-input fas__check"
					id={id}
					{...rest}
					ref={ref}
					type="radio"
				/>
				<label htmlFor={id} className="fas__label m-0">
					{label}
				</label>
			</div>
		</>
	);
});

export const InputFilter = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { label, id, required, ...rest } = props;
	return (
		<input
			id={id}
			{...rest}
			ref={ref}
			placeholder={label}
			style={{
				background: "#ECE5DF",
				borderRadius: 10,
				outline: "none",
				border: "none",
				fontWeight: 400,
				fontSize: "14px",
				lineHeight: " 18px",
				padding: "1rem",
				marginRight: "1rem",
			}}
		/>
	);
});

export const InputAdmin = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { label, id, required, error, ...rest } = props;
	return (
		<>
			<div className="mb-3">
				<label htmlFor={id} className="form-admin-label">
					{label}
				</label>
				<input
					className="form-control form-control-lg form-control-admin"
					id={id}
					{...rest}
					ref={ref}
				/>
				{<FormError error={error} />}
			</div>
		</>
	);
});

export function FormError({ error }: { error?: string | FieldError }) {
	const message = typeof error === "string" ? error : error ? error.message : error;
	if (!message) return null;

	return <ErrorMessage message={message} />;
}