import React from "react";
import { FiLogOut } from "react-icons/fi";
import Swal from "sweetalert2";
import { useAppDispatch } from "../../../../redux/hooks";
import { onlogout } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";
import { onHide } from "../../../../utils/utils";

const LogoutModal = () => {
	const dispatch = useAppDispatch();
	const onLogout = () => {
		dispatch(onlogout());
		onHide("LogoutModal");
	};
	return (
		<div
			className="modal fade"
			id="LogoutModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="LogoutModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<>
							<div className="modal__header">
								<div className="flex-c mb-3">
									<FiLogOut style={{ fontSize: 36, color: Color.red }} />
								</div>
								<h2 className="modal__title">Déconnexion</h2>
							</div>
							<div className="modal__body mt-3">
								<h3 className="modal-ask-logout mb-4">
									Souhaitez-vous vous déconnecter de votre compte TFT Solution ?
								</h3>
							</div>
							<div className="modal__footer flex-end gap-5 mt-5">
								<button
									type="button"
									className="fashion__btn"
									data-bs-dismiss="modal"
									style={{ color: Color.primary }}
								>
									Non
								</button>
								<button
									type="button"
									className="fashion__btn btn__primary btn__danger w-50"
									style={{
										borderRadius: "4px",
										background: Color.red,
										color: "#fff",
									}}
									onClick={onLogout}
								>
									Se déconnecter
								</button>
							</div>
						</>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LogoutModal;
