import { Outlet } from "react-router-dom";
import SideBarUser from "./SideBar/SidebarUser";
import TopBar from "./TopBar/TopBar";
import "./Layout.css";
import LogoutModal from "../../modals/user/LogoutModal";
import NotifyBanner from "../../../common/NotifyBanner";
import ContactModal from "../../modals/user/ContactModal";

const UserLayout = () => {
	return (
		<div className="layout__dashboard px-3 px-lg-5 py-3 contaier-fluid">
			<NotifyBanner />
			<TopBar />
			<SideBarUser />
			<div className="flex-end">
				<div className="outlet__container">
					<Outlet />
					<LogoutModal />
					<ContactModal />
				</div>
			</div>
		</div>
	);
};

export default UserLayout;
