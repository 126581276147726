import { Link, NavLink } from "react-router-dom";
import Logo from "../../../../../assets/images/Logo.png";
import "./SidebarUser.css";
import { Routes } from "../UserRoutes/UserRoutes";
import React from "react";
import Popup from "../../../../common/Popup";
import useWindowDimensions from "../../../../../utils/utils";
import VersionApp from "../../../../common/VersionApp";

const SideBarUser = () => {
	return (
		<div className="sidebar view-lg">
			<LogoApp />
			<MenuContent />
			<VersionApp />
		</div>
	);
};

export function LogoApp({ path = "/user/dashboard" }: { path?: string }) {
	return (
		<Link className="logo-container pt-3" to={path}>
			<img src={Logo} alt="fashion-logo" className="logo-app" />
		</Link>
	);
}

export function MenuContent() {
	const links = Routes;
	const { width } = useWindowDimensions();
	return (
		<React.Fragment>
			{/* {showMenu ? ( */}
			<ul className="sidebar-links mt-5 p-0">
				{width > 991 ? (
					<>
						{links.map((link, index) => (
							<li key={index} className="mb-4 position-relative">
								<NavLink
									to={link.pathname}
									className={({ isActive }) =>
										`sidebar-link flex-m gap-2 ${isActive ? "active" : ""}`
									}
								>
									{link.icon}
									{link.label}
								</NavLink>
							</li>
						))}
					</>
				) : (
					<>
						{links.map((link, index) => (
							<li
								key={index}
								className="mb-4 position-relative"
								data-bs-dismiss="offcanvas"
							>
								<NavLink
									to={link.pathname}
									className={({ isActive }) =>
										`sidebar-link flex-m gap-2 ${isActive ? "active" : ""}`
									}
								>
									{link.icon}
									{link.label}
								</NavLink>
							</li>
						))}
					</>
				)}
			</ul>
			{/* ) : ( */}
			<Popup />
			{/* )} */}
		</React.Fragment>
	);
}

export default SideBarUser;
