import React from "react";
import { IOffer } from "../../../../utils/api/offer/offer.type";
import { Color } from "../../../../utils/theme";
import { BtnPrimary } from "../../../common/Button";
import { ModePaiementsLists } from "./ChooseAbonnementModal";
import { useRenewAbonnement } from "./hooks/useRenewAbonnement";

const ChooseRenewAbonnementModal = ({ item }: { item?: IOffer }) => {
	const { onSubmit, errors, handleChoiceModePaiement, checkedModePayementId, isLoading } =
		useRenewAbonnement(item);
	return (
		<div
			className="modal fade"
			id="ChooseRenewAbonnementModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="ChooseRenewAbonnementModalLabel"
			aria-hidden="true"
		>
			<div className={`modal-dialog modal-dialog-centered modal-md`}>
				<div className="modal-content fas__modal  px-3 py-4">
					<div className="container-fluid mx-auto">
						<h1 className="payement-modal-title mb-4">Choisir un mode de paiement</h1>
						<form onSubmit={onSubmit}>
							<ModePaiementsLists
								handleChoiceModePaiement={handleChoiceModePaiement}
								checkedModePayementId={checkedModePayementId}
								errors={errors}
							/>
							<div className="d-flex justify-content-center gap-5 mt-5">
								<BtnPrimary
									label={"Valider"}
									mode="Secondary"
									isLoading={isLoading}
								/>
								<button
									type="button"
									className="fashion__btn btn__primary btn__danger"
									style={{
										borderRadius: "4px",
										color: Color.red,
									}}
									data-bs-dismiss="modal"
								>
									annuller
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChooseRenewAbonnementModal;
