import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../user/user.type";
import { CouleurFormData, ICouleur } from "./couleur.type";

export const CouleurApi = createApi({
	reducerPath: "couleurAPi",
	tagTypes: ["couleur"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}/api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCouleur: builder.query<PaginationResults<ICouleur>, void>({
			query: () => `couleurs/`,
			providesTags: ["couleur"],
		}),
		addOrUpdateCouleur: builder.mutation<
			ICouleur,
			{ slug?: string; data: CouleurFormData | FormData }
		>({
			invalidatesTags: ["couleur"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `couleurs/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `couleurs/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCouleur: builder.mutation<ICouleur, string>({
			query: (slug) => ({
				url: `couleurs/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["couleur"],
		}),
	}),
});

export const {
	useGetCouleurQuery,
	useLazyGetCouleurQuery,
	useAddOrUpdateCouleurMutation,
	useDeleteCouleurMutation,
} = CouleurApi;
