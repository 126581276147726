export enum DeviseType {
	euro = "EUR",
	xof = "XOF",
	usd = "USD",
}
export enum FrequenceType {
	DAY = "DAY",
	MONTH = "MONTH",
	YEAR = "YEAR",
	ILLIMITE = "ILLIMITE",
}
export enum OfferOptionType {
	FREEMIUM = "FREEMIUM",
	BASIC = "BASIC",
	ADVANCED = "ADVANCED",
}
export interface IOffer {
	id: number;
	slug: string;
	title: string;
	price: number;
	description: string;
	duration: number;
	devise: DeviseType;
	created_at: string;
	frequence: FrequenceType;
	option: OfferOptionType;
	details: string;
	position: number;
}

export type OfferFormData = Partial<IOffer>;
