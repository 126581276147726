import React from "react";
import { MdLogout } from "react-icons/md";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../../../../utils/utils";
import VersionApp from "../../../../common/VersionApp";
import { LogoApp } from "../../../user/Layouts/SideBar/SidebarUser";
import { Routes } from "../AdminRoutes/AdminRoutes";
import "./SidebarAdmin.css";

const SideBarUser = () => {
	return (
		<div className="sidebar-admin ps-3 ps-lg-5 py-3 view-lg shadow">
			<LogoApp path="/admin" />
			<MenuContent />
			<VersionApp />
		</div>
	);
};

export function MenuContent() {
	const { width } = useWindowDimensions();
	const links = Routes;
	return (
		<React.Fragment>
			<ul className="sidebar-admin-links mt-5 p-0">
				{width > 991 ? (
					<>
						{links.map((link, index) => (
							<li key={index} className="mb-5 position-relative">
								<NavLink
									to={link.pathname}
									className={({ isActive }) =>
										`sidebar-admin-link flex-m gap-2 ${
											isActive ? "active" : ""
										}`
									}
								>
									{link.icon}
									{link.label}
								</NavLink>
							</li>
						))}
					</>
				) : (
					<>
						{links.map((link, index) => (
							<li
								key={index}
								className="mb-5 position-relative"
								data-bs-dismiss="offcanvas"
							>
								<NavLink
									to={link.pathname}
									className={({ isActive }) =>
										`sidebar-admin-link flex-m gap-2 ${
											isActive ? "active" : ""
										}`
									}
								>
									{link.icon}
									{link.label}
								</NavLink>
							</li>
						))}
					</>
				)}
				<li>
					<button
						className="admin_btn_logout d-flex align-items-center"
						data-bs-target="#LogoutModal"
						data-bs-toggle="modal"
					>
						<MdLogout />
						<span>Se déconnecter</span>
					</button>
				</li>
			</ul>
		</React.Fragment>
	);
}

export default SideBarUser;
