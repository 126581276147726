import React from "react";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { onHide } from "../../../../utils/utils";

const OnboardingSuccessModal = () => {
	const navigate = useNavigate();
	return (
		<div
			className="modal fade"
			id="OnboardingSuccessModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="OnboardingSuccessModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content modal-shadow fas__modal  px-3 py-5">
					<div className="container-fluid mx-auto">
						<form action="">
							<div className="modal__header">
								<h2 className="modal__title">Félicitations 🎉</h2>
							</div>
							<div className="modal__body text-center mt-3 mb-4">
								<h3
									className="success-onboarding common_style text-lg font_urbanist mb-4"
									style={{ color: "#01465E" }}
								>
									Nous sommes heureuses de vous compter parmi nos membres.
								</h3>
								<p
									className="common_style text-lg font_urbanist"
									style={{ color: "#66605C", fontWeight: 500 }}
								>
									Vous pouvez dès à présent utiliser la plateforme en vous
									connectant !
								</p>
							</div>
							<div className="flex-center  gap-3 gap-sm-5 mt-5">
								{/* <button
									type="button"
									className="fashion__btn"
									data-bs-dismiss="modal"
									style={{ color: Color.primary }}
									onClick={() => navigate("/user/dashboard")}
								>
									pas maintenant
								</button> */}
								<button
									type="button"
									className="fashion__btn btn__primary"
									data-bs-dismiss="modal"
									style={{ color: Color.dark, backgroundColor: Color.plight }}
									onClick={() => {
										onHide("OnboardingSuccessModal");
										navigate("/se-connecter");
									}}
								>
									Se connecter
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingSuccessModal;
