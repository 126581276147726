import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { IAdmin } from "../utils/api/admin/admin.type";
import { IUser, UserType } from "../utils/api/user/user.type";
import { Authorisations } from "../utils/constants";

interface ProtectedRoutesProps {
	children: ReactElement;
	userType: UserType[];
}
export const ProtectedRoutes = ({ children, userType }: ProtectedRoutesProps) => {
	let location = useLocation();
	const { user } = useAppSelector((state) => state.user);

	const isAccess = userType?.includes(user?.user_type);
	const auth = user?.id;
	return isAccess && isAccess !== undefined ? (
		children
	) : auth && auth !== undefined ? (
		<Navigate to="/" replace />
	) : (
		<Navigate to="/se-connecter" state={{ from: location }} replace />
	);
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
	const { token, user } = useAppSelector((state) => state.user);
	let path = "/user";
	if (user?.user_type === UserType.admin) {
		for (let index = 0; index < Authorisations.length; index++) {
			const element = Authorisations[index];
			if (user[`${element?.field}`] === true) {
				path = element.path;
				break;
			}
		}
	} else if (isSuperAdmin(user)) {
		path = "/admin/dashboard";
	}
	return !token && !user?.id ? children : <Navigate to={path} replace />;
};

export function isAdmin(user?: IUser | IAdmin) {
	return user && (user.user_type === UserType.admin || user.user_type === UserType.superAdmin);
}

export function isSuperAdmin(user?: IUser | IAdmin) {
	return user && user.user_type === UserType.superAdmin;
}
