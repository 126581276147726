import { useStep } from "react-hooks-helper";
import { PaymentMode } from "../../../../utils/api/paymentMode/paymentMode.type";
import { useStepType } from "../../../../utils/type";
import { useModal } from "../../../../utils/utils";
import { ButtonAdd } from "../../../common/Button";
import AddAdminModal from "../../modals/admin/AddAdminModal";
import AdminsTable from "../../tables/admin/AdminsTable /AdminsTable";
import { AdminCard } from "../AdminLayouts/AdminLayout";
import Caracteristiques from "./Caracteristiques";
import ModePaiement from "./ModePaiement";

const steps = [
	{
		id: "admins",
		Component: AdminsTable,
	},
	{
		id: "caracteristiques",
		Component: Caracteristiques,
	},
	{
		id: "mode-paiement",
		Component: ModePaiement,
	},
];
const tabs = ["Administrateurs", "Caractéristiques", "Mode de paiement"];

const Parametres = () => {
	const { openModal, isShowModal, openEditModal, closeModal, item } = useModal<PaymentMode>();
	const {
		navigation: { go },
		index,
	}: useStepType = useStep({
		initialStep: 0,
		steps,
	});

	const props = {
		isShowModal,
		openEditModal,
		closeModal,
		item,
	};

	return (
		<div className="parametres__page">
			<h1>Paramètres</h1>
			<AdminCard>
				<ul className="tabs-container d-flex flex-wrap gap-3 px-0">
					{tabs.map((tab, key) => (
						<li
							className={`tab-item  ${index === key && "tab-active-item"}`}
							key={key}
							onClick={() => go(key)}
							role="button"
						>
							{tab}
						</li>
					))}
				</ul>
				<div className="flex-end my-4">
					{index === 0 && (
						<ButtonAdd
							label="Ajouter un administrateur"
							data-bs-toggle="modal"
							data-bs-target="#AddAdminModal"
						/>
					)}
					{index === 2 && (
						<ButtonAdd label="Ajouter un mode de paiement" onClick={openModal} />
					)}
				</div>
				{index === 0 ? (
					<AdminsTable />
				) : index === 1 ? (
					<Caracteristiques />
				) : (
					<ModePaiement {...props} />
				)}
			</AdminCard>
			<AddAdminModal />
		</div>
	);
};

export default Parametres;
