import React, { useState } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Color } from "../../utils/theme";

interface Props {
	id: string;
	title: string;
	children: React.ReactNode;
}
const Collapse: React.FC<Props> = ({ id, title, children }) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleSetActive = () => {
		setIsActive((isActive) => !isActive);
	};
	return (
		<div className="collapse-container shadow px-3 py-4 mb-4">
			<div
				className="flex-sb align-items-center"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target={`#collapse${id}`}
				aria-expanded="false"
				aria-controls="collapseFaq"
				onClick={toggleSetActive}
			>
				<h2 className="mb-3">{title}</h2>
				<div className="accordion_icon_container">
					{isActive ? (
						<BiChevronDown style={{ fontSize: 26, color: Color.primary }} />
					) : (
						<BiChevronRight style={{ fontSize: 26, color: Color.primary }} />
					)}
				</div>
			</div>
			<div className="collapse mt-3" id={`collapse${id}`}>
				<div className="collapse_card__body">{children}</div>
			</div>
		</div>
	);
};

export default Collapse;
