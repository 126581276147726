import { CSSProperties } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { BtnPrimary } from "../../../common/Button";
import { FormError } from "../../../common/Input";
import { IconLock } from "../../../shared/Icons";
import useSigninForm from "../../auth/useAuthForm/useSigninForm";
import { LogoApp } from "../../user/Layouts/SideBar/SidebarUser";

const InputGroupStyle = { background: "#F7F7F7", border: "none", padding: "5px 20px" };
const InputStyle = {
	background: "#F7F7F7",
	border: "none",
	padding: "1.3rem 10px",
	borderRadius: 10,
} as CSSProperties;
const iconStyle = { color: "#C4C4C4", fontSize: 24 };
const AdminSignIn = () => {
	const { register, onSubmit, errors, isLoading } = useSigninForm();
	return (
		<div className="admin__auth__page">
			<div className="shadow py-3 flex-c">
				<LogoApp path="/admin/connexion" />
			</div>
			<div
				style={{ backgroundColor: "#F5F5F5", paddingBottom: "20rem" }}
				className="row pt-5 d-flex"
			>
				<div
					className="admin-auth-form-container col-md-6 offset-md-3 bg-white px-5 py-5"
					style={{ borderRadius: 10 }}
				>
					<h1 className="text-center mb-5">Connectez-vous</h1>
					<form onSubmit={onSubmit}>
						<div className="mb-4">
							<div className="input-group mb-2">
								<span
									className="input-group-text"
									id="email"
									style={InputGroupStyle}
								>
									<BsFillPersonFill style={iconStyle} />
								</span>
								<input
									type="email"
									className="form-control"
									placeholder="Identifiant"
									aria-label="Username"
									aria-describedby="email"
									style={InputStyle}
									{...register("email")}
								/>
							</div>
							<FormError error={errors?.email} />
						</div>
						<div className="mb-4">
							<div className="input-group mb-2">
								<span
									className="input-group-text"
									id="password"
									style={InputGroupStyle}
								>
									<IconLock />
								</span>
								<input
									type="password"
									className="form-control"
									placeholder="Mot de passe"
									aria-label="Username"
									aria-describedby="password"
									style={InputStyle}
									{...register("password")}
								/>
							</div>
							<FormError error={errors?.password} />
						</div>
						<div className="btn-container flex-c mt-5">
							<BtnPrimary
								label="Se connecter"
								mode="Secondary"
								style={{ borderRadius: 10 }}
								isLoading={isLoading}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AdminSignIn;
