import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ButtonView, ButtonEditLink } from "../../../../common/Button";
import {
	useGetVendeurQuery,
	useDeleteVendeurMutation,
	useEditVendeurMutation,
} from "../../../../../utils/api/vendeur/vendeur.api";
import { IVendeur } from "../../../../../utils/api/vendeur/vendeur.type";
import { IconDelete } from "../../../../shared/Icons";
import { useDeleteConfirme } from "../../../../../utils/helpers";
import TableSkeleton from "../../../../common/TableSkeleton";
import { CustomPagination } from "../../../../common/CustomPagination";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";

function SellersTable({ word }: { word: string }) {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { data, isLoading } = useGetVendeurQuery({
		limit: perPage,
		page,
		word,
	});

	const boutiqueFormatter: any = (cell: any, row: any) => {
		return <div className="gap-3 d-flex">{row?.boutique[0]?.nom_boutique}</div>;
	};

	const nomFormatter: any = (cell: any, row: any) => {
		return (
			<div className="gap-3 d-flex">
				{row.prenom} {row.nom}
			</div>
		);
	};

	const villeFormatter: any = (cell: any, row: any) => {
		return <div className="gap-3 d-flex">{row?.boutique[0]?.ville}</div>;
	};

	const archiveFormatter: any = (cell: boolean, row: any) => {
		return <ButtontogglearchiveSeller cell={cell} row={row} />;
	};

	const actionFormatter: any = (cell: any, row: any) => {
		var url = "/admin/vendeurs/" + row?.slug;
		var edit_url = "/admin/vendeurs/" + row?.slug + "/modifier";
		return (
			<div className="gap-3 d-flex">
				<ButtonView path={url} />
				<ButtonEditLink to={edit_url} state={row} />
				<DeleteVendeur item={row} />
			</div>
		);
	};

	const columns = [
		{
			dataField: "nom",
			text: "Prénom & Nom",
			formatter: (cell: any, row: any) => nomFormatter(cell, row),
		},
		{
			dataField: "boutique",
			text: "Boutique",
			style: { textTransform: "uppercase" },
			formatter: (cell: any, row: any) => boutiqueFormatter(cell, row),
		},
		{
			dataField: "stock",
			text: "Stock",
			formatter: (cell: any, row: any) => 200,
		},
		{
			dataField: "adresse",
			text: "Ville",
			formatter: (cell: any, row: any) => villeFormatter(cell, row),
		},
		{
			dataField: "is_active",
			text: "Actif",
			formatter: (cell: any, row: any) => archiveFormatter(cell, row),
		},
		{
			dataField: "slug",
			text: "Actions",
			style: { textAlign: "center" },
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "80px", whiteSpace: "normal" };
			},
		},
	];

	return (
		<>
			{isLoading && (
				<TableSkeleton
					headers={["Prenom & Nom", "Boutique", "Stock", "Ville", "Actions"]}
				/>
			)}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="id"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive
						striped={false}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={() =>
							word
								? "Aucun vendeur ne correspond à votre recherche"
								: "Aucune donnée disponible"
						}
					/>
					<CustomPagination
						nbPages={data?.count}
						page={page}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
						perPage={perPage}
					/>
				</>
			)}
		</>
	);
}

export function DeleteVendeur({ item }: { item: IVendeur }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteVendeurMutation();
	const onDelete = useDeleteConfirme<IVendeur>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet vendeur?",
		successMessage: "Vendeur supprimée",
	});
	return (
		<button className="action-btn-delete action-btn" onClick={onDelete}>
			<IconDelete />
		</button>
	);
}

function ButtontogglearchiveSeller({ cell, row }: { cell: boolean; row: any }) {
	const [editData] = useEditVendeurMutation();

	const togglearchiveSeller = () => {
		Swal.fire({
			title: `Êtes-vous sûr de vouloir  ${
				cell === true ? "désactiver" : "activer"
			} le compte de ce vendeur?`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			iconColor: Color.primary,
			confirmButtonColor: Color.primary,
			preConfirm: () => {
				return editData({ slug: row.slug, data: { is_active: !cell } });
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			if (result?.value?.data) {
				console.log(result?.value?.data, "activer");
				Swal.fire({
					icon: "success",
					title: `Le compte du vendeur a été  ${
						result?.value?.data?.is_active ? "activé " : "désactivé"
					} avec succèss!`,
					iconColor: Color.success,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	return (
		<Switch
			checked={cell}
			onChange={togglearchiveSeller}
			handleDiameter={20}
			offHandleColor="#C4C4C4"
			onHandleColor="#C4c4c4"
			height={20}
			width={50}
			borderRadius={0}
			uncheckedIcon={<div className="toggle-style-off">OFF</div>}
			checkedIcon={<div className="toggle-style-on">ON</div>}
			className="react-switch"
			id="small-radius-switch"
		/>
	);
}

export default SellersTable;
